import clsx from 'clsx';
import { makeStyles, createStyles, Typography, Theme } from '@material-ui/core';
import { formatNumber, formatDate } from 'components/utils';
import { useTranslation } from 'react-i18next';
import { useCurrencyExchangerContext } from '../index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.divider,
      fontSize: 12,
      marginTop: 12,
    },
    rates: {
      borderRight: '1px solid',
      margin: '0 10px 0 0',
      padding: '0 10px 0 0',
    },
  })
);

export const CurrencyRatesInfo = () => {
  const classes = useStyles();
  const { currencies, updated } = useCurrencyExchangerContext();

  const { t } = useTranslation();

  if (!currencies) {
    return null;
  }

  const ratesInfo = currencies.map((t) => formatNumber(t.rate, 4)).join(' / ');

  return (
    <div className={classes.root}>
      <Typography component="span" variant={'body2'} className={clsx(classes.root, classes.rates)}>
        {t('CurrencyRate')}: {ratesInfo}
      </Typography>
      <Typography component="span" variant={'body2'} className={classes.root}>
        {t('LastUpdate')} {formatDate(updated)}
      </Typography>
    </div>
  );
};
