import { formatMoney, formatNumber } from 'components/index';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { SeasonalPaymentScheduleProps } from './SeasonalPaymentSchedule';
import clsx from 'clsx';
import { QuotaPaymentViewModel } from 'schema';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const useStyles = makeStyles<Theme, { hasDate: boolean }>((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: (props) =>
        props.hasDate
          ? '24px 64px 64px 120px 160px 160px 160px 90px 120px 120px 120px 1fr'
          : '24px 120px 160px 160px 160px 90px 120px 120px 120px 1fr',
    },
    header: {
      padding: theme.spacing(0.5, 0.5),
      fontSize: 12,
      lineHeight: '16px',
      color: theme.palette.text.secondary,
    },
    row: {
      padding: theme.spacing(0.75, 0.5),
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.text.secondary,
    },
    oddRow: {
      backgroundColor: theme.palette.background.default,
    },
    item: {},
    center: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    right: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    isHighlighted: {
      color: theme.palette.secondary.dark,
    },
    darkText: {
      color: theme.palette.text.primary,
    },
    bold: {
      fontWeight: 600,
    },
  })
);

const Row = ({
  payment,
  isHighlighted,
  hasDate,
}: {
  payment: QuotaPaymentViewModel;
  isHighlighted: boolean;
  hasDate: boolean;
}) => {
  const classes = useStyles({ hasDate });
  dayjs.locale('ru');

  const {
    insurance,
    mainDebt,
    mainDebtPayment,
    number,
    percentsPayment,
    telematics,
    agentFee,
    total,
    date,
    earlyRedemption,
    additionalLeasingPayment = 0,
  } = payment;

  const rowClass = number % 2 === 0 ? '' : classes.oddRow;
  const yearClass = isHighlighted ? classes.isHighlighted : '';

  return (
    <>
      <div className={clsx(classes.item, classes.row, classes.center, rowClass, classes.darkText)}>
        {number}
      </div>
      {hasDate && (
        <>
          <div className={clsx(classes.item, classes.row, classes.center, rowClass, yearClass)}>
            {dayjs(date).year()}
          </div>
          <div className={clsx(classes.item, classes.row, rowClass, classes.darkText)}>
            {dayjs(date).format('MMM')}
          </div>
        </>
      )}
      <div
        className={clsx(
          classes.item,
          classes.row,
          classes.right,
          rowClass,
          classes.darkText,
          classes.bold
        )}
      >
        {formatMoney({ amount: total, fractionDigits: 2 })}
      </div>
      <div className={clsx(classes.item, classes.row, classes.right, rowClass)}>
        {formatMoney({ amount: mainDebt, fractionDigits: 2 })}
      </div>
      <div className={clsx(classes.item, classes.row, classes.right, rowClass)}>
        {formatMoney({ amount: mainDebtPayment, fractionDigits: 2 })}
      </div>
      <div className={clsx(classes.item, classes.row, classes.right, rowClass)}>
        {formatNumber(percentsPayment, 2)}
      </div>
      <div className={clsx(classes.item, classes.row, rowClass, classes.right)}>
        {formatMoney({ amount: agentFee, fractionDigits: 2 })}
      </div>
      <div className={clsx(classes.item, classes.row, classes.right, rowClass)}>
        {formatMoney({ amount: insurance, fractionDigits: 2 })}
      </div>
      <div className={clsx(classes.item, classes.row, classes.right, rowClass)}>
        {formatMoney({ amount: telematics, fractionDigits: 2 })}
      </div>
      <div className={clsx(classes.item, classes.row, classes.right, rowClass)}>
        {formatMoney({ amount: additionalLeasingPayment, fractionDigits: 2 })}
      </div>
      <div className={clsx(classes.item, classes.row, classes.right, rowClass, classes.darkText)}>
        {earlyRedemption ? formatMoney({ amount: earlyRedemption, fractionDigits: 2 }) : 'запрещен'}
      </div>
    </>
  );
};

export const Schedule = (props: SeasonalPaymentScheduleProps) => {
  const { t } = useTranslation();
  const { payments } = props;

  const [highlightedYears, setHighlightedYears] = useState<Set<number>>(new Set());

  useEffect(() => {
    const yearsMap = new Set<number>();
    const highlighted = new Set<number>();

    payments.forEach((payment) => {
      const year = dayjs(payment.date).year();
      if (!yearsMap.has(year)) {
        yearsMap.add(year);
        highlighted.add(payment.number);
      }
    });

    setHighlightedYears(highlighted);
  }, [payments]);

  const hasDate = payments.some((payment) => payment.date);

  const classes = useStyles({ hasDate });

  return (
    <div className={classes.container}>
      <div className={clsx(classes.item, classes.header, classes.center)}>№</div>
      {hasDate && (
        <>
          <div className={clsx(classes.item, classes.header, classes.center)}>{t('Year')}</div>
          <div className={clsx(classes.item, classes.header)}>{t('Month')}</div>
        </>
      )}
      <div className={clsx(classes.item, classes.header, classes.right)}>Платеж</div>
      <div className={clsx(classes.item, classes.header, classes.right)}>Основной долг</div>
      <div className={clsx(classes.item, classes.header, classes.right)}>
        Основной долг (оплата)
      </div>
      <div className={clsx(classes.item, classes.header, classes.right)}>Проценты</div>
      <div className={clsx(classes.item, classes.header, classes.right)}>AB</div>
      <div className={clsx(classes.item, classes.header, classes.right)}>{t('Insurance')}</div>
      <div className={clsx(classes.item, classes.header, classes.right)}>{t('Telematics')}</div>
      <div className={clsx(classes.item, classes.header, classes.right)}>ДЛП</div>
      <div className={clsx(classes.item, classes.header, classes.right)}>Досрочный выкуп</div>
      {payments.map((payment) => (
        <Row
          payment={payment}
          isHighlighted={highlightedYears.has(payment.number)}
          key={payment.number}
          hasDate={hasDate}
        />
      ))}
    </div>
  );
};
