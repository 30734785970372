import { ShipmentItemViewModel } from 'schema';
import { IconSprite } from '../../icons';
import { themeOrange as theme } from 'theme';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useRemoveShipmentItemFromIssueMutation } from 'services';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../Toast';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      justifyContent: 'flex-start',
      width: '100%',
      fontSize: 12,
      lineHeight: '16px',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    view: {
      marginRight: 10,
    },
    title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flex: '1 1 auto',
      paddingLeft: theme.spacing(0.5),
      color: theme.palette.secondary.dark,
      display: 'flex',
      alignItems: 'center',
    },
    id: {
      width: 15,
      flexShrink: 0,
      color: theme.palette.text.secondary,
    },
    separator: {
      width: 11,
      fontSize: 16,
      flexShrink: 0,
      textAlign: 'center',
      color: theme.palette.beige.main,
    },
    delete: {
      cursor: 'pointer',
      width: 14,
      height: 16,
      flexShrink: 0,
      marginLeft: theme.spacing(1),
      justifySelf: 'flex-end',
    },
  })
);

export const ShipmentItem = (props: ShipmentItemViewModel & { issueId: number }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();

  const { id, leaseSubject, brand, itemModel, nomenclatureCode, issueId = 0 } = props;

  const queryClient = useQueryClient();

  const { mutateAsync } = useRemoveShipmentItemFromIssueMutation(issueId, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('issues') > -1;
        },
      });
      toast(t('SuccessMessage'), 'success');
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const deleteHandler = async () => {
    await mutateAsync({
      shipmentItemId: id,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.id}>{id}</div>
      <div className={classes.separator}>|</div>
      {nomenclatureCode && nomenclatureCode !== '' ? (
        <Link
          to={`/dictionaries/nomenclatures?nomenclatureCode=${nomenclatureCode}`}
          className={classes.title}
        >
          <IconSprite
            icon={'view'}
            width={15}
            height={10}
            color={theme.palette.primary.main}
            className={classes.view}
          />
          {leaseSubject} {brand} {itemModel}
        </Link>
      ) : (
        <div className={classes.title}>
          {leaseSubject} {brand} {itemModel}
        </div>
      )}

      <div onClick={deleteHandler} className={classes.delete}>
        <IconSprite icon={'delete'} width={14} height={15} color={theme.palette.primary.main} />
      </div>
    </div>
  );
};
