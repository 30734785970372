import { useTranslation } from 'react-i18next';
import {
  CalculationFormFieldPath,
  CalculationFormPathValue,
  RulesType,
  useCalculationFormContext,
} from './types';
import { useCallback } from 'react';
import { useNomenclatureListSearchQuery } from 'services/api/useNomenclaturesBackend';
import { useLeasingProductByBrandQuery } from 'services/api/useLeasingProductBrandsBackend';

import { useSelectedLeasingProductsQuery } from './useSelectedProductQuery';

type UseNomenclatureByVendorValidationProps<T extends CalculationFormFieldPath> = {
  name: T;
  code: any;
};

export const useNomenclatureByVendorValidation = <T extends CalculationFormFieldPath>({
  code,
}: UseNomenclatureByVendorValidationProps<T>) => {
  const { t } = useTranslation();
  const { getValues } = useCalculationFormContext();
  const errorMessage = t('The brand does not match the selected vendor program');

  const nomenclatureCode = getValues(code);
  const { data } = useNomenclatureListSearchQuery({
    code: [nomenclatureCode],
  });

  const vendor = data?.data[0]?.vendor ?? '';
  const { data: leasingProductByNomenclature } = useLeasingProductByBrandQuery({ brand: vendor });
  const selectedLeaseProductName = getValues('leaseProduct');
  const selectedLeaseProduct = useSelectedLeasingProductsQuery(selectedLeaseProductName);

  const validate = useCallback(
    (value: CalculationFormPathValue) => {
      const isSave = getValues('save');

      if (!isSave) {
        return undefined;
      }

      if (
        selectedLeaseProduct?.isVendor &&
        leasingProductByNomenclature?.name !== selectedLeaseProductName &&
        nomenclatureCode //DEVBE-536
      ) {
        return errorMessage;
      }

      return undefined;
    },
    [
      errorMessage,
      getValues,
      selectedLeaseProduct?.isVendor,
      leasingProductByNomenclature?.name,
      selectedLeaseProductName,
      nomenclatureCode,
    ]
  );

  const rules: RulesType<T> = {
    validate,
  };

  return rules;
};
