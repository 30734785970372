import React, { useCallback, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Breadcrumbs, ModalForm, Paging, useModalForm } from 'components';
import { AddButton } from '../AddButton';
import { WikiTagViewModel } from 'schema/serverTypes';
import { WikiTagsTable } from './WikiTagsTable';
import { EditWikiTagForm } from './EditWikiTagForm';
import { useWikiTagsData } from './useWikiTagsData';
import { WikiTagsFilterForm } from './WikiTagsFilterForm';
import { AddWikiTagForm } from './AddWikiTagForm';
import { useTranslation } from 'react-i18next';
import { usePageTitle } from 'components/utils/usePageTitle';

export const WikiTagsDataGrid = () => {
  const { t } = useTranslation();
  const { filter, paging, rows, isLoading, sorting } = useWikiTagsData();
  const { onOpen, onClose, open } = useModalForm();

  const [item, setItem] = useState<WikiTagViewModel>();

  const pageTitle = t('Wiki tags') + ' - Bumblebee';
  usePageTitle(pageTitle);

  const handleOnOpen = useCallback(
    (item: WikiTagViewModel) => {
      setItem(item);
      onOpen();
    },
    [onOpen],
  );

  const handleOnClose = useCallback(() => {
    setItem(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box>
          <WikiTagsFilterForm {...filter} />
        </Box>
        <Box>
          <AddButton onClick={onOpen} />
        </Box>
      </Box>
      <Grid container item direction="column">
        <Grid item>
          <WikiTagsTable rows={rows} onOpen={handleOnOpen} loading={isLoading} sorting={sorting} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
      <ModalForm open={open} onClose={handleOnClose}>
        {item && <EditWikiTagForm onSuccess={handleOnClose} item={item} />}
        {item === undefined && <AddWikiTagForm onSuccess={handleOnClose} />}
      </ModalForm>
    </>
  );
};
