import { useParams } from 'react-router-dom';
import { useGroupQuery } from './useGroupQuery';
import { Sidebar } from '../../Sidebar';
import { GroupForm } from '../GroupForm';

export const GroupEditFormPanel = () => {
  const { id: idString } = useParams<{ id: string }>();

  const id = Number.parseInt(idString);
  const { group } = useGroupQuery(id);
  if (!group) return null;

  return (
    <Sidebar url="/users/groups">
      <GroupForm group={group} />
    </Sidebar>
  );
};
