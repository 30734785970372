import { Divider, Theme, createStyles, makeStyles, MenuItem } from '@material-ui/core';
import { CalculationFormProps, useCalculationFormContext } from '../types';
import { Grid, Input, Radio, Switch, Select } from 'components';
import { useTranslation } from 'react-i18next';
import { LeaseProductAutocomplete } from './LeaseProductAutocomplete';
import { CounterpartyAutocomplete } from '../CounterpartyAutocomplete';
import { AdditionalLeasingPaymentType, Currency } from 'schema/serverTypes';
import { NomenclaturesList } from './NomenclaturesList';
import { SaleTotalPriceInput } from './SaleTotalPriceInput';
import { LeaseTotalPriceInput } from './LeaseTotalPriceInput';
import { CurrencyRateInput } from './CurrencyRateInput';
import { IndustryLeaseProductAutocomplete } from './IndustryLeaseProductAutocomplete';
import { VatInput } from './VatInput';
import { LessseeAutocomplete } from '../LesseeAutocomplete';
import { AmountInput } from '../AmountInput';
import { ChangeEvent, useEffect } from 'react';
import { PrepaymentDealerPercents } from './PrepaymentDealerPercents';
import { SalesChannelSelect } from './SalesChannelSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    divider: {
      marginBottom: theme.spacing(2.5),
    },
    noMarginSwitch: {
      textAlign: 'right',
      '& .MuiGrid-root': {
        flexWrap: 'nowrap',
      },
      '& .MuiFormControlLabel-root': {
        marginRight: 0,
      },
    },
    nomenclatureOrderLink: {
      fontSize: 12,
      color: theme.palette.dllBlue.main,
      marginLeft: theme.spacing(1),
    },
    add: {
      color: theme.palette.primary.light,
    },
  })
);

export type SaleContractFieldsProps = Pick<
  CalculationFormProps,
  'quotaId' | 'calculationResult' | 'nomenclatures'
> & {
  onOpenNomenclatures: () => void;
};

export const SaleContractFields = (props: SaleContractFieldsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onOpenNomenclatures, calculationResult, quotaId, nomenclatures = [] } = props;

  const margins = calculationResult?.nomenclatureMargins ?? [];
  const { control, watch, setValue } = useCalculationFormContext();
  const prepaymentDealerDaysCount = watch('prepaymentDealerDaysCount');
  const hasCurrencyCommission = watch('hasCurrencyCommission');

  useEffect(() => {
    if (!hasCurrencyCommission) {
      setValue('prepaymentDealerPercents', 0);
    }
  }, [hasCurrencyCommission, setValue]);

  const changeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
    if (parseInt(ev.target.value, 10) > 5) {
      setValue('additionalLeasingPaymentType', AdditionalLeasingPaymentType.Margin);
    } else {
      setValue('additionalLeasingPaymentType', '' as any);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xl={7} md={7} xs={24}>
          <SalesChannelSelect />
        </Grid>
        <Grid item xl={7} md={7} xs={24}>
          <LeaseProductAutocomplete />
        </Grid>
        <Grid item xl={5} md={5} xs={24}>
          <IndustryLeaseProductAutocomplete />
        </Grid>
        <Grid item xl={12} md={12} xs={24} xsStart={1}>
          <CounterpartyAutocomplete type="dealer" />
        </Grid>
        <Grid item xl={12} md={12} xs={24}>
          <LessseeAutocomplete />
        </Grid>
        <NomenclaturesList
          addButtomCssClassName={classes.add}
          onOpenNomenclatures={onOpenNomenclatures}
          nomenclatures={nomenclatures}
          margins={margins}
          quotaId={quotaId}
        />
      </Grid>
      <Divider light className={classes.divider} />
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={'auto'} xs={24}>
          <Switch
            name="vatEnabled"
            label={t('Vat')}
            labelOff={''}
            labelOn={''}
            type="checkbox"
            control={control}
            classes={{ root: classes.noMarginSwitch }}
          />
        </Grid>
        <Grid item md={3} xs={24}>
          <VatInput watch={watch} control={control} setValue={setValue} />
        </Grid>
        <Grid item md={6} xs={24}>
          <SaleTotalPriceInput />
        </Grid>
        <Grid item md={'auto'} xs={24}>
          <Radio
            name="currencySale"
            label={t('Currencies.Sale')}
            control={control}
            options={[
              { label: '₽', value: Currency.Ruble },
              { label: '$', value: Currency.Dollar },
              { label: '€', value: Currency.Euro },
              { label: '¥', value: Currency.Yuan },
            ]}
          />
        </Grid>
        <Grid item md={5} xs={24}>
          <CurrencyRateInput />
        </Grid>
        <Grid item md={8} xs={24}>
          <LeaseTotalPriceInput />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={6} xs={24}>
          <AmountInput
            name="prepaymentSalesContractAmount"
            label={t('Advance DKP')}
            required
            allowZero
            switchDisabled
          />
        </Grid>
        <Grid item md={'auto'} xs={24}>
          <Switch
            name="hasCurrencyCommission"
            label={t('Currencies.ConversionFee')}
            labelOn=""
            labelOff=""
            type="checkbox"
            control={control}
            classes={{ root: classes.noMarginSwitch }}
          />
        </Grid>
        <Grid item md={7} xs={24}>
          <PrepaymentDealerPercents watch={watch} setValue={setValue} control={control} />
        </Grid>
        <Grid item md={4} xs={24}>
          <Input
            name="prepaymentDealerDaysCount"
            label={t('Days before delivery')}
            control={control}
            onChange={changeHandler}
            rules={{
              pattern: /^[0-9.\-\s]+$/i,
            }}
          />
        </Grid>
        <Grid item md={6} xs={24}>
          <Select
            label={t('AdditionalLeasingPayment')}
            name="additionalLeasingPaymentType"
            control={control}
            displayEmpty
            disabled={!prepaymentDealerDaysCount || prepaymentDealerDaysCount <= 5}
          >
            <MenuItem value={AdditionalLeasingPaymentType.Margin}>
              {t('AdditionalLeasingPaymentType.Margin')}
            </MenuItem>
            <MenuItem value={AdditionalLeasingPaymentType.Payment}>
              {t('AdditionalLeasingPaymentType.Payment')}
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
    </div>
  );
};
