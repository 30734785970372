import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatDate, IconSprite, User as Author } from 'components';
import { DealQualificationStatus, User } from 'schema';
import { themeOrange as theme } from 'theme';
import { QualificationStatuses } from './QualificationStatuses';
import { Link } from 'react-router-dom';
import { useUserRole } from 'services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    status: {
      background: (props: { dealQualificationStatus: DealQualificationStatus }) => {
        return props.dealQualificationStatus === DealQualificationStatus.Qualified
          ? theme.palette.info.main
          : props.dealQualificationStatus === DealQualificationStatus.Valid
            ? theme.palette.warning.light
            : 'none';
      },
    },
    header: {
      minHeight: 32,
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '16px 1fr 140px auto 14px 14px',
      gridColumnGap: 16,
      alignItems: 'center',
      color: (props: { dealQualificationStatus: DealQualificationStatus }) => {
        return props.dealQualificationStatus !== DealQualificationStatus.None
          ? theme.palette.common.white
          : '';
      },
    },
    value: {
      fontWeight: 700,
    },
    scoringModel: {
      fontWeight: 700,
      color: (props: { dealQualificationStatus: DealQualificationStatus }) => {
        return props.dealQualificationStatus !== DealQualificationStatus.None
          ? theme.palette.common.white
          : theme.palette.secondary.dark;
      },
    },
  }),
);

type QualificationPanelProps = {
  author: User;
  createdDate: string;
  dealQualificationStatus: DealQualificationStatus;
  quotaId: number;
  lessee?: string;
  leaseSubject?: string;
  scoringModelName?: string;
};

export const QualificationPanel = (props: QualificationPanelProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const {
    author,
    createdDate,
    dealQualificationStatus,
    quotaId,
    lessee,
    leaseSubject,
    scoringModelName = '',
  } = props;
  const { isAdmin } = useUserRole();

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded={false}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.status}
        >
          <div className={classes.header}>
            <IconSprite
              icon={'view2'}
              width={16}
              color={
                dealQualificationStatus !== DealQualificationStatus.None
                  ? theme.palette.common.white
                  : theme.palette.text.secondary
              }
            />
            <Typography className={classes.scoringModel}>
              {dealQualificationStatus === DealQualificationStatus.None ? 'Не коробка' : 'Коробка'}
              {isAdmin && scoringModelName !== '' ? <> ({scoringModelName})</> : null}
            </Typography>
            <div>
              {t('Created')}: <span className={classes.value}>{formatDate(createdDate)}</span>
            </div>
            <div>
              {t('Owner')}:{' '}
              <span className={classes.value}>
                <Author isActive={author.isActive} name={author.name} />
              </span>
            </div>
            <Link to={`/quotas/calculator/owner/${quotaId}`} style={{ height: 14 }}>
              <IconSprite
                icon={'chat2'}
                width={14}
                color={
                  dealQualificationStatus !== DealQualificationStatus.None
                    ? theme.palette.common.white
                    : theme.palette.text.secondary
                }
              />
            </Link>
            <Link
              to={`/quotas/calculator/comments/${quotaId}?lessee=${lessee ?? ''}&name=${
                leaseSubject ?? ''
              }`}
              style={{ height: 14 }}
            >
              <IconSprite
                icon={'menu-catalogs'}
                width={14}
                color={
                  dealQualificationStatus !== DealQualificationStatus.None
                    ? theme.palette.common.white
                    : theme.palette.text.secondary
                }
              />
            </Link>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <QualificationStatuses quotaId={quotaId} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
