import { FixedAmountInput } from '../AmountInput';
import { AmountType } from 'schema';
import { useTranslation } from 'react-i18next';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { CalculatorFormValues } from '../types';

type PrepaymentDealerPercentProps = {
  control: Control<CalculatorFormValues, any>;
  watch: UseFormWatch<CalculatorFormValues>;
  setValue: UseFormSetValue<CalculatorFormValues>;
};

export const PrepaymentDealerPercents = (props: PrepaymentDealerPercentProps) => {
  const { t } = useTranslation();

  const { watch } = props;

  const prepaymentSalesContractAmount = watch('prepaymentSalesContractAmount');
  const hasCurrencyCommission = watch('hasCurrencyCommission');

  return (
    <FixedAmountInput
      name="prepaymentDealerPercents"
      label={`${t('AmountPrepaymentSupplier')}, %`}
      required={hasCurrencyCommission}
      allowZero
      amountMode={AmountType.Percents}
      disabled={!hasCurrencyCommission}
      max={
        hasCurrencyCommission && prepaymentSalesContractAmount?.type === AmountType.Percents
          ? 100 - (prepaymentSalesContractAmount?.value ?? 0)
          : undefined
      }
    />
  );
};
