import { ShipmentFormProps } from './types';
import { useQuotasBackendQuery } from 'services/api';
import { ShipmentViewModel } from 'schema/serverTypes';
import { useMemo, useState } from 'react';
import { MultiExpectedDateForm } from './MultiExpectedDateForm';
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { ShipmentItem } from './ShipmentItem';
import { MultiTelematics } from './MultiTelematics';
import { MultiShipmentReport } from './MultiShipmentReport';
import { MultiShipmentAct } from './MultiShipmentAct';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { MultiShipmentDateForm } from './MultiShipmentDateForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panels: {
      marginBottom: (props: any) => (props.isTask ? '0' : '75px'),
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      height: 72,
      padding: theme.spacing(0, 2.5),
      marginBottom: theme.spacing(2),
      borderRadius: 0,
      '& > *': {
        marginRight: theme.spacing(2.5),
      },
    },
  })
);

export const ShipmentForm = (props: ShipmentFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const [currentView, setCurrentView] = useState<string | null>(null);

  const { quota, shipmentItemId, setIsDirty } = props;

  const { data, isLoading } = useQuotasBackendQuery<ShipmentViewModel>(
    `${quota.quotaId}/shipment`,
    ['quotas', quota.quotaId, 'shipment'],
    {
      refetchOnWindowFocus: true,
    }
  );

  const items = useMemo(() => {
    let initialItems = data?.items ?? []; // Инициализация items

    if (shipmentItemId) {
      return initialItems.filter((t) => t.id === shipmentItemId);
    }

    return initialItems;
  }, [data?.items, shipmentItemId]);

  const isLocked = data?.isLocked ?? false;
  const telematics = quota.calculationResult.telematics;

  return (
    <>
      {!currentView && (
        <>
          <Paper className={classes.actions}>
            <Button
              variant="contained"
              onClick={() => setCurrentView('expecteddate')}
              disabled={items.every((item) => item.shipmentDate)}
            >
              {t('Expected shipping date')}
            </Button>
            <Button variant="contained" onClick={() => setCurrentView('shippingdate')}>
              {t('Shipping date')}
            </Button>
            <Button
              variant="contained"
              onClick={() => setCurrentView('telematics')}
              disabled={telematics === 'none' || items.every((item) => !item.canOrderTelematics)}
            >
              {t('Order telematics')}
            </Button>
            <Button
              variant="contained"
              onClick={() => setCurrentView('act')}
              disabled={items.every((item) => !item.canShipItem)}
            >
              {t('Request an act')}
            </Button>
            <Button
              variant="contained"
              onClick={() => setCurrentView('report')}
              disabled={items.every((item) => !item.canOrderShipmentReport)}
            >
              {t('Shipment reports')}
            </Button>
          </Paper>

          <div className={classes.panels}>
            {items.map((item, index) => {
              return (
                <ShipmentItem
                  key={item.id}
                  item={item}
                  index={index}
                  quotaId={quota.quotaId}
                  authorId={quota.user.id}
                  dealer={quota.dealer}
                  lessee={quota.lessee}
                  lessor={data?.lessor}
                  isLoading={isLoading}
                  isLocked={isLocked}
                  telematics={telematics}
                  setIsDirty={setIsDirty}
                />
              );
            })}
          </div>
        </>
      )}
      {currentView === 'expecteddate' && (
        <MultiExpectedDateForm
          quota={quota}
          lessor={data?.lessor}
          items={items}
          isLocked={isLocked}
          isLoading={isLoading}
          telematics={telematics}
          setIsDirty={setIsDirty}
          setCurrentView={setCurrentView}
        />
      )}
      {currentView === 'shippingdate' && (
        <MultiShipmentDateForm
          quota={quota}
          lessor={data?.lessor}
          items={items}
          isLocked={isLocked}
          isLoading={isLoading}
          telematics={telematics}
          setIsDirty={setIsDirty}
          setCurrentView={setCurrentView}
        />
      )}
      {currentView === 'telematics' && (
        <MultiTelematics
          quota={quota}
          lessor={data?.lessor}
          items={items}
          isLocked={isLocked}
          isLoading={isLoading}
          telematics={telematics}
          setIsDirty={setIsDirty}
          setCurrentView={setCurrentView}
        />
      )}
      {currentView === 'act' && (
        <MultiShipmentAct
          quota={quota}
          lessor={data?.lessor}
          items={items}
          isLocked={isLocked}
          isLoading={isLoading}
          telematics={telematics}
          setIsDirty={setIsDirty}
          setCurrentView={setCurrentView}
        />
      )}
      {currentView === 'report' && (
        <MultiShipmentReport
          quota={quota}
          lessor={data?.lessor}
          items={items}
          isLocked={isLocked}
          isLoading={isLoading}
          telematics={telematics}
          setIsDirty={setIsDirty}
          setCurrentView={setCurrentView}
        />
      )}
    </>
  );
};
