import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MenuItem, Typography } from '@material-ui/core';
import { Button, Grid, Input, Select } from 'components';
import { useIssueContractMutation } from '../../services';
import {
  SendQuotaToExecutionViewModel as ContractFormValues,
  ContractType,
  CounterpartyContractViewModel,
} from 'schema/serverTypes';
import { useToast } from '../Toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type ContractForm1Props = {
  leaseSubject?: string;
  lesseeInn?: string;
  lesseeFullName?: string;
  quotaId?: number;
  onSuccess: () => void;
};

export const ContractForm1 = (props: ContractForm1Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { quotaId = 0, leaseSubject = '', lesseeInn = '', lesseeFullName = '' } = props;

  const defaultValues: ContractFormValues = useMemo(() => {
    const values = {
      lessee: {
        fullName: lesseeFullName,
        inn: lesseeInn,
      } as CounterpartyContractViewModel,
      documentUrl: '',
      description: '',
      title: `№${quotaId} ${leaseSubject ? '| ' + leaseSubject : ''}`,
      link: {
        url: `/quotas/calculator/${quotaId}`,
        title: `№${quotaId}`,
      },
      contractType: ContractType.Lease,
    };

    return values;
  }, [lesseeFullName, lesseeInn, quotaId, leaseSubject]);

  const { control, handleSubmit } = useForm({
    defaultValues,
    mode: 'all',
  });
  const toast = useToast();
  const { push } = useHistory();

  const { mutateAsync } = useIssueContractMutation<ContractFormValues, any>(quotaId, {
    method: 'PUT',
    onSuccess: () => {
      toast(t('ContractSuccessMessage'), 'success');
      push(`/tasks`);
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: ContractFormValues) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const message = t('Required');

  return (
    <form id="newTask-form" onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.title}>
        {t('Draw up contract')} | {t('Calculation')} № {quotaId}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <Select name="contractType" control={control} label={t('Contract type')}>
            <MenuItem value={ContractType.Lease}>{t('ContractType.Lease')}</MenuItem>
            <MenuItem value={ContractType.Loan}>{t('ContractType.Loan')}</MenuItem>
            <MenuItem value={ContractType.LeaseNoVAT}>{t('ContractType.LeaseNoVAT')}</MenuItem>
          </Select>
        </Grid>
        <Grid item md={24} xs={24}>
          <Input
            label={t('Link to documents')}
            control={control}
            name="documentUrl"
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Input label={t('Description')} control={control} name="description" multiline={true} />
        </Grid>
        <Grid item md={24} xs={24}>
          <Typography className={classes.required} variant={'body2'}>
            *{t('required fields')}
          </Typography>
        </Grid>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <div className={classes.actionButton}>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
              >
                {t('Create task')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
