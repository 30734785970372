import { OfficeDashboard, TvDashboard, VendorDashboard } from 'components';

export const TvPage = () => {
  return <TvDashboard />;
};

export const VendorPage = () => {
  return <VendorDashboard />;
};

export const OfficePage = () => {
  return <OfficeDashboard />;
};
