import { ShipmentItemViewModel } from 'schema';
import { ShipmentItem } from './ShipmentItem';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 10,
      marginBottom: theme.spacing(1),
    },
  })
);

type IssueType = 'telematics' | 'shipment' | 'shipmentReport';

type ShipmentItemListProps = {
  shipmentItems: ShipmentItemViewModel[];
  shipmentItemsIds: number[];
  title: string;
  type: IssueType;
};

export const ShipmentItemList = (props: ShipmentItemListProps) => {
  const classes = useStyles();
  const { shipmentItems = [], shipmentItemsIds, title, type } = props;

  function getIssueId(item: ShipmentItemViewModel, type: IssueType): number {
    switch (type) {
      case 'telematics':
        return item.telematicsIssueId ?? 0;
      case 'shipment':
        return item.shipmentIssueId ?? 0;
      case 'shipmentReport':
        return item.shipmentReportIssueId ?? 0;
      default:
        return 0;
    }
  }

  return (
    <div>
      <Typography color={'secondary'} className={classes.title} variant={'body2'}>
        {title}
      </Typography>
      {shipmentItems
        .filter((item) => shipmentItemsIds.some((id) => id === item.id))
        .map((item) => {
          const issueId = getIssueId(item, type as IssueType);
          return <ShipmentItem key={item.id} {...item} issueId={issueId} />;
        })}
    </div>
  );
};
