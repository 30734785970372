import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FutureShipmentNomenclatureViewModel } from 'schema';
import clsx from 'clsx';
import { formatDate, formatMoney } from '../utils';
import { FutureShipmentControl } from './FutureShipmentControl';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontSize: 12,
      color: theme.palette.text.primary,
      display: 'grid',
      gridTemplateColumns: '108px 130px 1fr 550px 144px',
    },
    th: {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      padding: '2px 6px',

      '&:first-child': {
        borderTopLeftRadius: 2,
      },
      '&:last-child': {
        borderTopRightRadius: 2,
      },
    },
    td: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 6px',
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    right: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    controls: {
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'space-between',
      width: '100%',
    },
    control: {
      display: 'flex',
      lineHeight: '16px',
      alignItems: 'center',
      color: theme.palette.text.primary,
      width: '33%',
    },
    nowrap: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

export const FutureShipmentNomenclatureList = (props: {
  nomenclatures: FutureShipmentNomenclatureViewModel[];
}) => {
  const classes = useStyles(props);
  const { nomenclatures } = props;

  return (
    <div className={classes.root}>
      <div className={classes.th}>Ожидаемая</div>
      <div className={clsx(classes.th)}>Отгружен</div>
      <div className={clsx(classes.th)}>Предмет лизинга</div>
      <div className={clsx(classes.th)}>Контроли</div>
      <div className={clsx(classes.th, classes.right)}>NBV</div>
      {nomenclatures.map((nomenclature: FutureShipmentNomenclatureViewModel) => (
        <FutureShipmentNomenclature
          key={nomenclature.name + nomenclature.expectedShipmentDate}
          {...nomenclature}
        />
      ))}
    </div>
  );
};

const FutureShipmentNomenclature = (props: FutureShipmentNomenclatureViewModel) => {
  const classes = useStyles(props);

  const { expectedShipmentDate, shipmentDate, name, telematic, act, report, nbv } = props;

  return (
    <>
      <div className={classes.td}>{formatDate(expectedShipmentDate)}</div>
      <div className={classes.td}>{formatDate(shipmentDate)}</div>
      <div className={clsx(classes.td, classes.nowrap)} title={name}>
        {name}
      </div>
      <div className={classes.td}>
        <div className={classes.controls}>
          <div className={classes.control}>
            {telematic && <FutureShipmentControl {...telematic} key={telematic?.name} />}
          </div>
          <div className={classes.control}>
            {act && <FutureShipmentControl {...act} key={act?.name} />}
          </div>
          <div className={classes.control}>
            {report && <FutureShipmentControl {...report} key={report?.name} />}
          </div>
        </div>
      </div>
      <div className={clsx(classes.td, classes.right)}>
        {nbv !== undefined ? formatMoney({ amount: nbv, currency: 'RUB' }) : ''}
      </div>
    </>
  );
};
