import { useCalculationFormContext } from '../types';
import { useTranslation } from 'react-i18next';
import { Select } from 'components';
import { MenuItem } from '@material-ui/core';

export const SalesChannelSelect = () => {
  const { t } = useTranslation();

  const { control } = useCalculationFormContext();

  return (
    <Select
      label={`${t('Sales channel')}`}
      name="salesChannel"
      control={control}
      rules={{
        required: {
          value: true,
          message: t('Required'),
        },
      }}
    >
      <MenuItem value="Dealer direct">Dealer direct</MenuItem>
      <MenuItem value="Customer direct">Customer direct</MenuItem>
      <MenuItem value="Agency">Agency</MenuItem>
    </Select>
  );
};
