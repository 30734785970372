import { useDictionaryBackendQuery } from 'services';
import { PagedList } from '../../pagination';
import { LeasingProductItemViewModel } from 'schema';

export const useSelectedLeasingProductsQuery = (selectedLeaseProductName?: string) => {
  const searchParams = new URLSearchParams();
  searchParams.set('page', '1');
  searchParams.set('pageSize', '9999');

  if (selectedLeaseProductName) {
    searchParams.set('search', selectedLeaseProductName);
  }

  const url = `leasingProducts?${searchParams}`;

  const { data: selectedLeaseProduct } =
    useDictionaryBackendQuery<PagedList<LeasingProductItemViewModel>>(url);

  return selectedLeaseProductName ? selectedLeaseProduct?.data[0] : null;
};
