import { useGoBack } from 'hooks';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useUserAuth } from 'services/authentication';
import { UserViewModel } from './types';
import { useToast } from '../Toast/useToast';
import { useTranslation } from 'react-i18next';
import { IDENTITY_CONFIG } from 'services/authentication/AuthenticationConfig';

export interface UserEditFormValues {
  role?: string;
  lastName?: string;
  middleName?: string;
  firstName?: string;
  phoneNumber?: string;
  leasingPrograms: string[];
  salesPoints: string[];
  email?: string;
  activeToDate?: string;
  isActive: boolean;
}

export const useUpdateUserMutation = (id: string) => {
  const { user } = useUserAuth();
  const accessToken = user?.access_token;

  const updateUser = useCallback(
    async (values: UserEditFormValues) => {
      const requestUrl = `${IDENTITY_CONFIG.authority}/api/v1/user/${id}`;
      const response = await fetch(requestUrl, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Could not update user');
      }
    },
    [accessToken, id],
  );

  const queryClient = useQueryClient();
  const goBack = useGoBack();
  const { t } = useTranslation();
  const toast = useToast();

  const mutation = useMutation(updateUser, {
    onSuccess: () => {
      toast(t('UserUpdateSuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (_query) => {
          return true;
        },
      });
      queryClient.invalidateQueries(['user', id]);
      goBack('/users');
    },
    onError: () => {
      toast(t('ErrorMessage'), 'success');
    },
  });

  return mutation;
};

export const useEditForm = (user: UserViewModel) => {
  const initialValues: UserEditFormValues = {
    lastName: user.lastName,
    middleName: user.middleName,
    firstName: user.firstName,
    phoneNumber: user.phoneNumber,
    role: user.role ? user.role : user.roles && user.roles.length > 0 ? user.roles[0] : undefined,
    leasingPrograms: user.leasingPrograms,
    salesPoints: user.salesPoints,
    email: user.email,
    isActive: user.isActive,
  };

  const { mutateAsync, isLoading, isError } = useUpdateUserMutation(user.id);

  const onSubmit = useCallback(
    async (values: UserEditFormValues) => {
      await mutateAsync(values);
    },
    [mutateAsync],
  );

  return {
    initialValues,
    isLoading,
    isError,
    onSubmit,
  };
};
