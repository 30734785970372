import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Critical, Grid, IconSprite, Role } from 'components';
import { Card, CardContent, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useErrorContext } from 'components/tasks';
import { ApplicationRole, IssueSource } from 'schema/serverTypes';
import { themeOrange as theme } from 'theme';
import { usePageTitle } from 'components/utils/usePageTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'block',
      height: '100%',
      textDecoration: 'none',
    },
    card: {
      height: '100%',
      display: 'flex',
      borderRadius: 0,
    },
    content: {
      padding: theme.spacing(1.5, 2.5, 2.5, 0),
    },
    icon: {
      width: 28,
      minWidth: 28,
      height: 28,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.light,
      margin: theme.spacing(1.25, 1.5, 1.25, 1.25),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.secondary.dark,
      marginBottom: theme.spacing(1.5),
    },
    description: {
      color: theme.palette.text.primary,
      fontSize: 12,
    },
  }),
);

export const DictionariesPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const pageTitle = t('Section.dictionaries') + ' - Bumblebee';
  usePageTitle(pageTitle);

  useErrorContext({
    title: t('Reference books'),
    source: IssueSource.dictionary,
  });

  return (
    <>
      <Critical />
      <Grid container columnSpacing={2.5} rowSpacing={2.5}>
        <Grid item xl={6} md={8} xs={24}>
          <Link to="/dictionaries/qualifications" className={classes.link}>
            <Card className={classes.card}>
              <div className={classes.icon}>
                <IconSprite
                  icon="book"
                  width="12px"
                  height="16px"
                  color={theme.palette.secondary.dark}
                />
              </div>
              <CardContent className={classes.content}>
                <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                  {t('Qualifications')}
                </Typography>
                <Typography className={classes.description} variant={'body2'} component={'p'}>
                  {t('Dictionaries.Qualifications')}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xl={6} md={8} xs={24}>
          <Link to="/dictionaries/industries" className={classes.link}>
            <Card className={classes.card}>
              <div className={classes.icon}>
                <IconSprite
                  icon="book"
                  width="12px"
                  height="16px"
                  color={theme.palette.secondary.dark}
                />
              </div>
              <CardContent className={classes.content}>
                <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                  {t('Industries')}
                </Typography>
                <Typography className={classes.description} variant={'body2'} component={'p'}>
                  {t('Dictionaries.Industries')}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xl={6} md={8} xs={24}>
          <Link to="/dictionaries/specialization" className={classes.link}>
            <Card className={classes.card}>
              <div className={classes.icon}>
                <IconSprite
                  icon="book"
                  width="12px"
                  height="16px"
                  color={theme.palette.secondary.dark}
                />
              </div>
              <CardContent className={classes.content}>
                <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                  {t('IndustriesSpecialization')}
                </Typography>
                <Typography className={classes.description} variant={'body2'} component={'p'}>
                  {t('Dictionaries.IndustriesSpecialization')}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xl={6} md={8} xs={24}>
          <Link to="/dictionaries/pdc" className={classes.link}>
            <Card className={classes.card}>
              <div className={classes.icon}>
                <IconSprite
                  icon="book"
                  width="12px"
                  height="16px"
                  color={theme.palette.secondary.dark}
                />
              </div>
              <CardContent className={classes.content}>
                <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                  {t('PDC')}
                </Typography>
                <Typography className={classes.description} variant={'body2'} component={'p'}>
                  {t('Dictionaries.PDC')}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xl={6} md={8} xs={24}>
          <Link to="/dictionaries/nomenclatures" className={classes.link}>
            <Card className={classes.card}>
              <div className={classes.icon}>
                <IconSprite
                  icon="book"
                  width="12px"
                  height="16px"
                  color={theme.palette.secondary.dark}
                />
              </div>
              <CardContent className={classes.content}>
                <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                  {t('Nomenclatures')}
                </Typography>
                <Typography className={classes.description} variant={'body2'} component={'p'}>
                  {t('Dictionaries.Nomenclature')}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xl={6} md={8} xs={24}>
          <Link to="/dictionaries/leasingProducts" className={classes.link}>
            <Card className={classes.card}>
              <div className={classes.icon}>
                <IconSprite
                  icon="book"
                  width="12px"
                  height="16px"
                  color={theme.palette.secondary.dark}
                />
              </div>
              <CardContent className={classes.content}>
                <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                  {t('Sales channel')}
                </Typography>
                <Typography className={classes.description} variant={'body2'} component={'p'}>
                  {t('Dictionaries.LeasingProduct')}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xl={6} md={8} xs={24}>
          <Link to="/dictionaries/salesPoints" className={classes.link}>
            <Card className={classes.card}>
              <div className={classes.icon}>
                <IconSprite
                  icon="book"
                  width="12px"
                  height="16px"
                  color={theme.palette.secondary.dark}
                />
              </div>
              <CardContent className={classes.content}>
                <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                  {t('Sales points')}
                </Typography>
                <Typography className={classes.description} variant={'body2'} component={'p'}>
                  {t('Dictionaries.SalesPoint')}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Role role={[ApplicationRole.Admin]}>
          <Grid item xl={6} md={8} xs={24}>
            <Link to="/dictionaries/wikiTags" className={classes.link}>
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <IconSprite
                    icon="book"
                    width="12px"
                    height="16px"
                    color={theme.palette.secondary.dark}
                  />
                </div>
                <CardContent className={classes.content}>
                  <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                    {t('Wiki tags')}
                  </Typography>
                  <Typography className={classes.description} variant={'body2'} component={'p'}>
                    {t('Dictionaries.WikiTags')}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Role>
        <Role role={[ApplicationRole.Admin, ApplicationRole.RiskManager]}>
          <Grid item xl={6} md={8} xs={24}>
            <Link to="/dictionaries/regionTiers" className={classes.link}>
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <IconSprite
                    icon="book"
                    width="12px"
                    height="16px"
                    color={theme.palette.secondary.dark}
                  />
                </div>
                <CardContent className={classes.content}>
                  <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                    {t('Matrix of Tiers')}
                  </Typography>
                  <Typography className={classes.description} variant={'body2'} component={'p'}>
                    {t('Dictionaries.RegionTiers')}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Role>
        <Role role={[ApplicationRole.Admin]}>
          <Grid item xl={6} md={8} xs={24}>
            <Link to="/dictionaries/quotaLostReasons" className={classes.link}>
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <IconSprite
                    icon="book"
                    width="12px"
                    height="16px"
                    color={theme.palette.secondary.dark}
                  />
                </div>
                <CardContent className={classes.content}>
                  <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                    {t('Quota lost sale')}
                  </Typography>
                  <Typography className={classes.description} variant={'body2'} component={'p'}>
                    {t('Dictionaries.QuotaLostReasons')}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Role>
        <Role role={[ApplicationRole.Admin]}>
          <Grid item xl={6} md={8} xs={24}>
            <Link to="/dictionaries/issueControls" className={classes.link}>
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <IconSprite
                    icon="book"
                    width="12px"
                    height="16px"
                    color={theme.palette.secondary.dark}
                  />
                </div>
                <CardContent className={classes.content}>
                  <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                    {t('Issue controls')}
                  </Typography>
                  <Typography className={classes.description} variant={'body2'} component={'p'}>
                    {t('Dictionaries.IssueControls')}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Role>
        <Role role={[ApplicationRole.Admin]}>
          <Grid item xl={6} md={8} xs={24}>
            <Link to="/dictionaries/industryLeasingProducts" className={classes.link}>
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <IconSprite
                    icon="book"
                    width="12px"
                    height="16px"
                    color={theme.palette.secondary.dark}
                  />
                </div>
                <CardContent className={classes.content}>
                  <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                    {t('Dictionaries.IndustryLeasingProducts')}
                  </Typography>
                  <Typography className={classes.description} variant={'body2'} component={'p'}>
                    {t('Dictionaries.IndustryLeasingProducts')}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Role>
        <Role role={[ApplicationRole.Admin]}>
          <Grid item xl={6} md={8} xs={24}>
            <Link to="/dictionaries/countryRisks" className={classes.link}>
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <IconSprite
                    icon="book"
                    width="12px"
                    height="16px"
                    color={theme.palette.secondary.dark}
                  />
                </div>
                <CardContent className={classes.content}>
                  <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                    {t('Country risks')}
                  </Typography>
                  <Typography className={classes.description} variant={'body2'} component={'p'}>
                    {t('Dictionaries.CountryRisks')}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Role>
        <Grid item xl={6} md={8} xs={24}>
          <Link to="/dictionaries/telematics" className={classes.link}>
            <Card className={classes.card}>
              <div className={classes.icon}>
                <IconSprite
                  icon="book"
                  width="12px"
                  height="16px"
                  color={theme.palette.secondary.dark}
                />
              </div>
              <CardContent className={classes.content}>
                <Typography className={classes.title} variant={'subtitle2'} component={'h2'}>
                  {t('Telematics')}
                </Typography>
                <Typography className={classes.description} variant={'body2'} component={'p'}>
                  {t('Dictionaries.Telematics')}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};
