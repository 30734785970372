import {
  ConfirmRatingViewModel,
  RatingCalculationViewModel,
  RatingInput,
  RatingInputViewModel,
  RatingSortBy,
  RatingViewModel,
  RatingYear,
  SortOrder,
} from 'schema/serverTypes';
import { createUrlFromParts, createOptions, BackendQueryOptions, EmptyResponse } from './types';
import { useBackendQuery, useBackendMutation, MutationOptions } from './useBackend';
import { UserViewModel } from '../../components/users/types';

const getEntity = (inn: string) => `counterparties/${inn}/ratings`;

const useRatingsBackendQuery = <TResponse>(
  inn: string,
  options: BackendQueryOptions<TResponse>
) => {
  const entity = getEntity(inn);
  let queryKey = entity;
  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

export const useRatingsQuery = (
  inn: string,
  sortBy: RatingSortBy,
  order: SortOrder,
  users?: UserViewModel[]
) => {
  let searchParams = new URLSearchParams();

  if (users) {
    users.forEach((user) => searchParams.append('userId', user.id));
  }

  searchParams.append('orderBy', sortBy);
  searchParams.append('sortOrder', order);

  return useRatingsBackendQuery<RatingViewModel[]>(inn, {
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

export const useCalculateRatingMutation = (
  inn: string,
  options?: MutationOptions<RatingInputViewModel, RatingCalculationViewModel>
) => {
  return useBackendMutation(createUrlFromParts(getEntity(inn), 'calculate'), {
    ...options,
    method: 'POST',
  });
};

export const useAddRatingMutation = (
  inn: string,
  options?: MutationOptions<RatingInput, RatingViewModel>
) => {
  return useBackendMutation(createUrlFromParts(getEntity(inn)), {
    ...options,
    method: 'POST',
  });
};

export const useCreateAutomaticRatingMutation = (
  inn: string,
  options?: MutationOptions<unknown, RatingViewModel>
) => {
  return useBackendMutation(createUrlFromParts(getEntity(inn), 'automatic'), {
    ...options,
    method: 'POST',
  });
};

export const useUpdateRatingMutation = (
  inn: string,
  id: number,
  options?: MutationOptions<RatingInput, RatingViewModel>
) => {
  return useBackendMutation(createUrlFromParts(getEntity(inn), id.toString()), {
    ...options,
    method: 'PUT',
  });
};

export const useDeleteRatingMutation = (
  inn: string,
  id: number,
  options: MutationOptions<any, EmptyResponse> | undefined
) => {
  return useBackendMutation(createUrlFromParts(getEntity(inn), id.toString()), {
    ...options,
    method: 'DELETE',
  });
};

export const useConfirmRatingMutation = (
  inn: string,
  id: number,
  options?: MutationOptions<ConfirmRatingViewModel, RatingViewModel>
) => {
  return useBackendMutation(createUrlFromParts(getEntity(inn), `${id.toString()}/confirm`), {
    ...options,
    method: 'PUT',
  });
};

export const useUnconfirmRatingMutation = (
  inn: string,
  id: number,
  options?: MutationOptions<any, RatingViewModel>
) => {
  return useBackendMutation(createUrlFromParts(getEntity(inn), `${id.toString()}/unconfirm`), {
    ...options,
    method: 'PUT',
  });
};

export const useRatingCalculationsQuery = (inn: string, ids: number[]) => {
  const searchParams = new URLSearchParams();
  ids.forEach((id) => {
    searchParams.append('ids', id.toString());
  });

  return useRatingsBackendQuery<RatingViewModel[]>(inn, {
    relativeUrl: 'calculations',
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

export const useRatingHistoryQuery = (inn: string) => {
  return useRatingsBackendQuery<RatingYear[]>(inn, { relativeUrl: '/history' });
};

export const useAddCommentRatingMutation = (
  inn: string,
  id: number,
  text: string,
  options?: MutationOptions<any, RatingViewModel>
) => {
  let searchParams = new URLSearchParams();
  searchParams.append('text', text);

  return useBackendMutation(
    createUrlFromParts(getEntity(inn), `${id.toString()}/comment`, searchParams),
    {
      ...options,
      method: 'POST',
    }
  );
};

export const useCounterpartiesRecalculateRatingMutation = <TRequest, TResponse, TContext = unknown>(
  options?: MutationOptions<TRequest, TResponse, TContext>
) =>
  useBackendMutation(createUrlFromParts('counterparties', 'RecalculateRatings'), {
    ...options,
    method: 'PUT',
  });
