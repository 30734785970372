import {
  NomenclatureFilter,
  NomenclaturePagedList,
  NomenclatureSortBy,
  NomenclatureSorting,
  PageOptions,
} from 'schema/serverTypes';
import { BackendQueryOptions, createOptions, createUrlFromParts } from './types';
import { useBackendQuery } from './useBackend';

const entity = 'dictionaries/nomenclatures';

const useNomenclaturesBackend = <TResponse>(options: BackendQueryOptions<TResponse>) => {
  let queryKey = entity;
  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }
  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }
  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options)
  );
};

export type UseNomenclaturesListQueryProps = NomenclatureFilter &
  Partial<NomenclatureSorting> &
  PageOptions;

export const useNomenclatureListQuery = (props: UseNomenclaturesListQueryProps) => {
  const { search = '', order, sortBy, page = 1, pageSize = 20, ...rest } = props;
  let searchParams = new URLSearchParams();

  if (search !== '') {
    searchParams.append('search', search);
  }

  if (sortBy) {
    searchParams.append('sortBy', sortBy);
  }

  if (order) {
    searchParams.append('order', order);
  }

  const {
    code = [],
    category = '',
    vendor = '',
    model = '',
    leaseSubject = '',
    depreciationGroup = '',
    onlyConfirmed = false,
    categoryCode = '',
    assetType = '',
  } = rest;

  searchParams.append('onlyConfirmed', onlyConfirmed ? 'true' : 'false');

  if (code.length > 0) {
    code.forEach((nomenclature) => {
      searchParams.append('code', nomenclature);
    });
  }

  if (category !== '') {
    searchParams.append('category', category);
  }

  if (categoryCode !== '') {
    searchParams.append('categoryCode', categoryCode);
  }

  if (vendor !== '') {
    searchParams.append('vendor', vendor);
  }

  if (model !== '') {
    searchParams.append('model', model);
  }

  if (leaseSubject !== '') {
    searchParams.append('leaseSubject', leaseSubject);
  }

  if (depreciationGroup !== '') {
    searchParams.append('depreciationGroup', depreciationGroup);
  }

  if (assetType !== '') {
    searchParams.append('assetType', assetType);
  }

  searchParams.append('page', page.toString());
  searchParams.append('pageSize', pageSize.toString());

  return useNomenclaturesBackend<NomenclaturePagedList>({
    searchParams,
    options: {
      refetchOnMount: true,
    },
  });
};

export const useNomenclatureListSearchQuery = (props: UseNomenclaturesListQueryProps) => {
  const {
    search = '',
    order,
    sortBy = NomenclatureSortBy.model,
    page = 1,
    pageSize = 50,
    onlyConfirmed = true,
    ...rest
  } = props;

  const newProps = { search, onlyConfirmed, order, sortBy, page, pageSize, ...rest };
  return useNomenclatureListQuery(newProps);
};
