import { MutationOptions, useEntityBackendQuery, useEntityBackendMutation } from './useBackend';
import {
  ConfiscatedParkingPagedList,
  ConfiscatedLeaseItemsPagedList,
  ConfiscatedParkingFilter,
  ConfiscatedParkingSorting,
  PageOptions,
  ConfiscatedLeaseItemFilter,
  ConfiscatedLeaseItemSorting,
} from 'schema/serverTypes';
import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

const confiscated = 'confiscated';
const parkings = 'parkings';
const leaseItems = 'leaseItems';

export type UseConfiscatedParkingsPagedListQueryProps = ConfiscatedParkingFilter &
  Partial<ConfiscatedParkingSorting> &
  PageOptions;

export type UseConfiscatedLeaseItemsPagedListQueryProps = ConfiscatedLeaseItemFilter &
  Partial<ConfiscatedLeaseItemSorting> &
  PageOptions;

export const useConfiscatedParkingsPagedListQuery = (
  props: UseConfiscatedParkingsPagedListQueryProps,
) => {
  const { name = '', address = '', search = '', order, sortBy, page = 1, pageSize = 20 } = props;

  let searchParams = new URLSearchParams();

  if (name !== '') {
    searchParams.append('name', name);
  }

  if (address !== '') {
    searchParams.append('address', address);
  }

  if (search !== '') {
    searchParams.append('search', search);
  }

  if (sortBy) {
    searchParams.append('sortBy', sortBy);
  }

  if (order) {
    searchParams.append('order', order);
  }

  searchParams.append('page', page.toString());
  searchParams.append('pageSize', pageSize.toString());

  return useEntityBackendQuery<ConfiscatedParkingPagedList>(confiscated, {
    searchParams,
    relativeUrl: parkings,
  });
};

export const useConfiscatedLeaseItemsPagedListQuery = (
  props: UseConfiscatedLeaseItemsPagedListQueryProps,
) => {
  const { name = '', vin = '', search = '', order, sortBy, page = 1, pageSize = 20 } = props;

  let searchParams = new URLSearchParams();

  if (name !== '') {
    searchParams.append('name', name);
  }

  if (vin !== '') {
    searchParams.append('address', vin);
  }

  if (search !== '') {
    searchParams.append('search', search);
  }

  if (sortBy) {
    searchParams.append('sortBy', sortBy);
  }

  if (order) {
    searchParams.append('order', order);
  }

  searchParams.append('page', page.toString());
  searchParams.append('pageSize', pageSize.toString());

  return useEntityBackendQuery<ConfiscatedLeaseItemsPagedList>(confiscated, {
    searchParams,
    relativeUrl: leaseItems,
  });
};

const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey.indexOf(confiscated) > -1;
      },
    });
  }, [queryClient]);
};

export const useAddConfiscatedParkingMutation = <
  AddConfiscatedParkingRequest,
  ConfiscatedParkingViewModel,
>(
  options:
    | MutationOptions<AddConfiscatedParkingRequest, ConfiscatedParkingViewModel>
    | undefined = undefined,
) => {
  const invalidate = useInvalidateQuery();

  return useEntityBackendMutation(confiscated, parkings, {
    method: 'POST',
    onSuccess: (_response) => {
      invalidate();
    },
    ...options,
  });
};

export const useAddConfiscatedLeaseItemMutation = <
  AddConfiscatedLeaseItemRequest,
  ConfiscatedLeaseItemViewModel,
>(
  options:
    | MutationOptions<AddConfiscatedLeaseItemRequest, ConfiscatedLeaseItemViewModel>
    | undefined = undefined,
) => {
  const invalidate = useInvalidateQuery();

  return useEntityBackendMutation(confiscated, leaseItems, {
    method: 'POST',
    onSuccess: (_response) => {
      invalidate();
    },
    ...options,
  });
};
