import {
  makeStyles,
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Paper,
  Theme,
  Typography,
  Portal,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { useContractForm } from './useContractForm';
import { DatePicker, Input, Select, WarningTooltip } from 'components/form';
import { CounterpartyAutocompete } from './CounterpartyAutocompete';
import {
  PrepaymentAdvanceOrder,
  QuotaCalculationResult,
  ApplicationRole,
  ContractType,
  ContractPayConditionType,
} from 'schema/serverTypes';
import { CounterpartyType } from './types';
import { HeadSelect } from './HeadSelect';
import { alpha } from '@material-ui/core/styles';
import { Button, ModalForm, Role, useModalForm } from 'components';
import { FormProvider, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { OrderPaymentForm } from '../../tasks/OrderPaymentForm';
import { ApplicationRoles } from 'services';
import { NomenclaturesRequestForm } from '../CalculationForm/NomenclaturesRequestForm';
import { ContractDocumentList } from './ContractDocumentList';
import { RequisiteList } from './RequisiteList';
import { PaymentTermArray } from './PaymentTermArray';
import { TelematicsFields } from './Telematics';
import { useSendTo1CHandler } from '../useSendTo1CHandler';
import { Send1CForm } from '../Send1CForm';
import { RegionAutocomplete } from './RegionAutocomplete';
import { SimpleForm } from './DrawUpContract/SimpleForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panels: {
      marginBottom: (props: ContractFormProps) => (props.isTask ? '0' : '75px'),
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing(2),
      bottom: 0,
      left: 214,
      position: 'fixed',
      zIndex: 1,
    },
    actionsTask: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: theme.spacing(2),
      bottom: 0,
      position: 'static',
      zIndex: 1,
      marginBottom: theme.spacing(2),
    },
    details: {
      width: '100%',
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
    exceptions: {
      padding: theme.spacing(1),
      backgroundColor: alpha(theme.palette.warning.main, 0.07),
      fontSize: 10,
      lineHeight: 1.2,
      fontWeight: 400,
    },
    accordionDetails: {
      backgroundColor: theme.palette.common.white,
      '& + &': {
        marginTop: 10,
      },
    },
    warningIcon: {
      cursor: 'pointer',
      '&$warningIcon svg': {
        fill: theme.palette.darkAttention.main,
        cursor: 'pointer',
        pointerEvents: 'auto',
      },
    },
  })
);

export type ContractFormProps = {
  drawUpEnabled?: boolean;
  quota: QuotaCalculationResult;
  setIsDirty?: (value: boolean) => void;
  isTask?: boolean;
};

export const ContractForm = (props: ContractFormProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { quota, drawUpEnabled = false, setIsDirty, isTask = false } = props;
  const newNomenclatures = quota.input.nomenclatures.filter((item) => !item.code);

  const { onOpen: onOpen1C, onClose: onClose1C, open: open1C } = useModalForm();

  const {
    onSubmit,
    control,
    isSubmitting,
    isLoading,
    setValue,
    isLocked,
    allowedPaymentTypes,
    canExecuteContract,
    isDirty,
    watch,
    setError,
    clearErrors,
    errors,
    trigger,
    form,
    getValues,
  } = useContractForm(quota, quota.isContractCreationRequested ? onOpen1C : undefined);

  const {
    onOpen: onOpenOrderPayment,
    onClose: onCloseOrderPayment,
    open: openOrderPayment,
  } = useModalForm();

  const {
    onOpen: onOpenNomenclatures,
    onClose: onCloseNomenclatures,
    open: openNomenclatures,
  } = useModalForm();

  const {
    onOpen: onOpenDrawUpContract,
    onClose: onCloseDrawUpContract,
    open: openDrawUpContract,
  } = useModalForm();

  useEffect(() => {
    if (setIsDirty) {
      setIsDirty(isDirty);
    }
  }, [setIsDirty, isDirty]);

  const leaseSubject = useWatch({ control, name: 'leaseSubject' });

  const handleOnDrawUp = useCallback(
    async (event) => {
      event.preventDefault(); // Prevent any default browser behavior
      const isValid = await trigger(); // Trigger validation for all form fields

      if (isValid) {
        // Check if the form is valid
        if (drawUpEnabled) {
          onOpenDrawUpContract();
        }
      }
    },
    [drawUpEnabled, onOpenDrawUpContract, trigger] // Add trigger to dependencies
  );

  const handleOrderPayment = useCallback(() => {
    onOpenOrderPayment();
  }, [onOpenOrderPayment]);

  const contractNumber = watch('contractNumber');

  const { calculationMethod, payments } = quota.calculationResult;

  const hasNonPaymentMonth = useMemo(() => {
    return payments.some((payment) => payment.mainDebt === 0);
  }, [payments]);

  const availablePrepaymentAdvanceOrders = useMemo(() => {
    if (calculationMethod === 'annuity') {
      return [
        PrepaymentAdvanceOrder.EvenlyDuringLeaseTerm,
        PrepaymentAdvanceOrder.OneTimeWithFirstPayment,
      ];
    } else if (calculationMethod === 'superSeasonal') {
      return [
        PrepaymentAdvanceOrder.EvenlyDuringLeaseTerm,
        PrepaymentAdvanceOrder.EvenlyDuringFirst12Months,
        PrepaymentAdvanceOrder.OneTimeWithFirstPayment,
      ];
    } else if (calculationMethod === 'straightLine') {
      return [
        PrepaymentAdvanceOrder.EvenlyDuringFirst12Months,
        PrepaymentAdvanceOrder.OneTimeWithFirstPayment,
      ];
    } else if (calculationMethod === 'seasonal' && hasNonPaymentMonth) {
      return [
        PrepaymentAdvanceOrder.EvenlyDuringFirst12Months,
        PrepaymentAdvanceOrder.OneTimeWithFirstPayment,
      ];
    } else {
      return [
        PrepaymentAdvanceOrder.EvenlyDuringLeaseTerm,
        PrepaymentAdvanceOrder.EvenlyDuringFirst12Months,
        PrepaymentAdvanceOrder.OneTimeWithFirstPayment,
      ];
    }
  }, [calculationMethod, hasNonPaymentMonth]);

  const prepaymentAdvanceOrderTranslationKeys = {
    [PrepaymentAdvanceOrder.EvenlyDuringLeaseTerm]: t(
      'PrepaymentAdvanceOrder.EvenlyDuringLeaseTerm'
    ),
    [PrepaymentAdvanceOrder.EvenlyDuringFirst12Months]: t(
      'PrepaymentAdvanceOrder.EvenlyDuringFirst12Months'
    ),
    [PrepaymentAdvanceOrder.OneTimeWithFirstPayment]: t(
      'PrepaymentAdvanceOrder.OneTimeWithFirstPayment'
    ),
  };

  const lessorRequisites = watch('lessorRequisites');
  const dealerRequisites = watch('dealerRequisites');
  const lesseeRequisites = watch('lesseeRequisites');

  const advancePayConditionBlock = watch('advancePayConditionBlock');
  const postpaymentPayConditionBlock = watch('postpaymentPayConditionBlock');
  const prepaymentPayConditionBlock = watch('prepaymentPayConditionBlock');

  const calculatePayConditionsSum = (
    conditionBlockName:
      | 'advancePayConditionBlock'
      | 'postpaymentPayConditionBlock'
      | 'prepaymentPayConditionBlock'
  ) => {
    const conditions = watch(`${conditionBlockName}.payConditions`);
    return conditions.reduce((sum, item) => sum + (parseInt(item.payment.toString(), 10) || 0), 0);
  };

  const advancePayConditionsSum = calculatePayConditionsSum('advancePayConditionBlock');
  const postpaymentPayConditionsSum = calculatePayConditionsSum('postpaymentPayConditionBlock');
  const prepaymentPayConditionsSum = calculatePayConditionsSum('prepaymentPayConditionBlock');

  const validatePayConditions = useCallback(() => {
    const totalSum =
      advancePayConditionsSum + postpaymentPayConditionsSum + prepaymentPayConditionsSum;
    if (totalSum !== 100) {
      [
        'advancePayConditionBlock',
        'postpaymentPayConditionBlock',
        'prepaymentPayConditionBlock',
      ].forEach((blockName) => {
        const payConditions = watch(`${blockName}.payConditions` as any);
        payConditions.forEach((_: any, index: number) => {
          setError(`${blockName}.payConditions.${index}.payment` as any, {
            type: 'manual',
            message: 'Не соответствует условие оплат!',
          });
        });
      });
    } else {
      clearErrors('advancePayConditionBlock.payConditions');
      clearErrors('postpaymentPayConditionBlock.payConditions');
      clearErrors('prepaymentPayConditionBlock.payConditions');
    }
    return totalSum === 100;
  }, [
    advancePayConditionsSum,
    postpaymentPayConditionsSum,
    prepaymentPayConditionsSum,
    clearErrors,
    setError,
    watch,
  ]);

  const { handleSendTo1C, isSendingTo1C } = useSendTo1CHandler(quota.quotaId ?? 0, onClose1C);

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <div className={classes.panels}>
            <Accordion defaultExpanded={true} disabled>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="subtitle1">{t('GeneralInfo')}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container columnSpacing={2} rowSpacing={2.5}>
                  <Grid md={4} xs={24} item>
                    <Input
                      label={`${t('Contract number')}`}
                      name="contractNumber"
                      control={control}
                      disabled={true}
                    />
                  </Grid>
                  {contractNumber !== '' && (
                    <Grid md={4} xs={24} item>
                      <Select name="contractType" control={control} label={t('Contract type')}>
                        <MenuItem value={ContractType.Lease}>{t('ContractType.Lease')}</MenuItem>
                        <MenuItem value={ContractType.Loan}>{t('ContractType.Loan')}</MenuItem>
                        <MenuItem value={ContractType.LeaseNoVAT}>
                          {t('ContractType.LeaseNoVAT')}
                        </MenuItem>
                      </Select>
                    </Grid>
                  )}
                  <Grid item md={4} xs={24}>
                    <DatePicker label={`${t('Date')}`} name="date" control={control} />
                  </Grid>
                  <Grid item md={12} xs={24}>
                    <Input label={`${t('Lease subject')}`} name="leaseSubject" control={control} />
                  </Grid>
                  <Grid item xs={24}>
                    <Input
                      label={`${t('Lease subject in document')}`}
                      name="leaseSubjectInDocument"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={24}>
                    <RegionAutocomplete industryId={quota.industryLeasingProductId} />
                  </Grid>
                  <Grid item xs={24}>
                    <Typography variant={'body2'} className={classes.exceptions}>
                      {t('Regions.Exceptions')}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">{t('Parties to the deal')}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container columnSpacing={2} rowSpacing={2.5}>
                  <Grid md={12} xs={24} item>
                    <CounterpartyAutocompete
                      type={CounterpartyType.lessor}
                      control={control}
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item md={12} xs={24}>
                    <HeadSelect type={CounterpartyType.lessor} control={control} />
                  </Grid>
                  {lessorRequisites.length > 0 && (
                    <RequisiteList
                      name={'lessorRequisites'}
                      requisites={lessorRequisites}
                      control={control}
                      setError={setError}
                      clearErrors={clearErrors}
                      trigger={trigger}
                    />
                  )}
                  <Grid md={12} xs={24} item>
                    <CounterpartyAutocompete
                      type={CounterpartyType.dealer}
                      control={control}
                      setValue={setValue}
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xs={24}>
                    <HeadSelect type={CounterpartyType.dealer} control={control} />
                  </Grid>
                  {dealerRequisites.length > 0 && (
                    <RequisiteList
                      name={'dealerRequisites'}
                      requisites={dealerRequisites}
                      control={control}
                      setError={setError}
                      clearErrors={clearErrors}
                      trigger={trigger}
                    />
                  )}
                  <Grid md={12} xs={24} item>
                    <CounterpartyAutocompete
                      type={CounterpartyType.lessee}
                      control={control}
                      setValue={setValue}
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xs={24}>
                    <HeadSelect type={CounterpartyType.lessee} control={control} />
                  </Grid>
                  {lesseeRequisites.length > 0 && (
                    <RequisiteList
                      name={'lesseeRequisites'}
                      requisites={lesseeRequisites}
                      control={control}
                      setError={setError}
                      clearErrors={clearErrors}
                      trigger={trigger}
                    />
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">{t('Payment terms')}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container columnSpacing={2} rowSpacing={2.5}>
                  <Grid md={24} xs={24} item>
                    <PaymentTermArray
                      type={ContractPayConditionType.Advance}
                      control={control}
                      clearErrors={clearErrors}
                      setError={setError}
                      selectItems={advancePayConditionBlock.selectItems}
                      validatePayConditions={validatePayConditions}
                      sum={quota.agreement.prepaymentSalesContractAmount?.value ?? 0}
                    />
                  </Grid>
                  {quota.agreement.hasCurrencyCommission && (
                    <Grid md={24} xs={24} item>
                      <PaymentTermArray
                        type={ContractPayConditionType.Prepayment}
                        control={control}
                        clearErrors={clearErrors}
                        setError={setError}
                        selectItems={prepaymentPayConditionBlock.selectItems}
                        validatePayConditions={validatePayConditions}
                        sum={quota.agreement.prepaymentDealerPercents ?? 0}
                      />
                    </Grid>
                  )}
                  <Grid md={24} xs={24} item>
                    <PaymentTermArray
                      type={ContractPayConditionType.Postpayment}
                      control={control}
                      clearErrors={clearErrors}
                      setError={setError}
                      selectItems={postpaymentPayConditionBlock.selectItems}
                      validatePayConditions={validatePayConditions}
                      sum={
                        100 -
                        (quota.agreement.prepaymentSalesContractAmount?.value ?? 0) -
                        (quota.agreement.prepaymentDealerPercents ?? 0)
                      }
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">{t('Terms of delivery')}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Grid container columnSpacing={2} rowSpacing={2.5}>
                  <Grid item xs={24}>
                    <DatePicker
                      label={`${t('Delivery time')}`}
                      name="expectedShippingDate"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={24}>
                    <Input
                      label={`${t('Shipping address')}`}
                      name="shippingAddress"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={24}>
                    <Select
                      label={t('Prepayment advance order')}
                      name="prepaymentAdvanceOrder"
                      control={control}
                    >
                      {availablePrepaymentAdvanceOrders.map((order) => (
                        <MenuItem key={order} value={order}>
                          {prepaymentAdvanceOrderTranslationKeys[order]}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle1">{t('Telematics')}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TelematicsFields
                  type={quota.calculationResult.telematics}
                  control={control}
                  setValue={setValue}
                />
              </AccordionDetails>
            </Accordion>
            <Role role={[ApplicationRole.Admin, ApplicationRole.SalesSupport]}>
              {quota.contractNumber && (
                <ContractDocumentList contractNumber={quota.contractNumber} />
              )}
            </Role>
          </div>
          {isTask ? (
            <Paper square className={classes.actionsTask}>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || isLoading || isLocked}
                    onClick={onSubmit}
                  >
                    {t('Save')}
                  </Button>
                </div>
              </Role>
              <Role role={[ApplicationRole.Admin, ApplicationRole.SalesSupport]}>
                <div className={classes.actionButton}>
                  <Button
                    variant="contained"
                    onClick={handleSendTo1C}
                    disabled={!quota.canBeSendTo1C || isSendingTo1C}
                  >
                    {t('Send to 1C')}
                  </Button>
                </div>
              </Role>
              <Role role={[ApplicationRole.Admin, ApplicationRole.InsideSales]}>
                {drawUpEnabled && !quota.isLocked && (
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      disabled={isSubmitting || isLoading || !canExecuteContract}
                      className={newNomenclatures.length > 0 ? 'Mui-disabled' : ''}
                      onClick={(event) => handleOnDrawUp(event)}
                      startIcon={
                        newNomenclatures.length > 0 && (
                          <WarningTooltip
                            arrow
                            placement="bottom-start"
                            className={classes.warningIcon}
                            onOpenNomenclatures={onOpenNomenclatures}
                            taskIds={
                              newNomenclatures.filter((item: any) => !item.taskId).length > 0
                                ? []
                                : newNomenclatures
                                    .filter((item: any) => item.taskId)
                                    .map((item: any) => item.taskId)
                            }
                          />
                        )
                      }
                    >
                      {t('Draw up contract')}
                    </Button>
                  </div>
                )}
              </Role>
              <Role
                role={[
                  ApplicationRole.Admin,
                  ApplicationRole.SuperSalesManager,
                  ApplicationRole.SalesManager,
                  ApplicationRole.RiskManager,
                ]}
              >
                <div className={classes.actionButton}>
                  <Button
                    color="primary"
                    size="medium"
                    type="button"
                    variant="outlined"
                    disabled={isSubmitting || isLoading || allowedPaymentTypes.length === 0}
                    onClick={handleOrderPayment}
                  >
                    {t('Order payment')}
                  </Button>
                </div>
              </Role>
            </Paper>
          ) : (
            <Portal container={document.body}>
              <Paper square className={classes.actions}>
                <Role role={ApplicationRoles.allExceptAssetManager}>
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="submit"
                      variant="contained"
                      disabled={
                        isSubmitting || isLoading || isLocked || Object.keys(errors).length > 0
                      }
                      onClick={onSubmit}
                    >
                      {t('Save')}
                    </Button>
                  </div>
                </Role>
                <Role role={[ApplicationRole.Admin, ApplicationRole.SalesSupport]}>
                  <div className={classes.actionButton}>
                    <Button
                      variant="contained"
                      onClick={handleSendTo1C}
                      disabled={!quota.canBeSendTo1C || isSendingTo1C}
                    >
                      {t('Send to 1C')}
                    </Button>
                  </div>
                </Role>
                <Role role={[ApplicationRole.Admin, ApplicationRole.InsideSales]}>
                  {drawUpEnabled && !quota.isLocked && (
                    <div className={classes.actionButton}>
                      <Button
                        color="primary"
                        size="medium"
                        type="button"
                        variant="outlined"
                        onClick={(event) => handleOnDrawUp(event)}
                        disabled={isLoading || isSubmitting || !canExecuteContract}
                        className={newNomenclatures.length > 0 ? 'Mui-disabled' : ''}
                        startIcon={
                          newNomenclatures.length > 0 && (
                            <WarningTooltip
                              arrow
                              placement="bottom-start"
                              className={classes.warningIcon}
                              onOpenNomenclatures={onOpenNomenclatures}
                              taskIds={
                                newNomenclatures.filter((item: any) => !item.taskId).length > 0
                                  ? []
                                  : newNomenclatures
                                      .filter((item: any) => item.taskId)
                                      .map((item: any) => item.taskId)
                              }
                            />
                          )
                        }
                      >
                        {t('Draw up contract')}
                      </Button>
                    </div>
                  )}
                </Role>
                <Role
                  role={[
                    ApplicationRole.Admin,
                    ApplicationRole.SuperSalesManager,
                    ApplicationRole.SalesManager,
                    ApplicationRole.RiskManager,
                    ApplicationRole.InsideSales,
                  ]}
                >
                  <div className={classes.actionButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="button"
                      variant="outlined"
                      disabled={isSubmitting || isLoading || allowedPaymentTypes.length === 0}
                      onClick={handleOrderPayment}
                    >
                      {t('Order payment')}
                    </Button>
                  </div>
                </Role>
              </Paper>
            </Portal>
          )}
        </form>
      </FormProvider>
      <ModalForm open={openOrderPayment} onClose={onCloseOrderPayment}>
        <OrderPaymentForm
          quotaId={quota?.quotaId}
          leaseSubject={leaseSubject}
          allowedPaymentTypes={allowedPaymentTypes}
          onSuccess={() => {}}
        />
      </ModalForm>
      {quota?.quotaId && newNomenclatures.length > 0 && (
        <ModalForm open={openNomenclatures} onClose={onCloseNomenclatures}>
          <NomenclaturesRequestForm
            newNomenclatures={newNomenclatures.filter((item: any) => !item.taskId)}
            quotaId={quota?.quotaId}
            onClose={onCloseNomenclatures}
          />
        </ModalForm>
      )}
      <ModalForm open={open1C} onClose={onClose1C}>
        <Send1CForm
          quotaId={quota?.quotaId ?? 0}
          handleSendTo1C={handleSendTo1C}
          onClose={onClose1C}
        />
      </ModalForm>
      <ModalForm open={openDrawUpContract} onClose={onCloseDrawUpContract}>
        <SimpleForm quota={quota} contract={getValues()} onClose={onCloseDrawUpContract} />
      </ModalForm>
    </>
  );
};
