import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Input, useToast } from 'components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CalculationInputSettings } from 'schema/serverTypes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useUpdateCalculationSettingsMutation } from 'services';
import { useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
    },
    number: {
      '& input': {
        textAlign: 'right',
      },
    },
    button: {
      display: 'block',
    },
  }),
);

type SettingsFormInnerProps = {
  defaultValues: CalculationInputSettings;
};

export const CalculationSettingsForm = (props: SettingsFormInnerProps) => {
  const classes = useStyles();
  const toast = useToast();

  const { control, handleSubmit } = useForm<CalculationInputSettings>({
    mode: 'onBlur',
    defaultValues: props.defaultValues,
  });

  const { mutateAsync, isLoading } = useUpdateCalculationSettingsMutation({
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: CalculationInputSettings) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle1">{t('Calculation settings')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2} rowSpacing={2.5}>
            <Grid item md={4} xs={24}>
              <Input
                name="cof"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9.\-\s]+$/i,
                }}
                label={`${t('Cof')}, %`}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                name="cofFloatRate"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9.\-\s]+$/i,
                }}
                label={`${t('Cof float rate')}, %`}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                name="margin"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9.\-\s]+$/i,
                }}
                label={t('Margin')}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                name="minMargin"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9.\-\s]+$/i,
                }}
                label={t('Min margin')}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                name="maxMargin"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9.\-\s]+$/i,
                }}
                label={t('Max margin')}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                name="tradeFee"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9,\-,\s]+$/i,
                }}
                label={`${t('Commission')}, %`}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                name="vat"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9.\-\s]+$/i,
                }}
                label={`${t('Vat')}, %`}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                name="firstRedemptionMonth"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9.\-\s]+$/i,
                }}
                label={t('FirstRedemptionMonth')}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                name="redemptionReward"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9,\-,\s]+$/i,
                }}
                label={`${t('RedemptionReward')}, %`}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                name="monthsWithoutRedemption"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9.\-\s]+$/i,
                }}
                label={t('MonthsWithoutRedemption')}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                name="redemptionDefaultAmount"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9.\-\s]+$/i,
                }}
                label={t('RedemptionDefaultAmount')}
              />
            </Grid>
            <Grid item md={24} xs={24}>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
                disabled={isLoading}
              >
                {t('Save')}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </form>
  );
};
