import { useTranslation } from 'react-i18next';
import { AmountInput } from '../../AmountInput';
import { useCalculationFormContext } from '../../types';
import { useWatch } from 'react-hook-form';
import { CalculationMethodType } from 'schema/serverTypes';
import React, { useEffect } from 'react';

export const SubsidyVendorInput = () => {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useCalculationFormContext();
  const calculationMethodType = useWatch({ control, name: 'calculationMethodType' });

  const disabled = calculationMethodType !== CalculationMethodType.Forward;

  useEffect(() => {
    const currentSubsidyDealer = getValues('subsidyVendor');
    if (
      calculationMethodType !== CalculationMethodType.Forward &&
      currentSubsidyDealer !== undefined
    ) {
      setValue('subsidyVendor', undefined);
    }
  }, [calculationMethodType, setValue, getValues]);

  return (
    <AmountInput
      name="subsidyVendor"
      label={t('Vendor subsidy')}
      useSaleCurrency={true}
      disabled={disabled}
    />
  );
};
