import { SvgIcon } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { IconColors } from './types';
import { themeOrange as theme } from 'theme';

export type IconCheckboxProps = IconColors & {
  checked: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(0.6),
      marginTop: theme.spacing(0.6),
    },
  })
);

export const IconCheckbox = (props: IconCheckboxProps) => {
  const {
    stroke = theme.palette.text.primary,
    fill = theme.palette.background.paper,
    checked,
    disabled,
  } = props;
  const classes = useStyles();

  const disabledColor = theme.palette.text.secondary;

  return checked ? (
    <SvgIcon className={classes.root}>
      <rect
        x="1"
        y="1"
        width="18"
        height="18"
        rx="2"
        fill={disabled ? disabledColor : theme.palette.primary.main}
        stroke={disabled ? disabledColor : theme.palette.primary.main}
        strokeWidth="2"
      />
      <path d="M8 14.4L4 10.4L5.4 9L8 11.6L14.6 5L16 6.4L8 14.4Z" fill={fill} />
    </SvgIcon>
  ) : (
    <SvgIcon className={classes.root}>
      <rect
        x="1"
        y="1"
        width="18"
        height="18"
        rx="1"
        stroke={disabled ? disabledColor : stroke}
        fill={fill}
        strokeWidth="2"
      />
    </SvgIcon>
  );
};
