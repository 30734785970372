import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ApplicationRole, ConfiscatedParkingViewModel } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';
import { Role } from '../../authentication';
import { useParkingsData } from './useParkingsData';
import { EditButton } from 'components/dictionaries/EditButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    row: {
      '&:hover $editBtn': {
        display: 'block',
      },
    },
    edit: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      position: 'sticky',
      right: 0,
      width: 80,
    },
    editBtn: {
      display: 'none',
    },
  }),
);

export type ConfiscatedParkingsTableProps = Omit<
  ReturnType<typeof useParkingsData>,
  'paging' | 'filter'
>;

type TableItemProps = {
  row: ConfiscatedParkingViewModel;
};

const TableItem = (props: TableItemProps) => {
  const classes = useStyles();
  const { row } = props;

  const editHandler = () => {};

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell size="medium">{row.name}</TableCell>
        <TableCell size="medium">{row.address}</TableCell>
        <TableCell size="medium">{row.manager?.name}</TableCell>
        <Role role={[ApplicationRole.RiskManager, ApplicationRole.Admin]}>
          <TableCell size="medium" className={classes.edit}>
            <div className={classes.editBtn}>
              <EditButton onClick={editHandler} />
            </div>
          </TableCell>
        </Role>
      </TableRow>
    </>
  );
};

export const ConfiscatedParkingsTable = (props: ConfiscatedParkingsTableProps) => {
  const classes = useStyles();
  const { data, sorting } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="medium">
              <TableSortLabel columnName="name" sorting={sorting}>
                {t('Name')}
              </TableSortLabel>
            </TableCell>
            <TableCell size="medium">{t('Address')}</TableCell>
            <TableCell size="medium">
              <TableSortLabel columnName="managerName" sorting={sorting}>
                {t('Manager')}
              </TableSortLabel>
            </TableCell>
            <Role role={[ApplicationRole.RiskManager, ApplicationRole.Admin]}>
              <TableCell size="medium" className={classes.edit} />
            </Role>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableItem key={item.id} row={item} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
