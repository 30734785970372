import React, { useCallback } from 'react';
import { MenuItem } from '@material-ui/core';
import { Grid } from 'components/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Control, FieldArrayWithId } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconSprite } from 'components/icons';
import { themeOrange as theme } from 'theme';
import { Input, Select } from 'components';
import { ContractFormValues } from '../types';
import { ContractPayConditionSubType } from 'schema';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& + &': {
        marginTop: '28px',
      },
    },
    head: {
      color: theme.palette.secondary.main,
    },
    delete: {
      justifySelf: 'end',
      cursor: 'pointer',
      '& > div': {
        backgroundColor: theme.palette.background.default,
        width: 38,
        height: 38,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    text: {
      display: 'flex',
      gap: theme.spacing(1),
      '& > div': {
        fontSize: 12,
      },
    },
  })
);

export type PaymentTermFieldsProps = {
  control: Control<ContractFormValues, any>;
  item: FieldArrayWithId<
    ContractFormValues,
    | 'advancePayConditionBlock.payConditions'
    | 'prepaymentPayConditionBlock.payConditions'
    | 'postpaymentPayConditionBlock.payConditions',
    'id'
  >;
  index: number;
  remove: (index?: number | number[] | undefined) => void;
  blockName:
    | 'advancePayConditionBlock.payConditions'
    | 'prepaymentPayConditionBlock.payConditions'
    | 'postpaymentPayConditionBlock.payConditions';
  subType: ContractPayConditionSubType;
  description: string;
  text: string;
  validatePayConditions: () => boolean;
  validateSum: () => boolean;
  sum: number;
};

export const PaymentTermFields = (props: PaymentTermFieldsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { index, remove, control, blockName, subType, description, text, validateSum, sum } = props;

  const onDelete = useCallback(() => {
    remove(index);
  }, [remove, index]);

  return (
    <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
      <Grid item md={12} xs={24}>
        <Select
          label={t('Description')}
          name={`${blockName}.${index}.subType` as const}
          control={control}
          defaultValue={subType}
        >
          <MenuItem value={subType}>{description}</MenuItem>
        </Select>
      </Grid>
      <Grid item md={6} xs={24}>
        <Input
          label={t('Payment percentage')}
          name={`${blockName}.${index}.payment` as const}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Required'),
            },
            pattern: /^[0-9,\-\s]+$/i,
            min: {
              value: 0,
              message: 'Минимум 0%',
            },
            max: {
              value: 100,
              message: 'Максимум 100%',
            },
            validate: {
              isValidBlockSum: (value) => {
                const isValid = validateSum();
                return isValid || `Сумма процентов платежа в блоке должна быть ${sum}`;
              },
              // isValidPercentage: (value) => {
              //   const isValid = validatePayConditions();
              //   return isValid || 'Не соответствует условие оплат!';
              // },
            },
          }}
        />
      </Grid>
      <Grid item md={5} xs={24}>
        <Input
          label={t('DayCount')}
          name={`${blockName}.${index}.dayCount` as const}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Required'),
            },
            pattern: /^[0-9,\-\s]+$/i,
          }}
        />
      </Grid>
      <Grid item xs={1} className={classes.delete}>
        <div onClick={onDelete}>
          <IconSprite icon="delete" color={theme.palette.text.primary} width="14px" height="15px" />
        </div>
      </Grid>
      <Grid item xs={24} className={classes.text}>
        <Typography variant={'body2'} color={'textSecondary'} component={'div'}>
          Представление:
        </Typography>
        <Typography variant={'body2'} color={'textPrimary'} component={'div'}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
