import React, { useCallback, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Breadcrumbs, Paging } from 'components';
import { ModalForm, useModalForm } from '../../Modal/ModalForm';
import { AddButton } from '../AddButton';
import { QuotaLostReason } from 'schema/serverTypes';
import { useQuotaLostReasonData } from './useQuotaLostReasonData';
import { QuotaLostReasonsFilterForm } from './QuotaLostReasonsFilterForm';
import { QuotaLostReasonsTable } from './QuotaLostReasonsTable';
import { EditQuotaLostReasonForm } from './EditQuotaLostReasonForm';
import { AddQuotaLostReasonForm } from './AddQuotaLostReasonForm';
import { useTranslation } from 'react-i18next';
import { usePageTitle } from 'components/utils/usePageTitle';

export const QuotaLostReasonsDataGrid = () => {
  const { t } = useTranslation();
  const { filter, paging, sorting, ...dataProps } = useQuotaLostReasonData();
  const { onOpen, onClose, open } = useModalForm();
  const [reason, setReason] = useState<QuotaLostReason>();

  const pageTitle = t('Dictionaries.QuotaLostReasons') + ' - Bumblebee';
  usePageTitle(pageTitle);

  const handleOnOpen = useCallback(
    (item: QuotaLostReason) => {
      setReason(item);
      onOpen();
    },
    [onOpen],
  );

  const handleOnClose = useCallback(() => {
    setReason(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box>
          <QuotaLostReasonsFilterForm {...filter} />
        </Box>
        <Box>
          <AddButton onClick={onOpen} />
        </Box>
      </Box>
      <Grid container item direction="column">
        <Grid item>
          <QuotaLostReasonsTable {...dataProps} sorting={sorting} onOpen={handleOnOpen} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
      <ModalForm open={open} onClose={handleOnClose}>
        {reason && <EditQuotaLostReasonForm onSuccess={handleOnClose} reason={reason} />}
        {reason === undefined && <AddQuotaLostReasonForm onSuccess={handleOnClose} />}
      </ModalForm>
    </>
  );
};
