import React, { useMemo } from 'react';
import { themeOrange as theme } from 'theme';
import { makeStyles, createStyles, Theme, Typography, MenuItem } from '@material-ui/core';
import {
  ContractType,
  QuotaCalculationResult,
  SendQuotaToExecutionViewModel,
  UpdateContractViewModel,
} from 'schema/serverTypes';
import { useIssueContractMutation } from 'services';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useToast, Grid, Input, Select, Button } from 'components';
import { Link } from 'react-router-dom';
import { ContractFormValues } from '../types';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      marginBottom: theme.spacing(3),
    },
    left: {
      textAlign: 'left',
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
      '&$success': {
        border: `1px solid ${theme.palette.success.main}`,
        backgroundColor: theme.palette.success.main,
      },
    },
    success: {},
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  })
);

export type SimpleFormProps = {
  quota: QuotaCalculationResult;
  contract: ContractFormValues;
  onClose: (ev?: any) => void;
};

export const SimpleForm = (props: SimpleFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    onClose,
    quota: { quotaId = 0 },
    contract,
    contract: { leaseSubject = '' },
  } = props;
  const title = leaseSubject !== '' ? `№${quotaId} | ${leaseSubject}` : `№${quotaId}`;

  const defaultValues: SendQuotaToExecutionViewModel = useMemo(() => {
    const values = {
      documentUrl: '',
      description: '',
      title,
      link: {
        url: `/quotas/calculator/${quotaId}`,
        title: `№${quotaId}`,
      },
      contractType: ContractType.Lease,
      contract: contract as unknown as UpdateContractViewModel,
    };

    return values;
  }, [quotaId, title, contract]);

  const { control, handleSubmit } = useForm({
    mode: 'all',
    defaultValues,
  });
  const toast = useToast();

  const { mutateAsync } = useIssueContractMutation<SendQuotaToExecutionViewModel, any>(quotaId, {
    method: 'PUT',
    onSuccess: (result) => {
      toast(
        <>
          Создана задача №
          <Link
            to={`/tasks/${result.issueId}`}
            style={{ color: theme.palette.primary.main }}
            className={classes.link}
          >
            {result.issueId}
          </Link>
        </>,
        'success'
      );
      onClose();
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: SendQuotaToExecutionViewModel) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const message = t('Required');

  return (
    <form id="newTask-form" onSubmit={onSubmit}>
      <div className={classes.modalRoot}>
        <Typography variant={'h2'} className={classes.modalTitle}>
          {t('Draw up contract')}
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={2.5}>
          <Grid item xs={24} className={classes.left}>
            <Select
              name="contractType"
              control={control}
              label={t('Contract type')}
              rules={{
                required: {
                  value: true,
                  message,
                },
              }}
            >
              <MenuItem value={ContractType.Lease}>{t('ContractType.Lease')}</MenuItem>
              <MenuItem value={ContractType.Loan}>{t('ContractType.Loan')}</MenuItem>
              <MenuItem value={ContractType.LeaseNoVAT}>{t('ContractType.LeaseNoVAT')}</MenuItem>
            </Select>
          </Grid>
          <Grid item md={24} xs={24}>
            <Input
              label={t('Link to documents')}
              control={control}
              name="documentUrl"
              rules={{
                required: {
                  value: true,
                  message,
                },
              }}
            />
          </Grid>
          <Grid item xs={24}>
            <Input label={t('Description')} control={control} name="description" multiline={true} />
          </Grid>
          <Grid item xs={24}>
            <Button
              color="primary"
              size="medium"
              type="submit"
              variant="contained"
              onClick={onSubmit}
              className={classes.modalButton}
            >
              {t('Create task')}
            </Button>
            <Button
              onClick={onClose}
              color="primary"
              size="medium"
              variant="outlined"
              className={classes.modalButton}
            >
              {t('Cancel')}
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
