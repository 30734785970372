import { Control, useWatch } from 'react-hook-form';
import { CalculatorFormValues } from './types';
import { useNomenclatureListSearchQuery } from '../../../services/api/useNomenclaturesBackend';
import { useLeasingProductByBrandQuery } from '../../../services/api/useLeasingProductBrandsBackend';

export const useLeasingProductByNomenclature = (control: Control<CalculatorFormValues, any>) => {
  const nomenclatures = useWatch({ control, name: 'nomenclatures' });

  const codes = nomenclatures.map((nomenclature) => nomenclature.code ?? '');

  const { data } = useNomenclatureListSearchQuery({
    code: codes,
  });

  const vendors = data?.data.map((item) => item.vendor ?? '') || [];
  const allVendorsSame = vendors.every((vendor) => vendor === vendors[0]);
  const vendor = allVendorsSame ? vendors[0] : '';

  const { data: leasingProductByNomenclature } = useLeasingProductByBrandQuery({ brand: vendor });

  return {
    leasingProductByNomenclature,
    vendor,
  };
};
