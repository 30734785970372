import { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete, Chip } from 'components';
import { UserViewModel } from 'schema/serverTypes';
import { useUsersBackendQuery } from 'services/api';
import { UserListResult } from 'components/users/types';
import { Control, useController, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GroupEditFormValues } from './types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
  })
);

const getOptionLabel = (option: UserViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (option: UserViewModel | null, value: UserViewModel | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.id === value.id;
};

const useSearchParams = (search?: string) => {
  return useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('page', '1');
    searchParams.set('pageSize', '20');

    if (search && search !== '') {
      searchParams.set('search', search);
    }

    return searchParams;
  }, [search]);
};

type UsersAutocompleteProps = {
  control: Control<GroupEditFormValues, any>;
  setValue: UseFormSetValue<GroupEditFormValues>;
};

export const UsersAutocomplete = (props: UsersAutocompleteProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, setValue } = props;

  const {
    field: { value: users = null, onChange },
  } = useController({
    control,
    name: 'users',
  });

  const [user, setUser] = useState('');
  const [input] = useDebounce(user, 300);
  const searchParams = useSearchParams(input);
  const { data } = useUsersBackendQuery<UserListResult>({
    searchParams,
    queryKey: ['users', input],
  });

  const options: UserViewModel[] =
    data?.data.filter((t) => {
      if (users === undefined) {
        return true;
      }
      const usersById = users?.find((m) => m.id === t.id);
      return usersById === undefined;
    }) ?? [];

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setUser(value);
    },
    [setUser]
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: UserViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<UserViewModel | null>
    ) => {
      if (value === null) {
        onChange([]);
        setUser('');
      } else {
        onChange(users ? [...users.filter((v) => v.id !== value.id), value] : [value]);
        setUser('');
      }
    },
    [onChange, users]
  );

  const onUserIdRemove = useCallback(
    (id: string) => {
      const ids = users?.filter((v) => v.id !== id) ?? [];
      setValue('users', ids);
    },
    [setValue, users]
  );

  const userChips = users?.map((user) => {
    return (
      <Chip label={user.name} id={user.id} key={`user-${user.id}`} onRemove={onUserIdRemove} />
    );
  });

  return (
    <>
      <Autocomplete<UserViewModel | null>
        label={t('Members')}
        options={options}
        inputValue={user}
        onInputChange={handleOnInputChange}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onChange={handleOnChange}
        variant="outlined"
        value={null}
        className={classes.root}
        disableCloseOnSelect
      />
      {userChips}
    </>
  );
};
