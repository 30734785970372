import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Breadcrumbs, ModalForm, useModalForm } from 'components';
import { TelematicsDictionaryItemViewModel } from 'schema/serverTypes';
import { TelematicsTable } from './TelematicsTable';
import { useTranslation } from 'react-i18next';
import { usePageTitle } from 'components/utils/usePageTitle';
import { TelematicsEditForm } from './TelematicsEditForm';
import { useTelematicsData } from './useTelematicsData';

export const TelematicsDataGrid = () => {
  const { t } = useTranslation();
  const { rows, isLoading, sorting } = useTelematicsData();
  const { onOpen, onClose, open } = useModalForm();

  const [item, setItem] = useState<TelematicsDictionaryItemViewModel>();

  const pageTitle = t('Telematics') + ' - Bumblebee';
  usePageTitle(pageTitle);

  const handleOnOpen = useCallback(
    (item: TelematicsDictionaryItemViewModel) => {
      setItem(item);
      onOpen();
    },
    [onOpen],
  );

  const handleOnClose = useCallback(() => {
    setItem(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Grid container item direction="column">
        <Grid item>
          <TelematicsTable
            rows={rows}
            onOpen={handleOnOpen}
            loading={isLoading}
            sorting={sorting}
          />
        </Grid>
      </Grid>
      <ModalForm open={open} onClose={handleOnClose}>
        {item && <TelematicsEditForm onSuccess={handleOnClose} item={item} />}
      </ModalForm>
    </>
  );
};
