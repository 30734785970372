import { Sidebar } from 'components/Sidebar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Button, Checkbox } from 'components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import {
  DealQualificationGroup,
  DealQualificationRuleViewModel,
  DealQualificationType,
  QuotaDealQualificationRuleViewModel,
} from 'schema';
import { groupMap } from '../../dictionaries/Qualifications/QualificationsTable';
import {
  useQuotaDealQualificationQuery,
  useUpdateQuotaDealQualificationMutation,
  useUserRole,
} from 'services';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '838px',
      minWidth: '838px',
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
      //position: 'relative',
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      paddingTop: theme.spacing(5.5),
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingRight: 40,
      paddingLeft: 40,
      paddingBottom: 20,
    },
    cardContent: {
      paddingTop: 20,
      paddingRight: 40,
      paddingBottom: 84,
      paddingLeft: 40,
      overflowY: 'auto',

      marginBottom: theme.spacing(3),
    },
    actions: {
      display: 'block',
      position: 'absolute',
      bottom: 20,
      paddingLeft: 40,
      backgroundColor: theme.palette.common.white,
      width: '100%',
    },
    groupsContainer: {
      columns: 2,
      columnGap: '16px',
    },
    group: {
      padding: '10px',
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      breakInside: 'avoid',
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    groupTitle: {
      marginBottom: theme.spacing(1.5),
    },
    checkbox: {
      marginBottom: theme.spacing(1.25),
    },
    ruleName: {
      display: 'inline-block',
      borderRadius: 50,
      border: `1px solid ${theme.palette.primaryGradient[400]}`,
      lineHeight: '14px',
      padding: theme.spacing(0.5, 1),
    },
    button: {
      marginTop: theme.spacing(3.5),
    },
  })
);

type DataForQualificationsEditPanelProps = {
  onClose: () => void;
  quotaId?: number;
};

type QualificationsEditPanelProps = {
  onClose: () => void;
  quotaId?: number;
  data: QuotaDealQualificationRuleViewModel[];
};

export const DataForQualificationsEditPanel = (props: DataForQualificationsEditPanelProps) => {
  const { quotaId = 0 } = props;
  const { data } = useQuotaDealQualificationQuery(quotaId);
  if (!data) return null;
  return <QualificationsEditPanel {...props} data={data} />;
};

const QualificationsEditPanel = (props: QualificationsEditPanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { quotaId = 0, onClose, data } = props;

  const { isSalesSupport } = useUserRole();
  const queryClient = useQueryClient();

  const getDefaultValues = useMemo(() => {
    return data.reduce<Record<number, boolean>>(
      (accumulator, currentItem: QuotaDealQualificationRuleViewModel) => {
        accumulator[currentItem.id] = currentItem.isValid;
        return accumulator;
      },
      {}
    );
  }, [data]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm<any>({
    mode: 'onBlur',
    defaultValues: getDefaultValues,
  });

  useEffect(() => {
    reset(getDefaultValues);
  }, [reset, data, getDefaultValues]);

  const { mutateAsync } = useUpdateQuotaDealQualificationMutation(quotaId);

  const onSubmit = useMemo(() => {
    const submit = async (form: any) => {
      let formToArray = Object.keys(form).map((key) => {
        return {
          id: parseInt(key, 10),
          isValid: form[key] ?? false,
        };
      });
      try {
        await mutateAsync(formToArray);
        queryClient.invalidateQueries({
          predicate: (query) => {
            return query.queryKey.indexOf(`${quotaId}/dealQualifications`) > -1;
          },
        });
        onClose();
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, onClose, queryClient, quotaId]);

  if (!data.length) return null;

  type DealQualificationRuleGroupBy<T> = T extends Record<string, unknown> ? keyof T : never;

  const groupBy = <T extends Record<string, any>>(
    data: T[],
    key: DealQualificationRuleGroupBy<T>
  ) => {
    return data.reduce((acc, curr) => {
      const groupKey = curr[key];
      if (groupKey !== undefined) {
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(curr);
      }
      return acc;
    }, {} as Record<string, T[]>);
  };

  const groupedData = groupBy(data, 'group');

  return (
    <Sidebar width={838} onClose={onClose}>
      <Card className={classes.root}>
        <Box className={classes.headerWrapper}>
          <CardHeader className={classes.header} title={t('Qualifications')} />
        </Box>
        <CardContent className={classes.cardContent}>
          <form onSubmit={onSubmit}>
            <div className={classes.groupsContainer}>
              {Object.keys(groupedData).map((key: any) => {
                const groupKey = key as DealQualificationGroup;
                return (
                  <div key={groupKey} className={classes.group}>
                    <Typography className={classes.groupTitle}>{groupMap[groupKey]}</Typography>
                    {groupedData[groupKey].map((item: DealQualificationRuleViewModel) => {
                      const isDisabled =
                        item.type === DealQualificationType.NoLimit ||
                        item.type === DealQualificationType.Rule;
                      return (
                        <div className={classes.checkbox} key={item.id}>
                          <Checkbox
                            name={item.id.toString()}
                            label={item.name}
                            control={control}
                            disabled={isDisabled}
                            noWrapLabel={false}
                          />
                          {item.rule && (
                            <div className={classes.ruleName}>
                              {item.rule.code} - {item.rule.name}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </form>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            variant={'contained'}
            disabled={isSubmitting}
            onClick={onSubmit}
            className={classes.button}
          >
            {isSalesSupport ? t('Qualify') : t('Save')}
          </Button>
        </CardActions>
      </Card>
    </Sidebar>
  );
};
