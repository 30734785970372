import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../Tooltip';
import React, { useState } from 'react';
import { useToast } from '../../Toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      fontSize: 12,
      color: theme.palette.text.primary,
      fontWeight: 400,
    },
    title: {
      color: theme.palette.text.secondary,
    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
      padding: theme.spacing(0, 0.5),
    },
  })
);

type PathLinkProps = {
  link: string;
};

export const PathLink = (props: PathLinkProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();
  const { link } = props;

  const [tooltipTitle, setTooltipTitle] = useState(t('Copy'));

  const copyToClipboard = () => {
    setTooltipTitle(t('Copied'));

    navigator.clipboard
      .writeText(link)
      .then(() => {
        // Возвращаем текст подсказки обратно через 2 секунды
        setTimeout(() => {
          setTooltipTitle(t('Copy'));
        }, 1000);
      })
      .catch((err) => {
        toast(t('ErrorMessage'), 'error');
        setTooltipTitle(t('Copy')); // Вернуть `title` при ошибке
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>{t('Decision')}:</div>
      <Tooltip title={tooltipTitle} arrow placement="top">
        <div className={classes.link} onClick={copyToClipboard}>
          {link}
        </div>
      </Tooltip>
    </div>
  );
};
