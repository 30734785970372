import { useCallback, useEffect, useMemo } from 'react';
import { ConfiscatedParkingFilter, ConfiscatedParkingSortBy, SortOrder } from 'schema/serverTypes';
import { useForm, useWatch } from 'react-hook-form';
import { usePaging } from 'components/paging/usePaging';
import { useSorting } from 'components/sorting/useSorting';
import { useConfiscatedParkingsPagedListQuery } from 'services/api';

export const useParkingsData = () => {
  const { sortBy, order, setOrder, setSortBy } = useSorting<ConfiscatedParkingSortBy>({
    sortBy: ConfiscatedParkingSortBy.Id,
    order: SortOrder.asc,
  });

  const handleSortBy = useCallback(
    (sortBy: ConfiscatedParkingSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy],
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder],
  );

  const sorting = useMemo(() => {
    return {
      sortBy,
      order,
      setSortBy: handleSortBy,
      setOrder: handleSortOrder,
    };
  }, [sortBy, order, handleSortBy, handleSortOrder]);

  const { control, reset } = useForm<ConfiscatedParkingFilter>({
    mode: 'all',
  });

  const handleOnReset = useCallback(() => {
    reset();
  }, [reset]);

  const name = useWatch({ control, name: 'name' });
  const search = useWatch({ control, name: 'search' });
  const address = useWatch({ control, name: 'address' });

  const { page, pageSize, onPageChanged, onPageSizeChanged, onReset } = usePaging();

  const { data, isLoading } = useConfiscatedParkingsPagedListQuery({
    name,
    search,
    address,
    page,
    pageSize: pageSize,
    sortBy,
    order,
  });

  const filter = useMemo(() => {
    return {
      control,
      handleOnReset,
    };
  }, [control, handleOnReset]);

  const rows = data?.data ?? [];
  const pageCount = data?.pageCount ?? 0;
  const totalCount = data?.totalCount ?? 0;
  const length = rows.length;

  const paging = useMemo(() => {
    return {
      pageCount,
      totalCount,
      page,
      pageSize,
      dataCount: length,
      onPageChanged,
      onPageSizeChanged,
      onReset,
    };
  }, [pageCount, totalCount, page, pageSize, length, onPageChanged, onPageSizeChanged, onReset]);

  useEffect(() => {
    onPageChanged(1);
  }, [name, onPageChanged]);

  return {
    paging,
    filter,
    sorting,
    isLoading,
    data: data?.data ?? [],
  };
};
