import { formatDate } from 'components/utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import dayjs from 'dayjs';

dayjs.extend(quarterOfYear);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: 12,
      fontWeight: 700,
      padding: theme.spacing(1.5, 2.5, 1),
      color: theme.palette.text.secondary,
      borderBottom: '1px solid #E3E6F3',
    },
    fromDate: {},
    owner: {
      fontSize: 10,
      fontWeight: 400,
    },
    actions: {},
  }),
);

export type RatingHeaderProps = {
  fromDate?: string;
  user?: string;
  updatedDate?: string;
};

export const RatingHeader = (props: RatingHeaderProps) => {
  const classes = useStyles();
  const { fromDate, user, updatedDate } = props;

  var humanQuoter =
    dayjs(fromDate).quarter() === 1
      ? '1 квартал'
      : dayjs(fromDate).quarter() === 2
        ? 'полугодие'
        : dayjs(fromDate).quarter() === 3
          ? '9 месяцев'
          : dayjs(fromDate).quarter() === 4
            ? 'год'
            : undefined;

  //у автоматически рассчитанных рейтингов дата 10 января, и для них название должно быть 'год'
  if (dayjs(fromDate).month() === 0 && dayjs(fromDate).date() === 10) {
    humanQuoter = 'год';
  }

  return (
    <header className={classes.header}>
      <span className={classes.fromDate}>
        {fromDate && `${humanQuoter} ${dayjs(fromDate).year()}`}
      </span>
      {user && updatedDate && (
        <div className={classes.owner}>
          {user} | {formatDate(updatedDate)}
        </div>
      )}
    </header>
  );
};
