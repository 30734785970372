import { Route } from 'react-router-dom';
import {
  MissingDataGrid,
  Critical,
  MissingCommentFormPanel,
  MissingCommentOverdueFormPanel,
} from 'components';
import { useErrorContext } from 'components/tasks';
import { useTranslation } from 'react-i18next';
import { IssueSource } from 'schema/serverTypes';
import { usePageTitle } from 'components/utils/usePageTitle';

const MissingGrid = () => {
  return <MissingDataGrid debt={true} />;
};

export const MissingPage = () => {
  const { t } = useTranslation();

  const pageTitle = t('Missing') + ' - Bumblebee';
  usePageTitle(pageTitle);

  useErrorContext({
    title: t('Missing'),
    source: IssueSource.missing,
  });

  return (
    <>
      <Critical />
      <Route path="/missing">
        <Route path="/missing" component={MissingGrid} />
        <Route path="/missing/comments/:id" component={MissingCommentFormPanel} exact />
        <Route path="/missing/overdue/comments" component={MissingCommentOverdueFormPanel} exact />
      </Route>
    </>
  );
};
