import { Sidebar } from 'components/Sidebar';
import { GroupForm } from '../GroupForm';

export const GroupCreateFormPanel = () => {
  return (
    <Sidebar url="/users/groups">
      <GroupForm />
    </Sidebar>
  );
};
