import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { LeasingProductsTable } from './LeasingProductsTable';
import { Breadcrumbs, Pagination } from 'components';
import { useLeasingProductsData } from './useLeasingProductsData';
import { AddButton } from '../AddButton';
import { useTranslation } from 'react-i18next';
import { SearchFilterForm } from './SearchFilterForm';
import { usePageTitle } from 'components/utils/usePageTitle';

export const LeasingProductsDataGrid = () => {
  const { t } = useTranslation();
  const { filter, paging, ...dataProps } = useLeasingProductsData();

  const pageTitle = t('Sales channel') + ' - Bumblebee';
  usePageTitle(pageTitle);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box>
          <SearchFilterForm {...filter} />
        </Box>
        <Box>
          <AddButton to={'/dictionaries/leasingProducts/add'} />
        </Box>
      </Box>
      <Grid container item direction="column">
        <Grid item>
          <LeasingProductsTable {...dataProps} />
        </Grid>
        <Grid item>
          <Pagination {...paging} />
        </Grid>
      </Grid>
    </>
  );
};
