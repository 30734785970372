import { Box, Grid } from '@material-ui/core';
import { Breadcrumbs, Paging } from 'components';
import { usePDCData } from './usePDCData';
import { PDCTable } from './PDCTable';
import { FilterForm } from './FilterForm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePageTitle } from 'components/utils/usePageTitle';

export const PDCDataGrid = () => {
  const { t } = useTranslation();
  const { filter, paging, ...dataProps } = usePDCData();

  const pageTitle = t('PDC') + ' - Bumblebee';
  usePageTitle(pageTitle);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box>
          <FilterForm {...filter} />
        </Box>
      </Box>
      <Grid container direction="column">
        <Grid item>
          <PDCTable {...dataProps} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
    </>
  );
};
