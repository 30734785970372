//@ts-nocheck
import { useParams } from 'react-router-dom';
import { Sidebar } from 'components/Sidebar';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { IconSprite } from '../../icons';
import { themeOrange as theme } from 'theme';
import {
  useAddCommentRatingMutation,
  useRatingCalculationsQuery,
} from '../../../services/api/useRatingsBackend';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { RatingViewModel } from 'schema/serverTypes';
import { Input } from '../../form';
import { Button } from '../../Button';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

dayjs.extend(quarterOfYear);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '440px',
      minWidth: '440px',
      border: 'none',
      boxShadow: 'none',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto auto 1fr auto',
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      paddingTop: theme.spacing(5.5),
      fontWeight: 'bolder',
      textAlign: 'center',
      paddingRight: 40,
      paddingLeft: 40,
      paddingBottom: 20,
    },
    titleWrapper: {
      paddingRight: 40,
      paddingLeft: 40,
      textAlign: 'center',
      margin: theme.spacing(2, 0, 0.5),
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      margin: theme.spacing(0, 0, 1),
    },
    planDate: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.common.black,
      margin: theme.spacing(1, 0, 0),
    },
    cardContent: {
      paddingTop: 20,
      paddingRight: 40,
      paddingBottom: 20,
      paddingLeft: 40,
      overflowY: 'auto',
      marginBottom: theme.spacing(3),
    },
    date: {
      color: theme.palette.secondary.dark,
      fontSize: 12,
      fontWeight: 400,
    },
    status: {
      marginBottom: theme.spacing(3),
    },
    statusText: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: 400,
      '&:before': {
        content: "' | '",
      },
    },
    message: {
      marginBottom: theme.spacing(3),
    },
    messageText: {
      color: theme.palette.common.black,
      fontSize: 14,
      fontWeight: 400,
      marginTop: theme.spacing(0.75),
    },
    analytics: {
      fontSize: 10,
      color: theme.palette.secondary.dark,
      marginTop: theme.spacing(2),
      textAlign: 'left',
    },

    actions: {
      display: 'block',
      boxShadow: '3px -3px 15px rgba(56, 60, 97, 0.1)',
      padding: theme.spacing(4, 5),
    },
    input: {
      marginBottom: theme.spacing(2),
    },
    closeButton: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& img': {
        maxWidth: '10px',
      },
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    star: {
      marginRight: 10,
    },
  })
);

export const RatingCommentFormPanel = () => {
  const { inn, id: ratingId } = useParams<{ id: string }>();
  const { data: rating } = useRatingCalculationsQuery(inn, [ratingId]);
  if (!rating) return null;
  return <RatingComments rating={rating[0]} />;
};

export const RatingComments = ({ rating }: RatingViewModel) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { inn, id: ratingId } = useParams<{ id: string }>();

  const comments = rating.comments ?? [];
  const period = rating.fromDate ?? '';

  let humanDayMonth =
    dayjs(period).quarter() === 1
      ? '1 квартал'
      : dayjs(period).quarter() === 2
      ? 'полугодие'
      : dayjs(period).quarter() === 3
      ? '9 месяцев'
      : dayjs(period).quarter() === 4
      ? 'год'
      : undefined;

  // Проверяем, является ли дата 10 января
  if (dayjs(period).isSame('YYYY-01-10', 'day')) {
    humanDayMonth = 'год';
  }

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
  });

  const text = watch('text');

  const queryClient = useQueryClient();

  const { mutateAsync } = useAddCommentRatingMutation(inn, ratingId, text, {
    method: 'POST',
    onSuccess: (_result) => {
      setValue('text', '');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' &&
            (query.queryKey as string).startsWith(`counterparties/${inn}`)
          );
        },
      });
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async () => {
      try {
        await mutateAsync();
      } catch (error) {
        console.log(error);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return (
    <Sidebar url="/counterparties" width={440}>
      <Card className={classes.root}>
        <Box className={classes.headerWrapper}>
          <CardHeader
            className={classes.header}
            title={`${humanDayMonth} ${dayjs(period).format('YYYY')}`}
          />
        </Box>
        <CardContent className={classes.cardContent}>
          {comments.map((c) => {
            return c.author ? (
              <Message
                date={dayjs(c.date).format('DD.MM.YYYY HH:mm')}
                text={c.text}
                author={c.author.name}
                key={c.id}
                deliveryStatus={c.deliveryStatus}
              />
            ) : (
              <Status key={c.id} date={dayjs(c.statusDate).format('DD.MM.YYYY')} text={c.text} />
            );
          })}
        </CardContent>
        <CardActions className={classes.actions}>
          <form onSubmit={onSubmit}>
            <Input
              label={t('Comment')}
              name={'text'}
              control={control}
              fullWidth
              rules={{
                required: {
                  value: true,
                  message: t('Required'),
                },
              }}
              className={classes.input}
            />
            <Button
              variant={'contained'}
              disabled={isSubmitting}
              type={'submit'}
              //onClick={onSubmit}
            >
              {t('Add')}
            </Button>
          </form>
        </CardActions>
      </Card>
    </Sidebar>
  );
};

const Status = ({ date, text }: { date: string; text: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.status}>
      <span className={classes.date}>{date}</span>
      <span className={classes.statusText}>{text}</span>
    </div>
  );
};

const Message = ({
  date,
  text,
  author,
  deliveryStatus,
}: {
  date: string;
  text: string;
  author?: string;
  deliveryStatus?: 'notFound' | 'delivered';
}) => {
  const classes = useStyles();

  return (
    <div className={classes.message}>
      <span className={classes.date}>{date}</span>
      <span className={classes.statusText}>{author}</span>
      <div className={classes.messageText}>
        {deliveryStatus === 'delivered' ? (
          <>
            <IconSprite
              width="10px"
              color={theme.palette.attention.main}
              icon="star"
              className={classes.star}
            />
            {text}
          </>
        ) : (
          text
        )}
      </div>
    </div>
  );
};
