import { useCallback, useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  Typography,
  Checkbox as MuiCheckbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from 'components/Grid';
import { DatePicker, Input } from 'components/form';
import { ShipmentItemProps } from './types';
import { useTranslation } from 'react-i18next';
import { useShipmentItemForm } from './useShipmentItemForm';
import dayjs from 'dayjs';
import { BaseSyntheticEvent, useState } from 'react';
import { Button } from 'components/Button';
import { TemplateKind } from 'schema/serverTypes';
import { PrintableTemplateList } from '../../templates/PrintableTemplatesList';
import { formatMoney, formatNumber, IconCheckbox } from 'components/index';
import { CounterpartySelect } from './CounterpartySelect';
import { FileUpload } from '../../templates/FileUpload';
import { useWatch } from 'react-hook-form';
import { useUserRole } from 'services/authentication/useUserRole';
import { IssueParam } from './IssueParam';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.bgGray.main,
    },
    wrapper: {
      width: '100%',
    },
    accordionRoot: {
      backgroundColor: 'white',
      marginBottom: 0,
      borderBottom: '1px solid #E3E6F3',
      '&.success': {
        backgroundColor: theme.palette.success.light,
        borderBottom: `1px solid ${theme.palette.success.main}`,
      },
      '&.error': {
        backgroundColor: '#FFF9FB',
        borderBottom: '1px solid #FD397A',
      },
    },
    leaseSubject: {
      '&&&': {
        fontSize: 10,
        fontWeight: 400,
      },
    },
    leaseTitle: {
      fontSize: 14,
      fontWeight: 600,
    },
    locked: {
      color: theme.palette.text.secondary,
    },
    accordionDetails: {
      backgroundColor: 'white',
      '& + &': {
        marginTop: 10,
        boxShadow: '0px 0px 10px rgba(56, 60, 97, 0.05)',
      },
    },
    accordionContent: {
      //justifyContent: 'space-between',
      padding: theme.spacing(1, 0),
      gap: theme.spacing(2),
      alignItems: 'center',
      '& .MuiTypography-root': {
        lineHeight: '21px',
        fontSize: '14px',
      },
    },
    accordionDate: {
      minWidth: 220,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
      display: 'flex',
      alignItems: 'center',
      '& a': {
        color: theme.palette.primary.main,
      },
    },
    or: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    subtitleContainer: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    subtitle: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.text.secondary,
      display: 'inline-block',
    },
    shipped: {
      color: theme.palette.success.main,
    },
    expectedShipment: {
      color: theme.palette.primary.main,
    },
    noneDate: {
      color: theme.palette.text.primary,
    },
    nbvContainer: {
      textAlign: 'right',
    },
    nbvTitle: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.text.secondary,
      display: 'inline-block',
      margin: '0 8px',
    },
    nbvValue: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
  })
);

export const ShipmentItem = (props: ShipmentItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    item,
    quotaId,
    dealer,
    lessee,
    lessor,
    isLoading,
    authorId,
    setIsDirty,
    isDisabledCheckbox,
  } = props;

  const [isOpen, setOpen] = useState<boolean>(false);
  const [isDocumentsOpen, setDocumentsOpen] = useState<boolean>(false);
  const { isChecked, onCheckChange } = props;
  // Обработчик для чекбокса в ShipmentItem
  const handleCheckChange = () => {
    if (onCheckChange) {
      onCheckChange(!isChecked);
    }
  };

  const {
    control,
    isSubmitting,
    onSubmitUpdate,
    dealerHeads,
    lesseeHeads,
    lessorHeads,
    isHeadsLoading,
    setValue,
    isDirty,
  } = useShipmentItemForm(quotaId, item, dealer, lessee, lessor);

  useEffect(() => {
    if (setIsDirty) {
      setIsDirty(isDirty);
    }
  }, [setIsDirty, isDirty]);

  const shipmentDateValue = useWatch({ control, name: 'shipmentDate' });
  const expectedShipmentDateValue = useWatch({ control, name: 'expectedShipmentDate' });
  const status = shipmentDateValue
    ? 'success'
    : dayjs(expectedShipmentDateValue).diff(dayjs()) < 0
    ? 'error'
    : '';

  const stopBubble = useCallback((ev: BaseSyntheticEvent) => {
    ev?.stopPropagation();
  }, []);

  const handleOnExpand = useCallback(() => setOpen((prev: boolean) => !prev), [setOpen]);
  const openHandler = useCallback(() => {
    setDocumentsOpen(true);
  }, [setDocumentsOpen]);

  const [isShip, setShip] = useState<boolean>(false);

  const saveHandler = useCallback(async () => {
    setShip(false);
    await onSubmitUpdate();
  }, [onSubmitUpdate]);

  const file = useWatch({ control, name: 'file.fileName' });

  const { nbv, margin } = item;

  const { isAdmin, id, isSalesSupport, isSalesManager, isSuperSalesManager } = useUserRole();
  const shipmentDate = useWatch({ control, name: 'shipmentDate' });
  const isShipmentDateDisabled =
    (!isAdmin && !isSuperSalesManager && !isSalesManager && !isSalesSupport && id !== authorId) ||
    !!nbv;
  const isExpectedShipmentDateDisabled = shipmentDate !== '';

  return (
    <>
      <Accordion expanded={isOpen} onChange={handleOnExpand} className={classes.root}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<ExpandMoreIcon />}
          className={status}
          classes={{
            root: classes.accordionRoot,
            content: classes.accordionContent,
          }}
        >
          {onCheckChange && (
            <MuiCheckbox
              onClick={stopBubble}
              checked={props.isChecked ?? false}
              onChange={handleCheckChange}
              disabled={isDisabledCheckbox}
              checkedIcon={<IconCheckbox checked={true} disabled={isDisabledCheckbox} />}
              icon={<IconCheckbox checked={false} disabled={isDisabledCheckbox} />}
              color={'primary'}
              size="small"
            />
          )}
          <div className={classes.wrapper}>
            <Typography variant="body2" color="textSecondary" className={classes.leaseSubject}>
              {t('LeaseSubject')}:
            </Typography>
            <div>
              <Typography
                variant="body2"
                color="textPrimary"
                className={clsx(classes.leaseTitle, isDisabledCheckbox ? classes.locked : '')}
              >
                {item.leaseSubject} {item.brand} {item.itemModel}
              </Typography>
              {nbv && (
                <div className={classes.nbvContainer}>
                  <span className={classes.nbvTitle}>{t('NBV')}:</span>
                  <span className={classes.nbvValue}>{formatMoney({ amount: nbv })}</span>
                  {margin && (
                    <>
                      <span className={classes.nbvTitle}> | </span>
                      <span className={classes.nbvValue}>{formatNumber(margin)}%</span>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className={classes.subtitleContainer}>
              <div className={classes.subtitle}>
                <span>{shipmentDateValue ? t('Shipped') : t('Expected')}:&nbsp;</span>
                <span
                  className={
                    shipmentDateValue
                      ? classes.shipped
                      : expectedShipmentDateValue
                      ? classes.expectedShipment
                      : classes.noneDate
                  }
                >
                  {shipmentDateValue || expectedShipmentDateValue
                    ? dayjs(shipmentDateValue || expectedShipmentDateValue).format('DD.MM.YYYY')
                    : '—'}
                </span>
              </div>
              <IssueParam
                title={t('Telematics')}
                issueId={item.telematicsIssueId}
                user={item.telematicsIssueAssignee}
                status={item.telematicsIssueStatus}
              />
              <IssueParam
                title={t('Act')}
                issueId={item.shipmentIssueId}
                user={item.shipmentIssueAssignee}
                status={item.shipmentIssueStatus}
              />
              <IssueParam
                title={t('Report')}
                issueId={item.shipmentReportIssueId}
                user={item.shipmentReportIssueAssignee}
                status={item.shipmentReportIssueStatus}
              />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {isOpen && (
            <form className={classes.wrapper}>
              <Grid container columnSpacing={2} rowSpacing={2.5}>
                <Grid xl={4} md={6} xs={24} item>
                  <DatePicker
                    label={`${t('Expected shipping date')}`}
                    name="expectedShipmentDate"
                    control={control}
                    disabled={isExpectedShipmentDateDisabled}
                  />
                </Grid>
                <Grid xl={4} md={6} xs={24} item>
                  <DatePicker
                    label={`${t('Shipping date')}`}
                    name={`shipmentDate`}
                    control={control}
                    disabled={isShipmentDateDisabled}
                    disableFuture={true}
                  />
                </Grid>
                <Grid xs={24} item>
                  <Input
                    label={`${t('Nomenclature in 1C')}`}
                    name="nomenclature"
                    disabled={true}
                    control={control}
                  />
                </Grid>
                <Grid md={24} xs={24} item>
                  <Input
                    label={`${t('Lease subject in document')}`}
                    name="leaseSubjectInDocument"
                    multiline={true}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('Required'),
                      },
                    }}
                  />
                </Grid>
                <Grid xl={5} lg={12} md={12} xs={24} item>
                  <Input
                    label={`${t('Place of delivery of an asset')}`}
                    name="shipmentPlace"
                    control={control}
                  />
                </Grid>
                <Grid xl={3} lg={4} md={6} xs={24} item>
                  <Input label={`${t('VIN')}`} name="vin" control={control} />
                </Grid>
                <Grid xl={3} lg={4} md={6} xs={24} item>
                  <Input label={`${t('Engine number')}`} name="engineNumber" control={control} />
                </Grid>
                <Grid xl={3} lg={4} md={5} xs={24} item>
                  <Input label={`${t('Chassis number')}`} name="chassisNumber" control={control} />
                </Grid>
                <Grid xl={2} lg={4} md={5} xs={24} item>
                  <Input label={`${t('Body number')}`} name="bodyNumber" control={control} />
                </Grid>
                <Grid xl={2} lg={4} md={4} xs={24} item>
                  <Input label={`${t('Color')}`} name="color" control={control} />
                </Grid>
                <Grid xl={3} lg={4} md={5} xs={24} item>
                  <Input label={`${t('Engine power')}`} name="enginePower" control={control} />
                </Grid>
                <Grid xl={3} lg={4} md={5} xs={24} item>
                  <Input label={`${t('Engine volume')}`} name="engineVolume" control={control} />
                </Grid>

                <Grid md={24} xs={24} item>
                  <Typography variant="subtitle1">{t('TCP data')}</Typography>
                </Grid>
                <Grid xl={3} lg={4} md={6} xs={24} item>
                  <Input
                    label={`${t('Number')}`}
                    name="technicalDevicePassport.number"
                    control={control}
                  />
                </Grid>
                <Grid xl={9} lg={12} md={14} xs={24} item>
                  <Input
                    label={`${t('Issued by')}`}
                    name="technicalDevicePassport.issuedBy"
                    control={control}
                  />
                </Grid>
                <Grid xl={3} lg={4} md={4} xs={24} item>
                  <DatePicker
                    label={`${t('Issued date')}`}
                    name="technicalDevicePassport.issuedDate"
                    control={control}
                  />
                </Grid>
                <Grid xl={11} lg={11} md={11} xs={24} item>
                  <FileUpload
                    name={'file.fileName'}
                    control={control}
                    setValue={setValue}
                    accept={['image/jpeg', 'image/png', 'application/pdf']}
                  />
                </Grid>
                <Grid xl={2} lg={2} md={2} xs={24} item className={classes.or}>
                  {t('or')}
                </Grid>
                <Grid xl={11} lg={11} md={11} xs={24} item>
                  <Input
                    label={`${t('Vehicle passport link')}`}
                    name="fileUrl"
                    control={control}
                    rules={{
                      required: {
                        value: isShip && file === '',
                        message: t('Required'),
                      },
                    }}
                  />
                </Grid>
                <Grid xl={4} lg={4} md={6} xs={24} item>
                  <Input
                    label={`${t('Asset value for the act')}`}
                    name="technicalDevicePassport.price"
                    control={control}
                  />
                </Grid>
                <Grid xl={5} lg={8} md={8} xs={24} mdStart={1} item>
                  <CounterpartySelect
                    label={t('Lessee signer')}
                    name="lesseeContact.id"
                    control={control}
                    disabled={isHeadsLoading}
                    heads={lesseeHeads}
                    setValue={setValue}
                  />
                </Grid>
                <Grid xl={4} lg={8} md={8} xs={24} item>
                  <Input label={`${t('Reason')}`} name="lesseeContact.reason" control={control} />
                </Grid>
                <Grid xl={3} md={4} xs={24} item>
                  <Input label={`${t('Number')}`} name="lesseeContact.number" control={control} />
                </Grid>
                <Grid xl={3} md={4} xs={24} item>
                  <DatePicker label={`${t('Date')}`} name="lesseeContact.date" control={control} />
                </Grid>
                <Grid xl={5} lg={8} md={8} xs={24} mdStart={1} item>
                  <CounterpartySelect
                    label={t('Lessor signer')}
                    name="lessorContact.id"
                    control={control}
                    disabled={isHeadsLoading}
                    heads={lessorHeads}
                    setValue={setValue}
                  />
                </Grid>
                <Grid xl={4} lg={8} md={8} xs={24} item>
                  <Input label={`${t('Reason')}`} name="lessorContact.reason" control={control} />
                </Grid>
                <Grid xl={3} md={4} xs={24} item>
                  <Input label={`${t('Number')}`} name="lessorContact.number" control={control} />
                </Grid>
                <Grid xl={3} md={4} xs={24} item>
                  <DatePicker label={`${t('Date')}`} name="lessorContact.date" control={control} />
                </Grid>
                <Grid xl={5} lg={8} md={8} xs={24} mdStart={1} item>
                  <CounterpartySelect
                    label={t('Dealer signer')}
                    name="dealerContact.id"
                    control={control}
                    disabled={isHeadsLoading}
                    heads={dealerHeads}
                    setValue={setValue}
                  />
                </Grid>
                <Grid xl={4} lg={8} md={8} xs={24} item>
                  <Input label={`${t('Reason')}`} name="dealerContact.reason" control={control} />
                </Grid>
                <Grid xl={3} md={4} xs={24} item>
                  <Input label={`${t('Number')}`} name="dealerContact.number" control={control} />
                </Grid>
                <Grid xl={3} md={4} xs={24} item>
                  <DatePicker label={`${t('Date')}`} name="dealerContact.date" control={control} />
                </Grid>
                <Grid xs={24} item>
                  <div className={classes.actions}>
                    <div className={classes.actionButton}>
                      <Button variant="contained" onClick={openHandler}>
                        {t('Print')}
                      </Button>
                    </div>
                    <div className={classes.actionButton}>
                      <Button
                        variant="outlined"
                        disabled={isSubmitting || isLoading || item.isLocked || !!item.nbv}
                        onClick={saveHandler}
                      >
                        {t('Save')}
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </AccordionDetails>
        {isDocumentsOpen ? (
          <PrintableTemplateList
            quotaId={quotaId}
            kind={TemplateKind.Shipment}
            shipmentItemId={item.id}
          />
        ) : null}
      </Accordion>
    </>
  );
};
