import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { User } from 'components/users';
import { IssueStatus, UserShortViewModel } from 'schema';
import { Link } from 'react-router-dom';
import { useStatusSelectString } from '../../tasks/useStatusString';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.text.secondary,
      display: 'inline-block',
    },
    closed: {
      color: theme.palette.success.main,
    },
    link: {
      color: theme.palette.secondary.dark,
    },
    user: {
      color: theme.palette.text.primary,
    },
  })
);

type IssueParamProps = {
  title: string;
  user?: UserShortViewModel;
  status?: IssueStatus;
  issueId?: number;
};

export const IssueParam = (props: IssueParamProps) => {
  const classes = useStyles();
  //const { t } = useTranslation();

  const { issueId, title, user, status } = props;

  const statusTitle = useStatusSelectString(status ?? IssueStatus.Opened);

  return (
    <div className={classes.subtitle}>
      <span>{title}:&nbsp;</span>
      <span>
        {issueId ? (
          <>
            {user && (
              <>
                <span className={classes.user}>
                  <User name={user.name} isActive={user.isActive} />
                </span>
                &nbsp;|&nbsp;
              </>
            )}
            <Link to={`/tasks/${issueId}`} className={classes.link}>
              № {issueId}
            </Link>
            {status ? (
              <span className={status === IssueStatus.Closed ? classes.closed : ''}>
                &nbsp;|&nbsp;{statusTitle}
              </span>
            ) : null}
          </>
        ) : (
          '—'
        )}
      </span>
    </div>
  );
};
