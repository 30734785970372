import { useState } from 'react';
import { endOfMonth, startOfMonth } from '../../../Home/Shipment/dates';
import { PipelineFilter } from '../../../Home/PipelineToggle/Pipeline2';
import { useNewPipelineReportQuery } from 'services';
import { formatMoney, formatNumber } from 'components/utils';
import { Currency } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useUserQuery } from '../../../users/useUserQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontWeight: 700,
      color: theme.palette.text.primary,
      fontSize: 12,
      paddingTop: 7,
    },
    container: {
      display: 'flex',
      gap: theme.spacing(0, 2),
      alignItems: 'center',
    },
  })
);

export const MarginAdditionalInfo = (props: { authorId: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<string | undefined>(startOfMonth);
  const [endDate, setEndDate] = useState<string | undefined>(endOfMonth);
  const { data: items } = useNewPipelineReportQuery({ start: startDate, end: endDate });
  const { user, isLoading: isUserLoading } = useUserQuery(props.authorId);

  return (
    <div className={classes.container}>
      <PipelineFilter setStartDate={setStartDate} setEndDate={setEndDate} />
      {isUserLoading
        ? t('Loading')
        : items
            ?.filter(
              (item) =>
                user?.groups?.some((group) => group.name === item.name) || item.name === 'Всего'
            )
            .map((item) => {
              return (
                <span className={classes.root} key={item.name}>
                  {item.name === 'Всего' ? item.name : `По группе ${item.name}`}:{' '}
                  {formatNumber(item.margin, 1)}% |{' '}
                  {formatMoney({
                    amount: item.totalAwaitingAmount,
                    currency: Currency.Ruble,
                  })}
                </span>
              );
            })}
    </div>
  );
};
