import { useNomenclatureListQuery } from '../../services/api/useNomenclaturesBackend';
import { useDebounce } from 'use-debounce';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1.5),
    },
    label: {
      fontSize: 10,
      color: theme.palette.text.secondary,
    },
    value: {
      fontSize: 12,
      color: theme.palette.secondary.dark,
    },
    empty: {
      fontSize: 12,
      color: theme.palette.error.main,
    },
  })
);

type SearchingNomenclatureProps = {
  code: string;
  setNotFound?: Dispatch<SetStateAction<boolean>>;
};

export const SearchingNomenclature = ({ code, setNotFound }: SearchingNomenclatureProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [debouncedCode] = useDebounce(code, 300);
  const { data } = useNomenclatureListQuery({ code: [debouncedCode], pageSize: 1 });

  const isNotFound = !data?.data[0] || data?.data[0].code !== code;

  useEffect(() => {
    if (setNotFound) setNotFound(isNotFound);
  }, [isNotFound, setNotFound]);

  const nomenclature = isNotFound ? (
    <div className={classes.empty}>Не найдена</div>
  ) : (
    <div className={classes.value}>{data?.data[0].name}</div>
  );
  return (
    <div className={classes.root}>
      <div className={classes.label}>{t('IssueType.Nomenclature')}</div>
      {nomenclature}
    </div>
  );
};
