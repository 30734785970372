import { createStyles, makeStyles, MenuItem, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, Select } from 'components';
import { ModalForm } from 'components/Modal';
import { useCalculationFormContext } from '../types';
import React, { useState } from 'react';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
    form: {
      textAlign: 'left',
      marginTop: theme.spacing(2),
    },
    errorText: {
      color: theme.palette.error.main,
      marginTop: theme.spacing(1),
    },
  })
);

export type KofModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  modalText: string;
};

export const KofModal = (props: KofModalProps) => {
  const classes = useStyles();
  const { open, onClose, onConfirm, modalText } = props;
  const { t } = useTranslation();
  const { control, getValues, setValue } = useCalculationFormContext();
  const [showError, setShowError] = useState(false);

  const closeHandler = () => {
    setValue('cofChangeReasonExternalId', undefined);
    onClose();
  };

  const confirmHandler = () => {
    const selectedValue = getValues('cofChangeReasonExternalId');
    if (!selectedValue) {
      setShowError(true);
    } else {
      setShowError(false);
      onConfirm();
    }
  };

  return (
    <ModalForm open={open} onClose={onClose} width={500}>
      <div className={classes.modalRoot}>
        <Typography variant={'h2'} className={classes.modalTitle}>
          Разблокировка КОФ
        </Typography>
        <Typography variant="subtitle2" className={classes.modalText}>
          {modalText}
        </Typography>
        <div className={classes.form}>
          <Select
            name={'cofChangeReasonExternalId'}
            label={t('The reason for change COF')}
            control={control}
          >
            <MenuItem value={9987}>Отсрочка оплаты поставщику</MenuItem>
            <MenuItem value={9990}>Спец. программа 26 (Ручное изменение)</MenuItem>
          </Select>
          {showError && (
            <Typography variant="body2" className={classes.errorText}>
              {t('Required')}
            </Typography>
          )}
        </div>
        <div>
          <Button variant="contained" className={classes.modalButton} onClick={confirmHandler}>
            {t('Yes')}
          </Button>
          <Button variant="outlined" onClick={closeHandler} className={classes.modalButton}>
            {t('No')}
          </Button>
        </div>
      </div>
    </ModalForm>
  );
};
