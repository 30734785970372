import { Paging } from '../../paging';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { usePageTitle } from 'components/utils/usePageTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useParkingsData } from './useParkingsData';
import { FilterForm } from './FilterForm';
import { AddButton } from 'components/dictionaries/AddButton';
import { ConfiscatedParkingsTable } from './ConfiscatedParkingsTable';
import { ModalForm, useModalForm } from 'components/Modal';
import { CreateParkingForm } from './CreateParkingForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      '& > *': {
        marginLeft: theme.spacing(1.5),
      },
    },
  }),
);

export const ConfiscatedParkingsDataGrid = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { filter, paging, ...dataProps } = useParkingsData();

  usePageTitle(t('ConfiscatedLeaseItems') + ' - Bumblebee');

  const { onOpen: onAddFormOpen, onClose: onAddFormClose, open: isAddFormOpen } = useModalForm();

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item>
            <FilterForm {...filter} />
          </Grid>
          <Grid item className={classes.actions}>
            <AddButton onClick={onAddFormOpen} />
          </Grid>
        </Grid>
        <Grid container item direction="column">
          <Grid item>
            <ConfiscatedParkingsTable {...dataProps} />
          </Grid>
          <Grid item>
            <Paging {...paging} />
          </Grid>
        </Grid>
      </Grid>
      <ModalForm open={isAddFormOpen} onClose={onAddFormClose}>
        <CreateParkingForm onSuccess={onAddFormClose} />
      </ModalForm>
    </>
  );
};
