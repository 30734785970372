import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Button } from 'components/Button';
import { IconSprite } from '../../../icons';
import { themeOrange as theme } from 'theme';
import clsx from 'clsx';
import { useMemo } from 'react';
import { IssueViewModel, MarginIssueActions, UserViewModel } from 'schema';
import { useTranslation } from 'react-i18next';
import { useCancelMarginIssueMutation } from '../../../../services';
import { useQueryClient } from 'react-query';
import { useToast } from '../../../Toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 1, 1, 2),
      borderRadius: 28,
      '&$success': {
        border: `1px solid ${theme.palette.success.main}`,
      },
      '&$error': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
    success: {},
    error: {},
    icon: {
      height: 16,
      marginRight: theme.spacing(1.25),
    },
    text: {
      marginRight: theme.spacing(2),
      fontSize: 12,
      flex: '1 1 auto',
    },
    reject: {
      marginLeft: theme.spacing(1),
    },
  })
);

type MessageButtonProps = {
  current: number;
  calculated: number;
  minGreen: number;
  min: number;
  isLocked: boolean;
  isContractIssue: boolean;
  isLoading: boolean;
  isDirty: boolean;
  isLessee: boolean;
  isMarginApprove?: boolean;
  issue?: IssueViewModel;
  isStartup?: boolean;
  approveActions?: MarginIssueActions;
  isMarginAgreement: boolean;
  users: UserViewModel[];
  quotaId: number;
};

export const MessageButton = (props: MessageButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const toast = useToast();

  const {
    current,
    min,
    isLocked,
    isContractIssue,
    isLoading,
    isDirty,
    isLessee,
    isMarginApprove = false,
    issue,
    minGreen,
    isStartup,
    approveActions,
    isMarginAgreement,
    users,
    quotaId,
  } = props;

  const isIssue = !!issue && issue.isActive;
  const namesString = users.map((user) => user.name).join(', ');

  const { type, text, action } = useMemo(() => {
    if (isMarginApprove) {
      return {
        type: 'error',
        text: isDirty
          ? 'Утвердить новую границу минимальной маржи?'
          : `Утвердить маржу ${issue?.marginApproval?.margin}% в расчете?`,
        action: '',
      };
    } else if (!isLessee) {
      return {
        type: 'success',
        text: 'Отсутствует лизингополучатель',
        action: '',
      };
    } else if (isContractIssue) {
      return {
        type: 'success',
        text: 'Маржа соответствует требованию!',
        action: '',
      };
    } else if (isIssue) {
      return {
        type: 'error',
        text: `Запрос на утверждение отправлен ${namesString}!`,
        action: '',
      };
    } else if (!approveActions?.canRequestApproval && current < minGreen) {
      return {
        type: 'error',
        text: 'Согласование маржи невозможно, владелец расчета не состоит ни в одной из групп',
        action: '',
      };
    } else if (current < minGreen && isStartup) {
      return {
        type: 'error',
        text: 'Маржа в расчете меньше расчетной.',
        action: 'Необходимо ручное утверждение.',
      };
    } else if (current < minGreen) {
      return {
        type: 'error',
        text: 'Маржа в расчете меньше расчетной.',
        action: 'Необходимо ручное утверждение.',
      };
    } else if (current >= minGreen) {
      return {
        type: 'success',
        text: 'Маржа соответствует требованию!',
        action: '',
      };
    }
    return {
      type: 'success',
      text: '',
      action: '',
    };
  }, [
    current,
    isIssue,
    isContractIssue,
    isLessee,
    isMarginApprove,
    isDirty,
    issue?.marginApproval?.margin,
    minGreen,
    isStartup,
    approveActions?.canRequestApproval,
    namesString,
  ]);

  const queryClient = useQueryClient();
  const { mutateAsync } = useCancelMarginIssueMutation(quotaId, {
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('quotas') > -1 || query.queryKey.indexOf('margin') > -1;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const rejectHandler = async () => {
    try {
      await mutateAsync(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={clsx(classes.root, type === 'error' ? classes.error : classes.success)}>
      <div className={classes.icon}>
        <IconSprite
          icon="menu-catalogs"
          width={16}
          color={type === 'error' ? theme.palette.error.main : theme.palette.success.main}
        />
      </div>
      <div className={classes.text}>
        {text}
        <br />
        <strong>{action}</strong>
      </div>
      <Button
        variant="contained"
        type="submit"
        disabled={
          isLoading ||
          (isMarginApprove &&
            ((approveActions?.canApproveYellow && current < min && !isMarginAgreement) ||
              (isStartup && current < min && !isMarginAgreement))) ||
          (!isMarginApprove &&
            (isLocked ||
              isIssue ||
              (!isDirty && current >= minGreen) ||
              (!approveActions?.canRequestApproval && current < minGreen)))
        }
      >
        {isMarginApprove ? 'Утвердить' : current >= minGreen ? 'Сохранить' : 'Утвердить маржу'}
      </Button>
      {isMarginApprove && (
        <Button variant="outlined" onClick={rejectHandler} className={classes.reject}>
          {t('Reject')}
        </Button>
      )}
    </div>
  );
};
