import { Switch } from 'components';
import { Control, UseFormSetValue } from 'react-hook-form';
import { CalculatorFormValues } from '../../types';
import { useTranslation } from 'react-i18next';

export type AgentFeeSwitchProps = {
  control: Control<CalculatorFormValues, any>;
  setValue: UseFormSetValue<CalculatorFormValues>;
  disabled: boolean;
};

export const AgentFeeSwitch = (props: AgentFeeSwitchProps) => {
  const { control, disabled } = props;
  const { t } = useTranslation();

  return (
    <Switch
      name="isAgentFeeWithVat"
      label={t('VAT payer')}
      type="checkbox"
      control={control}
      disabled={disabled}
    />
  );
};
