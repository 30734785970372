import { QueryKey } from 'react-query';
import {
  CalculationInputSettings,
  IssueNotificationSettingSaveRequest,
  IssueNotificationSettingViewModel,
  CalcMarginSettingViewModel,
  EventNotificationSettingViewModel,
  EventNotificationSettingSaveRequest,
  RatingSettingsViewModel,
} from 'schema/serverTypes';
import { useBackendQuery, useBackendMutation, QueryOptions, MutationOptions } from './useBackend';
import { calculationUrl } from 'services/urls';

const createUrlFromParts = (relativeUrl?: string, searchParams?: URLSearchParams) => {
  const urlParts = relativeUrl ? relativeUrl.trim().split('/') : [];
  const parts = [calculationUrl, 'api', 'v1', 'settings', ...urlParts].filter((t) => t !== '');
  let url = parts.join('/');
  url = searchParams ? `${url}?${searchParams.toString()}` : url;
  return url;
};

const createOptions = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  options: QueryOptions<TResponse, TQueryKey> | undefined = {}
) => {
  const defaultOptions: QueryOptions<TResponse, TQueryKey> = {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: false,
    keepPreviousData: false,
  };
  return {
    ...defaultOptions,
    ...options,
  } as QueryOptions<TResponse, TQueryKey>;
};

type SettingsQueryOptions<TResponse, TQueryKey extends QueryKey = QueryKey> = {
  searchParams?: URLSearchParams;
  options?: QueryOptions<TResponse, TQueryKey>;
};

export const useCalculationSettingsQuery = (
  options: SettingsQueryOptions<CalculationInputSettings, string> | undefined = undefined
) => {
  const url = createUrlFromParts('calculation', options?.searchParams);
  return useBackendQuery(url, url, createOptions(options?.options));
};

export const useUpdateCalculationSettingsMutation = (
  options?: MutationOptions<CalculationInputSettings, CalculationInputSettings>
) =>
  useBackendMutation<CalculationInputSettings, CalculationInputSettings>(
    createUrlFromParts('calculation'),
    { ...options, method: 'PUT' }
  );

export const useCalculationMarginSettingsQuery = (
  options: SettingsQueryOptions<CalcMarginSettingViewModel, string> | undefined = undefined
) => {
  const url = createUrlFromParts('margin', options?.searchParams);
  return useBackendQuery(url, url, createOptions(options?.options));
};

export const useUpdateCalculationMarginSettingsMutation = (
  options?: MutationOptions<CalcMarginSettingViewModel, CalcMarginSettingViewModel>
) =>
  useBackendMutation<CalcMarginSettingViewModel, CalcMarginSettingViewModel>(
    createUrlFromParts('margin'),
    { ...options, method: 'POST' }
  );

export const useIssueNotificationSettingsQuery = (
  options: SettingsQueryOptions<IssueNotificationSettingViewModel[], string> | undefined = undefined
) => {
  const url = createUrlFromParts('notifications', options?.searchParams);
  return useBackendQuery(url, url, createOptions(options?.options));
};

export const useUpdateIssueNotificationSettingsMutation = (
  options?: MutationOptions<
    IssueNotificationSettingSaveRequest,
    IssueNotificationSettingSaveRequest
  >
) => useBackendMutation(createUrlFromParts('notifications'), { ...options, method: 'PUT' });

export const useEventNotificationSettingsQuery = (
  options: SettingsQueryOptions<EventNotificationSettingViewModel[], string> | undefined = undefined
) => {
  const url = createUrlFromParts('events', options?.searchParams);
  return useBackendQuery(url, url, createOptions(options?.options));
};

export const useUpdateEventNotificationSettingsMutation = (
  options?: MutationOptions<
    EventNotificationSettingSaveRequest,
    EventNotificationSettingSaveRequest
  >
) => useBackendMutation(createUrlFromParts('events'), { ...options, method: 'PUT' });

export const useRatingSettingsQuery = (
  options: SettingsQueryOptions<RatingSettingsViewModel, string> | undefined = undefined
) => {
  const url = createUrlFromParts('rating', options?.searchParams);
  return useBackendQuery(url, url, createOptions(options?.options));
};

export const useUpdateRatingSettingsMutation = (
  options?: MutationOptions<RatingSettingsViewModel, RatingSettingsViewModel>
) =>
  useBackendMutation<RatingSettingsViewModel, RatingSettingsViewModel>(
    createUrlFromParts('rating'),
    { ...options, method: 'PUT' }
  );
