import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Button, DatePicker, Grid, Input, Role } from 'components';
import { useToast } from '../../Toast';
import { ApplicationRoles } from 'services/authentication/ApplicationRoles';
import { useShipItemsMutation } from '../../../services/api/useShipmentItemsBackend';
import { CounterpartySelect } from './CounterpartySelect';
import { useShipmentActForm } from './useShipmentActForm';
import { ShipmentItemViewModel } from '../../../schema';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(1.5),
    },
    modalText: {
      color: theme.palette.text.primary,
      textAlign: 'center',
      marginBottom: theme.spacing(4),
      fontWeight: 500,
      fontSize: 14,
    },
    item: {
      marginBottom: theme.spacing(1),
      fontSize: 10,
      color: theme.palette.text.secondary,
      '& a$value': {
        fontSize: 12,
        color: theme.palette.secondary.dark,
      },
    },
    contractContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    contract: {
      color: theme.palette.secondary.dark,
      fontWeight: 500,
      fontSize: 12,
    },
    contractStatus: {
      color: theme.palette.text.primary,
      fontWeight: 400,
      fontSize: 12,
    },
    sep: {
      color: theme.palette.secondary.main,
      display: 'inline-block',
      margin: theme.spacing(0, 0.75),
    },
    icon: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1),
      marginBottom: 2,
    },

    label: {
      color: theme.palette.text.secondary,
      fontWeight: 700,
      fontSize: 12,
    },
    value: {
      color: theme.palette.common.black,
      fontWeight: 400,
      fontSize: 12,
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type ShipmentFormProps = {
  quotaId: number;
  checkedItemIds: number[];
  onClose: () => void;
  dealer?: string;
  lessee?: string;
  lessor?: string;
  items: ShipmentItemViewModel[];
};

export const MultiShipmentActForm = (props: ShipmentFormProps) => {
  const classes = useStyles();
  const { quotaId, onClose, checkedItemIds, dealer, lessee, lessor, items } = props;
  const toast = useToast();

  const { handleSubmit, control, dealerHeads, lesseeHeads, lessorHeads, isHeadsLoading, setValue } =
    useShipmentActForm(quotaId, items, dealer, lessee, lessor);

  const { push } = useHistory();

  const { mutateAsync } = useShipItemsMutation(quotaId, {
    method: 'PUT',
    onSuccess: (result) => {
      toast(t('SuccessMessage'), 'success');
      push(`/tasks/${result.id}`);
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: any) => {
      const obj = {
        quotaId: quotaId,
        ids: checkedItemIds,
        item: { ...form },
      };
      await mutateAsync(obj);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, quotaId, checkedItemIds]);

  const { t } = useTranslation();

  const message = t('Required');

  const formId = `shipment-multi-form-${quotaId}`;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.modalTitle}>
        {t('Act request')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={5} xs={24}>
          <DatePicker
            label={`${t('Expected shipping date')}`}
            name="expectedShipmentDate"
            control={control}
            variant={'dialog'}
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>
        <Grid item md={19} xs={24}>
          <Input
            label={'Место поставки'}
            control={control}
            name="shipmentPlace"
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>
        <Grid item md={24} xs={24}>
          <Input
            label={'Ссылка на документы для отгрузки'}
            control={control}
            name="fileUrl"
            rules={{
              required: {
                value: true,
                message,
              },
            }}
          />
        </Grid>
        <Grid xl={5} lg={8} md={8} xs={24} mdStart={1} item>
          <CounterpartySelect
            label={t('Lessee signer')}
            name="lesseeContact.id"
            control={control as any}
            disabled={isHeadsLoading}
            heads={lesseeHeads}
            setValue={setValue as any}
          />
        </Grid>
        <Grid xl={4} lg={8} md={8} xs={24} item>
          <Input label={`${t('Reason')}`} name="lesseeContact.reason" control={control} />
        </Grid>
        <Grid xl={3} md={4} xs={24} item>
          <Input label={`${t('Number')}`} name="lesseeContact.number" control={control} />
        </Grid>
        <Grid xl={3} md={4} xs={24} item>
          <DatePicker label={`${t('Date')}`} name="lesseeContact.date" control={control} />
        </Grid>
        <Grid xl={5} lg={8} md={8} xs={24} mdStart={1} item>
          <CounterpartySelect
            label={t('Lessor signer')}
            name="lessorContact.id"
            control={control as any}
            disabled={isHeadsLoading}
            heads={lessorHeads}
            setValue={setValue as any}
          />
        </Grid>
        <Grid xl={4} lg={8} md={8} xs={24} item>
          <Input label={`${t('Reason')}`} name="lessorContact.reason" control={control} />
        </Grid>
        <Grid xl={3} md={4} xs={24} item>
          <Input label={`${t('Number')}`} name="lessorContact.number" control={control} />
        </Grid>
        <Grid xl={3} md={4} xs={24} item>
          <DatePicker label={`${t('Date')}`} name="lessorContact.date" control={control} />
        </Grid>
        <Grid xl={5} lg={8} md={8} xs={24} mdStart={1} item>
          <CounterpartySelect
            label={t('Dealer signer')}
            name="dealerContact.id"
            control={control as any}
            disabled={isHeadsLoading}
            heads={dealerHeads}
            setValue={setValue as any}
          />
        </Grid>
        <Grid xl={4} lg={8} md={8} xs={24} item>
          <Input label={`${t('Reason')}`} name="dealerContact.reason" control={control} />
        </Grid>
        <Grid xl={3} md={4} xs={24} item>
          <Input label={`${t('Number')}`} name="dealerContact.number" control={control} />
        </Grid>
        <Grid xl={3} md={4} xs={24} item>
          <DatePicker label={`${t('Date')}`} name="dealerContact.date" control={control} />
        </Grid>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <Role role={ApplicationRoles.allExceptAssetManager}>
              <div className={classes.actionButton}>
                <Button
                  className={classes.button}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  {t('Request an act')}
                </Button>
              </div>
              <div className={classes.actionButton}>
                <Button
                  className={classes.button}
                  type="button"
                  color="primary"
                  size="medium"
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('Cancel2')}
                </Button>
              </div>
            </Role>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
