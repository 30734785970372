import React from 'react';
import {
  Card,
  CardContent,
  makeStyles,
  createStyles,
  CardActions,
  Theme,
  CardHeader,
  MenuItem,
  Box,
} from '@material-ui/core';
import { Grid } from 'components/Grid';
import { Select, Button, Input, Switch } from 'components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { GroupEditFormValues } from './types';
import { GroupUsersViewModel } from '../../schema';
import { useUserSearchQuery } from './useUserSearchQuery';
import { useEditForm } from './GroupEditFormPanel/useEditForm';
import { useCreateForm } from './GroupCreateFormPanel/useCreateForm';
import { UsersAutocomplete } from './UsersAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '400px',
      minWidth: '400px',
      border: 'none',
      boxShadow: 'none',
    },
    cardContent: {
      padding: 20,
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      fontWeight: 'bolder',
      textAlign: 'left',
      padding: theme.spacing(5.5, 2, 2, 2),
    },
    actions: {
      justifyContent: 'flex-start',
    },
  })
);

export type GroupFormProps = {
  group?: GroupUsersViewModel;
};

export const GroupForm = ({ group }: GroupFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { users } = useUserSearchQuery('', []);

  const isEditMode = !!group;
  const createForm = useCreateForm();
  const editForm = useEditForm(group || ({} as GroupUsersViewModel));
  const { onSubmit, initialValues, isLoading, options } = isEditMode ? editForm : createForm;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<GroupEditFormValues>({
    mode: 'onBlur',
    defaultValues: initialValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className={classes.root}>
        <Box className={classes.headerWrapper}>
          <CardHeader
            className={classes.header}
            title={isEditMode ? group.name : t('Buttons.NewGroup')}
          />
        </Box>
        <CardContent className={classes.cardContent}>
          <Grid container rowSpacing={2.5} columnSpacing={0}>
            <Grid item xs={24}>
              <Input
                label={`${t('Name')}`}
                name="name"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={24}>
              <Select
                label={t('Lessor')}
                name="lessorInn"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('Required'),
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option.inn} value={option.inn}>
                    {option.name}&nbsp;{option.inn}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={24}>
              <Select
                label={t('Owner')}
                name="owner"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('Required'),
                  },
                }}
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={24}>
              <UsersAutocomplete control={control} setValue={setValue} />
            </Grid>
            <Grid item xs={24}>
              <Switch
                label={'Активна'}
                name="isActive"
                control={control}
                labelOn={''}
                labelOff={''}
                disabled={!isEditMode}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            disabled={isSubmitting || isLoading}
            size="medium"
            type="submit"
            variant="contained"
          >
            {t('Save')}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
