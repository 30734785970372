import { useCallback, useState } from 'react';
import { UseMutationOptions, useMutation, UseQueryOptions, useQuery, QueryKey } from 'react-query';
import { ValidationProblemDetails, ValidationProblemErrors } from 'schema/serverTypes';
import { calculationUrl, useUserAuth } from 'services';
import { BackendQueryOptions, createOptions, createUrlFromParts } from './types';

const acceptOnlyHeaders = {
  Accept: 'application/json',
};

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const useBackendFetch = (setDefaultContentType?: boolean) => {
  const { user } = useUserAuth();
  const accessToken = user?.access_token;

  const setContentType = setDefaultContentType ?? true;

  const fetchBackend = useCallback(
    async (url: string, init?: RequestInit) => {
      const { headers = {}, ...rest } = init ?? {};
      const requestHeaders = accessToken
        ? {
            Authorization: `Bearer ${accessToken}`,
            ...(setContentType ? defaultHeaders : acceptOnlyHeaders),
            ...headers,
          }
        : {
            ...(setContentType ? defaultHeaders : acceptOnlyHeaders),
            ...headers,
          };
      const request = { headers: requestHeaders, ...rest };
      const response = await fetch(url, request);
      return response;
    },
    [accessToken, setContentType],
  );

  return fetchBackend;
};

export type QueryOptions<
  TResponse,
  TQueryKey extends QueryKey = QueryKey,
  TError = unknown,
> = UseQueryOptions<TResponse, TError, TResponse, TQueryKey>;

export const useBackendQuery = <TResponse, TQueryKey extends QueryKey = QueryKey, TError = unknown>(
  url: string,
  queryKey: TQueryKey,
  options?: QueryOptions<TResponse, TQueryKey, TError>,
) => {
  const fetchBackend = useBackendFetch();

  const getData = useCallback(async (): Promise<TResponse> => {
    const response = await fetchBackend(url);
    const result: TResponse = await response.json();
    return result;
  }, [url, fetchBackend]);

  const query = useQuery(queryKey, getData, {
    //enabled: authenticated,
    keepPreviousData: true,
    refetchInterval: false,
    refetchOnMount: true,
    ...options,
  });

  return query;
};

export const useEntityBackendQuery = <TResponse, TQueryKey extends QueryKey = QueryKey>(
  entity: string,
  options: BackendQueryOptions<TResponse, TQueryKey>,
) => {
  let queryKey = entity;

  if (options.relativeUrl && options.relativeUrl !== '') {
    queryKey = `${queryKey}-${options.relativeUrl}`;
  }

  if (options.searchParams) {
    queryKey = `${queryKey}-${options.searchParams}`;
  }

  return useBackendQuery(
    createUrlFromParts(entity, options.relativeUrl, options.searchParams),
    queryKey,
    createOptions(options.options),
  );
};

type MutationHttpMethod = 'POST' | 'PUT' | 'DELETE';

export type MutationOptions<TRequest, TResponse, TContext = unknown> = UseMutationOptions<
  TResponse,
  ValidationProblemDetails,
  TRequest,
  TContext
> & {
  method?: MutationHttpMethod | ((form: TRequest) => MutationHttpMethod);
  json?: (form: TRequest, response: Response) => Promise<TResponse>;
};

export type MutationOptionsWithoutHttpMethod<TRequest, TResponse, TContext = unknown> = Omit<
  MutationOptions<TRequest, TResponse, TContext>,
  'method'
>;

export const useBackendMutation = <TRequest, TResponse, TContext = unknown>(
  url: string | ((form: TRequest) => string),
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'POST' },
) => {
  const fetchBackend = useBackendFetch();

  const { method = 'POST', json, ...rest } = options;

  const mutationFn = useCallback(
    async (form: TRequest): Promise<TResponse> => {
      const requestMethod =
        method === undefined ? 'POST' : typeof method === 'string' ? method : method(form);
      let requestUrl = typeof url === 'string' ? url : url(form);
      const response = await fetchBackend(requestUrl, {
        method: requestMethod,
        body: JSON.stringify(form),
      });

      if (response.status === 400 || response.status === 404 || response.status === 500) {
        const errors: ValidationProblemErrors | undefined = await response.json();
        const error: ValidationProblemDetails = {
          errors: errors ?? { '': ['Ошибка'] },
          status: response.status,
        };
        throw error;
      }

      if (json) {
        const jsonResult: TResponse = await json(form, response);
        return jsonResult;
      }

      const result: TResponse = await response.json();
      return result;
    },
    [fetchBackend, method, url, json],
  );

  return useMutation(mutationFn, rest);
};

export const useEntityBackendMutation = <TRequest, TResponse, TContext = unknown>(
  entity: string,
  relativeUrl?: string,
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'POST' },
) => useBackendMutation(createUrlFromParts(entity, relativeUrl), options);

export const useBackendFormDataMutation = <TRequest, TResponse, TContext = unknown>(
  requestUrl: string,
  getFormData: (request: TRequest) => FormData,
  options: MutationOptions<TRequest, TResponse, TContext> | undefined = { method: 'POST' },
) => {
  const fetchBackend = useBackendFetch(false);

  const { method = 'POST', ...rest } = options;

  const mutationFn = useCallback(
    async (form: TRequest): Promise<TResponse> => {
      const requestMethod =
        method === undefined ? 'POST' : typeof method === 'string' ? method : method(form);
      const response = await fetchBackend(requestUrl, {
        method: requestMethod,
        body: getFormData(form),
      });

      if (response.status === 400) {
        const validationErrors: ValidationProblemDetails | undefined = await response.json();
        throw validationErrors ?? { errors: { '': ['Ошибка'] } };
      }
      if (response.status === 500) {
        const error: ValidationProblemDetails = { errors: { '': ['Ошибка'] } };
        throw error;
      }

      const result: TResponse = await response.json();
      return result;
    },
    [fetchBackend, method, requestUrl, getFormData],
  );

  return useMutation(mutationFn, rest);
};

export const appendValue = (formData: FormData, name: string, value?: string) => {
  if (value === undefined || value === '') {
    return;
  }
  formData.append(name, value);
};

export const appendNumber = (formData: FormData, name: string, value?: number) => {
  if (value === undefined || value === 0) {
    return;
  }
  formData.append(name, value.toString());
};

export type UseDownloadFileProps = {
  downloadUrl: string;
  fileName: string;
  onClose?: () => void;
};

export const useDownloadFile = (props: UseDownloadFileProps) => {
  const { downloadUrl, fileName, onClose } = props;

  const fetchBackend = useBackendFetch();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const downloadAsync = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchBackend(`${calculationUrl}${downloadUrl}`, {
        method: 'GET',
      });

      if (response.status !== 200) {
        setIsError(true);
        if (onClose) {
          onClose();
        }
        return;
      }

      let realFileName = fileName;
      const header = response.headers.get('content-disposition');
      if (header !== null) {
        realFileName =
          header
            .split(';')
            .find((n) => n.includes("filename*=UTF-8''"))
            ?.replace("filename*=UTF-8''", '')
            .trim() ?? fileName;

        realFileName = decodeURIComponent(decodeURI(realFileName));
      }
      const result = await response.blob();
      const blob = new Blob([result], {
        type: result.type,
      });
      const newUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = newUrl;
      a.download = realFileName;
      document.body.appendChild(a);
      a.click();
    } catch (e) {
      console.log(e);
      setIsError(true);
    } finally {
      if (onClose) {
        onClose();
      }
      setIsLoading(false);
    }
  }, [fileName, downloadUrl, fetchBackend, onClose]);

  return { isLoading, isError, downloadAsync };
};
