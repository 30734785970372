import {
  FutureShipmentListFilter,
  FutureShipmentListRequest,
  FutureShipmentSortBy,
  SortOrder,
} from 'schema/serverTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { usePaging } from 'components/paging';
import { useFutureShipmentListRequest } from 'services/api/useFeatureShipmentBackend';
import { FutureShipmentFilterFormProps, FutureShipmentFilterFormValues } from './types';

export const useFutureShipmentListData = (defaultValues?: FutureShipmentListFilter) => {
  const filterParams = sessionStorage.futureShipmentsFilterValues
    ? JSON.parse(sessionStorage.futureShipmentsFilterValues)
    : null;

  const { control, reset, setValue } = useForm<FutureShipmentFilterFormValues>({
    mode: 'all',
    defaultValues: { ...defaultValues, ...filterParams },
  });

  const [sortBy, setSortBy] = useState<FutureShipmentSortBy>(
    FutureShipmentSortBy.expectedShipmentDate
  );
  const [order, setOrder] = useState(SortOrder.asc);

  const onReset = useCallback(() => {
    reset({});
  }, [reset]);

  const { page, pageSize, onPageChanged, onPageSizeChanged } = usePaging();
  const lesseeInn = useWatch({ control, name: 'lesseeInn' });
  const dealerInn = useWatch({ control, name: 'dealerInn' });
  const contractNumber = useWatch({ control, name: 'contractNumber' });
  const quotaNumber = useWatch({ control, name: 'quotaNumber' });
  const quotaOwnerId = useWatch({ control, name: 'quotaOwnerId' });
  const isInWork = useWatch({ control, name: 'isInWork' });
  const isShipped = useWatch({ control, name: 'isShipped' });
  const isPartiallyShipped = useWatch({ control, name: 'isPartiallyShipped' });

  const tabId = useWatch({ control, name: 'tabId' });

  const params = useMemo(() => {
    return {
      isShipped,
      isPartiallyShipped,
      lesseeInn,
      dealerInn,
      quotaOwnerId,
      contractNumber,
      quotaNumber,
      isInWork,
      tabId,
      pageSize,
      page,
    };
  }, [
    isShipped,
    isPartiallyShipped,
    lesseeInn,
    dealerInn,
    contractNumber,
    tabId,
    pageSize,
    page,
    quotaNumber,
    quotaOwnerId,
    isInWork,
  ]);

  useEffect(() => {
    onPageChanged(1);
  }, [
    isShipped,
    isPartiallyShipped,
    lesseeInn,
    dealerInn,
    contractNumber,
    tabId,
    onPageChanged,
    quotaNumber,
    quotaOwnerId,
    isInWork,
    pageSize,
  ]);

  useEffect(() => {
    sessionStorage.setItem('futureShipmentsFilterValues', JSON.stringify(params));
  }, [params]);

  const lesseeInns = useMemo(() => {
    return lesseeInn?.map((t) => t.lesseeInn);
  }, [lesseeInn]);

  const dealerInns = useMemo(() => {
    return dealerInn?.map((t) => t.dealerInn);
  }, [dealerInn]);

  const quotaOwnerIds = useMemo(() => {
    return quotaOwnerId?.map((t) => t.id);
  }, [quotaOwnerId]);

  const request: FutureShipmentListRequest = useMemo(() => {
    return {
      tabId,
      isShipped,
      isPartiallyShipped,
      contractNumber,
      quotaNumber,
      lesseeInn: lesseeInns,
      dealerInn: dealerInns,
      quotaOwnerId: quotaOwnerIds,
      isInWork,
      page,
      pageSize,
      sortBy,
      order,
    };
  }, [
    tabId,
    isShipped,
    isPartiallyShipped,
    contractNumber,
    lesseeInns,
    dealerInns,
    page,
    pageSize,
    sortBy,
    order,
    quotaNumber,
    quotaOwnerIds,
    isInWork,
  ]);

  const { data: list, isLoading } = useFutureShipmentListRequest(request);

  const handleSortBy = useCallback(
    (sortBy: FutureShipmentSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  return useMemo(() => {
    const rows = list?.data ?? [];
    const pageCount = list?.pageCount ?? 0;
    const totalCount = list?.totalCount ?? 0;

    return {
      isLoading,
      rows,
      sorting: {
        sortBy,
        order,
        setSortBy: handleSortBy,
        setOrder: handleSortOrder,
      },
      paging: {
        pageCount,
        totalCount,
        page,
        pageSize,
        dataCount: rows.length,
        onPageChanged,
        onPageSizeChanged,
      },
      filter: {
        control,
        onReset,
        setValue,
      } as FutureShipmentFilterFormProps,
    };
  }, [
    isLoading,
    list,
    page,
    pageSize,
    control,
    sortBy,
    order,
    onReset,
    setValue,
    handleSortBy,
    handleSortOrder,
    onPageChanged,
    onPageSizeChanged,
  ]);
};
