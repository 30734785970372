import { Route, useLocation } from 'react-router-dom';
import { RequestsDataGrid, Critical } from 'components';
import { useErrorContext } from 'components/tasks';
import { useTranslation } from 'react-i18next';
import { ExternalQuotaCalculation, IssueSource } from 'schema/serverTypes';
import { usePageTitle } from 'components/utils/usePageTitle';
import { CreateQuotaForm, CurrencyExchangerProvider } from '../../components/calculator2';
import { useCurrencyRatesQuery, useCofsQuery } from 'services/api/useQuotasBackend';

const RequestsGrid = () => {
  return <RequestsDataGrid />;
};

const CreateQuotaPage = () => {
  const { data: currencies, isLoading } = useCurrencyRatesQuery();
  const { data: cofs, isLoading: isLoadingCofs } = useCofsQuery();
  //const { requestId } = useParams<{ requestId: string }>();
  //const { data: requestValues, isLoading: isLoadingExternal } = useExternalQuotaQuery(requestId);
  const { t } = useTranslation();
  const title = t('Calculation');
  const { state } = useLocation<{ requestValues: ExternalQuotaCalculation } | undefined>();

  useErrorContext({
    title,
    source: IssueSource.quota,
  });

  if (!currencies || isLoading || !cofs || isLoadingCofs) {
    return null;
  }

  return (
    <CurrencyExchangerProvider currencies={currencies}>
      <CreateQuotaForm requestValues={state?.requestValues} />
    </CurrencyExchangerProvider>
  );
};

export const RequestsPage = () => {
  const { t } = useTranslation();

  const pageTitle = t('Requests') + ' - Bumblebee';
  usePageTitle(pageTitle);

  useErrorContext({
    title: t('Requests'),
    source: IssueSource.requests,
  });

  return (
    <>
      <Critical />
      <Route path="/requests">
        <Route exact path="/requests/quota" component={CreateQuotaPage} />
        <Route exact path={`/requests/quota/:requestId(\\d+)`} component={CreateQuotaPage} />
        <Route path="/requests" component={RequestsGrid} />
      </Route>
    </>
  );
};
