import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  ContractViewModel,
  CounterpartyViewModel,
  ShipmentItemViewModel,
} from 'schema/serverTypes';
import { useCounterpartiesBackendQuery, useQuotasBackendQuery } from 'services/api';

export const useShipmentActForm = (
  quotaId: number,
  items: ShipmentItemViewModel[],
  dealer?: string,
  lessee?: string,
  lessor?: string
) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitted, isDirty },
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: 'onBlur',
  });

  const { data: contractData } = useQuotasBackendQuery<ContractViewModel>(
    `${quotaId}/contract`,
    ['quotas', quotaId, 'contract'],
    {
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    const shipmentPlace = watch('shipmentPlace');
    if (!shipmentPlace) {
      setValue('shipmentPlace', contractData?.shippingAddress);
    }
  }, [contractData?.shippingAddress, setValue, watch]);

  const useCounterpartyHeads = (
    counterparty: string,
    contactKey: 'dealerContact' | 'lesseeContact' | 'lessorContact'
  ) => {
    const requestUrl = `${counterparty}`;
    const { data, isLoading } = useCounterpartiesBackendQuery<CounterpartyViewModel>(requestUrl, {
      enabled: counterparty !== undefined && counterparty !== '',
      refetchInterval: false,
      refetchOnWindowFocus: false,
    });

    const heads = useMemo(() => {
      let options = data?.heads
        ? data?.heads.map(({ lastName = '', ...head }) => ({
            lastName,
            ...head,
          }))
        : [];

      if (options.length === 0) {
        items.forEach((item) => {
          const contact = item[contactKey];
          const isDuplicate = options.some(
            (option) =>
              option.firstName === contact?.firstName &&
              option.lastName === contact?.lastName &&
              option.middleName === contact?.middleName
          );

          if (contact !== undefined && !isDuplicate) {
            options.push({ position: '', ...contact });
          }
        });
      }
      return options;
    }, [data?.heads, contactKey]);

    return { heads, isLoading };
  };

  const { heads: dealerHeads, isLoading: isDealerHeadsLoading } = useCounterpartyHeads(
    dealer ?? '',
    'dealerContact'
  );
  const { heads: lesseeHeads, isLoading: isLesseeHeadsLoading } = useCounterpartyHeads(
    lessee ?? '',
    'lesseeContact'
  );
  const { heads: lessorHeads, isLoading: isLessorHeadsLoading } = useCounterpartyHeads(
    lessor ?? '',
    'lessorContact'
  );

  return {
    handleSubmit,
    control,
    isSubmitting,
    setValue,
    getValues,
    isDirty: isDirty && !isSubmitted,
    dealerHeads,
    lesseeHeads,
    lessorHeads,
    isHeadsLoading: isDealerHeadsLoading || isLesseeHeadsLoading || isLessorHeadsLoading,
  };
};
