import { useCalculationFormContext } from '../types';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete, PagedList } from 'components';
import { FieldError, useController, useWatch } from 'react-hook-form';
import { useCalculationSettingsQuery, useDictionaryBackendQuery } from 'services';
import { LeasingProductItemViewModel, RateType } from 'schema';
import { LeasingProductBrandsContext } from '../LeasingProductBrandsContext';

export const useLeasingProductQuery = () => {
  const { data } = useDictionaryBackendQuery<PagedList<LeasingProductItemViewModel>>(
    'leasingProducts?page=1&pageSize=9999',
  );
  const options = data?.data ?? [];
  return {
    options,
  };
};

const getOptionLabel = (option: LeasingProductItemViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (
  option: LeasingProductItemViewModel | null,
  value: LeasingProductItemViewModel | null,
) => {
  if (option === null || value === null) {
    return false;
  }
  return option.name === value.name;
};

export const LeaseProductAutocomplete = () => {
  const { t } = useTranslation();

  const { setBrands, clearBrands } = useContext(LeasingProductBrandsContext);

  const { control, setValue } = useCalculationFormContext();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: 'leaseProduct',
  });

  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  const [input, setInput] = useState('');
  const { options = [] } = useLeasingProductQuery();

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInput(value);
    },
    [setInput],
  );

  const { data: settings } = useCalculationSettingsQuery();
  const numberOfMonths = useWatch({ control, name: 'numberOfMonths' });
  const rateType = useWatch({ control, name: 'rateType' });

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: LeasingProductItemViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<LeasingProductItemViewModel | null>,
    ) => {
      if (value === null) {
        clearBrands();
        onChange('');
        setValue('cofPercents', settings?.cof);
      } else {
        setBrands(value.brands);
        if (rateType === RateType.Floating) {
          setValue('cofPercents', settings?.cofFloatRate);
        } else if (value.specialCof && numberOfMonths && numberOfMonths >= 24) {
          setValue('cofPercents', value.specialCof);
        } else {
          setValue('cofPercents', settings?.cof);
        }
        onChange(value.name);
      }
    },
    [
      onChange,
      clearBrands,
      setBrands,
      setValue,
      settings?.cof,
      settings?.cofFloatRate,
      rateType,
      numberOfMonths,
    ],
  );

  const selectedOption = options.find((t) => t.name === value) ?? null;

  return (
    <Autocomplete<LeasingProductItemViewModel | null>
      label={`${t('Vendor programm')}`}
      options={options}
      inputValue={input}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant="outlined"
      value={selectedOption}
      error={error !== undefined}
      helperText={helperText}
    />
  );
};
