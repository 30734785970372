import { Typography } from '@material-ui/core';
import { Grid, Button, useToast } from 'components/index';

import { useTranslation } from 'react-i18next';
import { QuotaNomenclatureViewModel } from 'schema/serverTypes';
import { useAddNomenclatureIssueMutation } from 'services';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { themeOrange as theme } from '../../../theme';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    nomenclature: {
      color: theme.palette.common.black,
      marginBottom: theme.spacing(1),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
  })
);

export type NomenclaturesRequestFormProps = {
  quotaId: number;
  newNomenclatures: QuotaNomenclatureViewModel[];
  onClose: (...event: any[]) => void;
};

export const NomenclaturesRequestForm = (props: NomenclaturesRequestFormProps) => {
  const classes = useStyles();
  const { newNomenclatures, quotaId, onClose } = props;
  const { t } = useTranslation();
  const toast = useToast();

  const queryClient = useQueryClient();

  const { mutateAsync } = useAddNomenclatureIssueMutation(quotaId, {
    onSuccess: (result) => {
      toast(
        <>
          Спасибо ваше обращение отправлено
          {result.map((issue) => (
            <Link to={`/tasks/${issue.id}`} style={{ color: theme.palette.primary.main }}>
              &nbsp;№{issue.id}
            </Link>
          ))}
        </>,
        'success'
      );
      toast(t('Your request has been created'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('quotas') > -1;
        },
      });
      onClose();
    },
    onError: () => {
      toast(t('Could not create nomenclature'), 'error');
    },
  });

  const saveHandler = async () => {
    await mutateAsync({ nomenclatures: newNomenclatures.map((item) => ({ id: item.id ?? -1 })) });
  };

  return (
    <>
      <Typography variant={'h2'} className={classes.title}>
        {t('Add nomenclatures')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          {newNomenclatures.map((item) => (
            <div className={classes.nomenclature} key={item.id}>
              {item.name}
            </div>
          ))}
        </Grid>
        <Grid item md={24} xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
            onClick={saveHandler}
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
