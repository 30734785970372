import { Grid } from '@material-ui/core';
import { Paging } from '../paging';
import { useLocation, useParams } from 'react-router-dom';
import { useContractsListData } from './useContractsListData';
import { ContractsTable } from './ContractsTable';
import { ContractsFilterForm } from './ContractsFilterForm';
import { ContractDataGridProps } from './types';
import { usePageTitle } from 'components/utils/usePageTitle';
import { useTranslation } from 'react-i18next';
import { ApplicationUserTab } from '../../schema';

export const ContractsDataGrid = (props: ContractDataGridProps) => {
  const { t } = useTranslation();
  const pageTitle = t('Contracts') + ' - Bumblebee';
  usePageTitle(pageTitle);
  const { lesseeInn } = useParams<{ lesseeInn: string }>();
  const { dealerInn } = useParams<{ dealerInn: string }>();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const contractNumber = searchParams.get('contractNumber') ?? undefined;

  const {
    tabId = contractNumber !== undefined ? ApplicationUserTab.all : ApplicationUserTab.mine,
  } = props;

  const { rows, isLoading, paging, filter, sorting } = useContractsListData({
    contractNumber,
    lesseeInn: lesseeInn ? [{ lesseeInn, name: lesseeInn }] : [],
    dealerInn: dealerInn ? [{ dealerInn, name: dealerInn }] : [],
    ...props,
    tabId,
  });

  const { control, onReset, setValue } = filter;

  return (
    <>
      <ContractsFilterForm control={control} onReset={onReset} setValue={setValue} />
      <Grid container item direction="column">
        <Grid item>
          <ContractsTable rows={rows} isLoading={isLoading} sorting={sorting} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
    </>
  );
};
