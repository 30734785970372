import { Grid } from 'components/Grid';
import { Range } from './Range';
import { NumberFormatCustom } from '../../CalculationForm/AmountInput';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { MessageButton } from './MessageButton';
import React, { useEffect, useMemo } from 'react';
import { useUpdateQuotaMarginMutation } from 'services';
import { useToast } from '../../../Toast';
import { useForm } from 'react-hook-form';
import { Input } from 'components/form';
import { QuotaMarginViewModel } from 'schema';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
    },
    accordionDetails: {
      padding: theme.spacing(2.5),
      flexDirection: 'column',
    },
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.paper,
      padding: theme.spacing(1.5, 0),
    },
    number: {
      '& input': {
        textAlign: 'right',
      },
    },
    locked: {
      filter: 'grayscale(1) brightness(1.2)',
    },
    attentionTitle: {
      fontWeight: 700,
      marginBottom: theme.spacing(1),
    },
    rule: {
      fontSize: 12,
      lineHeight: '16px',
    },
    divider: {
      margin: theme.spacing(2, 0, 2.5),
      backgroundColor: theme.palette.background.default,
    },
  })
);

type MarginSaveProps = {
  data: QuotaMarginViewModel;
  quotaId: number;
  isMarginAgreement: boolean;
};

export const MarginSave = (props: MarginSaveProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();
  const { data, quotaId, isMarginAgreement } = props;

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useUpdateQuotaMarginMutation(quotaId, {
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          const substrings = ['quotas', 'scoring', 'margin'];

          if (typeof query.queryKey === 'string') {
            return substrings.some((substring) => query.queryKey.includes(substring));
          }

          if (Array.isArray(query.queryKey)) {
            return query.queryKey.some(
              (key) =>
                typeof key === 'string' && substrings.some((substring) => key.includes(substring))
            );
          }

          return false;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const defaultValues = useMemo(() => {
    return {
      margin: data.current,
    };
  }, [data]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitSuccessful, isDirty },
    reset,
  } = useForm<{ margin: number }>({
    mode: 'onBlur',
    defaultValues,
  });

  useEffect(() => {
    reset({ margin: data.current });
  }, [reset, data]);

  //resets isDirty after successful submit
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmit = useMemo(() => {
    const submit = async (form: { margin: number }) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const currentMargin = watch('margin');

  const isLessee = !!data.lesseeInn;
  const isIssue = !!data.marginIssue && data.marginIssue.isActive;

  return (
    <Accordion expanded={true}>
      <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
        <div className={classes.title}>
          <Typography variant="subtitle1">Маржа</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {data.isStartup && (
          <>
            <div>
              <Typography variant={'body2'} color={'error'} className={classes.attentionTitle}>
                Внимание у клиента повышенные риски
              </Typography>
              {data.rulesWithCodeAResult && (
                <ul>
                  {data.rulesWithCodeAResult.map((rule) => (
                    <li key={rule.name} className={classes.rule}>
                      — {rule.name} <strong>{rule.description.actualDisplayMessage}</strong>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <Divider light className={classes.divider} />
          </>
        )}
        <form onSubmit={onSubmit} className={data.isLocked ? classes.locked : ''}>
          <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
            <Grid item xs={11}>
              <Range {...{ ...data, current: currentMargin }} />
            </Grid>
            <Grid item xs={3}>
              <Input
                label={`Маржа в расчете, %`}
                name="margin"
                control={control}
                disabled={isIssue || data.isLocked}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                rules={{
                  pattern: /^[0-9,\-\s]+$/i,
                  min: {
                    value: 0,
                    message: 'Минимум 0%',
                  },
                  max: {
                    value: data.max,
                    message: `Максимум ${data.max}%`,
                  },
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <MessageButton
                current={currentMargin}
                calculated={data.calculated ? data.calculated : data.min}
                minGreen={data.minGreen ? data.minGreen : data.min}
                min={data.min}
                isLocked={data.isLocked}
                issue={data.marginIssue}
                isContractIssue={!!data.contractIssue}
                isLoading={isLoading}
                isDirty={isDirty}
                isLessee={isLessee}
                isStartup={data.isStartup}
                approveActions={data.marginIssueActions}
                isMarginAgreement={isMarginAgreement}
                users={data.users}
                quotaId={data.quotaId}
              />
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};
