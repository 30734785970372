import { Typography } from '@material-ui/core';
import { Grid, Button, useToast, Input, Checkbox } from 'components';
import { useTranslation } from 'react-i18next';
import { useAddManualScoringIssueDecisionMutation, useQuotaDealQualificationQuery } from 'services';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useQueryClient } from 'react-query';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    nomenclature: {
      color: theme.palette.common.black,
      marginBottom: theme.spacing(1),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    dealQualificationsControl: {
      marginBottom: theme.spacing(1),
    },
  })
);

export type ManualDecisionFormProps = {
  quotaId: number;
  onClose: (...event: any[]) => void;
};

export const ManualDecisionForm = (props: ManualDecisionFormProps) => {
  const classes = useStyles();
  const { quotaId, onClose } = props;
  const { t } = useTranslation();
  const toast = useToast();

  const { data } = useQuotaDealQualificationQuery(quotaId);

  const dealQualificationsControls = useMemo(() => {
    return data?.filter((item) => item.type === 'manual') ?? [];
  }, [data]);

  const { control, handleSubmit, setValue } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    dealQualificationsControls.forEach((item, index) => {
      setValue(`dealQualifications.${index}`, { id: item.id, isValid: true });
    });
  }, [dealQualificationsControls, setValue]);

  const queryClient = useQueryClient();

  const { mutateAsync } = useAddManualScoringIssueDecisionMutation(quotaId, {
    onSuccess: (result) => {
      toast(t('SuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (typeof query.queryKey === 'string') {
            const key = query.queryKey as string;
            return ['quotas', 'scoring'].some((substring) => key.includes(substring));
          }

          return false;
        },
      });
      onClose();
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: any) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return (
    <form onSubmit={onSubmit}>
      <Typography variant={'h2'} className={classes.title}>
        {t('AddDecision')}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xs={24}>
          <Input
            label={t('Path to the CAR solution file')}
            control={control}
            name="fileUrl"
            rules={{
              required: {
                value: true,
                message: t('Required'),
              },
            }}
          />
        </Grid>
        <Grid item xs={24}>
          {dealQualificationsControls.map((item, index) => {
            return (
              <div
                key={`dealQualificationsControl${item.id}`}
                className={classes.dealQualificationsControl}
              >
                <Checkbox
                  name={`dealQualifications.${index}.isValid` as const}
                  label={item.name}
                  control={control}
                />
              </div>
            );
          })}
        </Grid>
        <Grid item xs={24}>
          <Button
            className={classes.button}
            color="primary"
            size="medium"
            type="submit"
            variant="contained"
          >
            {t('Save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
