import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Input, useToast } from 'components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RatingSettingsViewModel } from 'schema/serverTypes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  useUpdateRatingSettingsMutation,
  useCounterpartiesRecalculateRatingMutation,
} from 'services';
import { useMemo } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
    },
    number: {
      '& input': {
        textAlign: 'right',
      },
    },
    button: {
      display: 'inline-block',
    },
    actions: {
      '& > *': {
        marginRight: theme.spacing(1.5),
      },
    },
  })
);

type RatingSettingsFormProps = {
  defaultValues: RatingSettingsViewModel;
};

export const RatingSettingsForm = (props: RatingSettingsFormProps) => {
  const classes = useStyles();
  const toast = useToast();

  const { control, handleSubmit } = useForm<RatingSettingsViewModel>({
    mode: 'onBlur',
    defaultValues: props.defaultValues,
  });

  const { mutateAsync, isLoading } = useUpdateRatingSettingsMutation({
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: RatingSettingsViewModel) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const { t } = useTranslation();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = event.charCode;
    // Allow only digits and :
    if (
      (charCode < 48 || charCode > 57) && // not a digit
      charCode !== 58 // not :
    ) {
      event.preventDefault();
    }
  };

  const { mutateAsync: recalculateRatingsMutation, isLoading: isRecalculateRatingsLoading } =
    useCounterpartiesRecalculateRatingMutation({
      method: 'PUT',
      onSuccess: () => {
        toast(t('SuccessMessage'), 'success');
      },
    });

  const recalculateRating = async () => {
    try {
      await recalculateRatingsMutation({});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle1">{t('Rating settings')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={2} rowSpacing={2.5}>
            <Grid item md={4} xs={24}>
              <Input
                name="recalcFrequencyDay"
                control={control}
                className={classes.number}
                rules={{
                  required: true,
                  pattern: /^[0-9\s]+$/i,
                }}
                label={t('RecalcFrequencyDay')}
                onKeyPress={handleKeyPress}
              />
            </Grid>
            <Grid item md={4} xs={24}>
              <Input
                label={t('RecalcTime')}
                name="recalcTime"
                control={control}
                className={classes.number}
                placeholder={'HH:MM'}
                rules={{
                  required: true,
                  pattern: {
                    value: /^([01]\d|2[0-3]):([0-5]\d)$/,
                    message: t('TimeFormat'),
                  },
                }}
                inputProps={{ maxLength: 5 }}
                onKeyPress={handleKeyPress}
              />
            </Grid>
            <Grid item md={24} xs={24} className={classes.actions}>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
                disabled={isLoading}
              >
                {t('Save')}
              </Button>
              <Button
                className={classes.button}
                color="primary"
                size="medium"
                variant="contained"
                disabled={isRecalculateRatingsLoading}
                onClick={recalculateRating}
              >
                {t('Recalculate rating')}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </form>
  );
};
