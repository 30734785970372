import { IssueViewModel, ShipmentItemIdViewModel } from 'schema/serverTypes';
import { MutationOptions, useBackendMutation } from '.';
import { calculationUrl } from 'services/urls';

const createUrl = (issueId: number) => {
  return `${calculationUrl}/api/v1/issues/${issueId}/shipmentItems`;
};

export const useShipmentItemsIssuesBackendMutation = <TRequest, TResponse, TContext = unknown>(
  issueId: number,
  options: MutationOptions<TRequest, TResponse, TContext> | undefined
) => useBackendMutation(createUrl(issueId), options);

export const useCloseShipmentItemsIssueMutation = (
  issueId: number,
  options: MutationOptions<any, any>
) =>
  useShipmentItemsIssuesBackendMutation<unknown, IssueViewModel>(issueId, {
    method: 'POST',
    ...options,
  });

export const useAddShipmentItemToIssueMutation = (
  issueId: number,
  options: MutationOptions<any, any>
) =>
  useShipmentItemsIssuesBackendMutation<ShipmentItemIdViewModel, IssueViewModel>(issueId, {
    method: 'PUT',
    ...options,
  });

export const useRemoveShipmentItemFromIssueMutation = (
  issueId: number,
  options: MutationOptions<any, any>
) =>
  useShipmentItemsIssuesBackendMutation<ShipmentItemIdViewModel, IssueViewModel>(issueId, {
    method: 'DELETE',
    ...options,
  });
