import { useCallback, useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { Chip } from 'components/index';
import { CounterpartyOption, CounterpartyType } from 'schema/serverTypes';
import { QuotaChipsProps, QuotaFilterFormContext } from './types';
import { getOptionLabel } from './CounterpartyAutocomplete';

export type CounterpartyChipsProps = QuotaChipsProps & {
  type?: CounterpartyType;
};

export const CounterpartyChips = (props: CounterpartyChipsProps) => {
  const { control, setValue, type } = props;
  const name =
    type === CounterpartyType.dealer
      ? 'dealerInn'
      : type === CounterpartyType.lessee
      ? 'lesseeInn'
      : 'inn';

  const counterparties = useWatch({ control, name });

  const onRemove = useCallback(
    (id: string) => {
      const values = counterparties?.filter((v) => v !== id);
      setValue(name, values);
    },
    [counterparties, setValue, name]
  );

  const counterpartiesFromSession: CounterpartyOption[] = sessionStorage.quotaFilterCounterparties
    ? JSON.parse(sessionStorage.quotaFilterCounterparties)
    : null;
  const { counterparties: counterpartiesFromContext } = useContext(QuotaFilterFormContext);
  const counterpartiesSource = counterpartiesFromSession || counterpartiesFromContext;

  const counterpartyChips = counterparties?.map((value) => {
    let counterparty = counterpartiesSource.find((t) => t.inn === value);

    if (counterparty === undefined) {
      return <Chip label={value} id={value} key={`counterparties-${value}`} onRemove={onRemove} />;
    }

    const label = getOptionLabel(counterparty);
    return <Chip label={label} id={value} key={`counterparties-${value}`} onRemove={onRemove} />;
  });

  return <>{counterpartyChips}</>;
};
