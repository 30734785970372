import { Skeleton } from '@material-ui/lab';
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  createStyles,
  CardActions,
  Theme,
  Grid,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { QuotaHistoryWithAvailableOwners } from 'schema';
import { useChangeOwnerForm } from './useChangeOwnerForm';
import { Button, Select } from 'components/index';
import { Typography } from '@material-ui/core';
import { IconArrowLineRight } from 'components/icons/IconArrowLineRight';
import { useGoBack } from 'hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '400px',
      minWidth: '400px',
      border: 'none',
      boxShadow: 'none',
      overflow: 'visible',
    },
    header: {
      fontWeight: 'bolder',
      textAlign: 'left',
      paddingTop: 0,
    },
    actions: {
      justifyContent: 'flex-start',
    },
    cancelButton: {
      color: theme.palette.error.main,
    },
    item: {
      marginBottom: theme.spacing(1),
    },
    subtitile: {
      fontSize: '12px',
      marginBottom: theme.spacing(1),
    },
    historyText: {
      marginBottom: theme.spacing(1),
      fontWeight: 500,
    },
    oldOwner: {
      color: theme.palette.text.primary,
    },
    GridCloseButton: {
      backgroundColor: theme.palette.secondary.light,
    },
    closeButton: {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      '& img': {
        maxWidth: '10px',
      },
    },
  })
);

export const QuotaOwnerFormSkeleton = () => {
  return <Skeleton></Skeleton>;
};

export type QuotaOwnerFormType = {
  quota: QuotaHistoryWithAvailableOwners;
};

export const QuotaOwnerForm = (props: QuotaOwnerFormType) => {
  const classes = useStyles();
  const { quota } = props;
  const { control, onSubmit, disabled, isDirty, isError } = useChangeOwnerForm(quota);
  const { availableOwners, owner } = quota;
  const goBack = useGoBack();

  const handleOnClose = useCallback(() => {
    goBack();
  }, [goBack]);

  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit}>
      <Card className={classes.root}>
        <Grid container justifyContent="flex-end" className={classes.GridCloseButton}>
          <IconButton className={classes.closeButton} onClick={handleOnClose}>
            <img src="/img/icons/close-icon.svg" alt="" />
          </IconButton>
        </Grid>
        <Grid>
          <CardHeader className={classes.header} title={quota.id} />
        </Grid>
        <CardContent>
          <Grid container>
            <Grid item xs={12} className={classes.item}>
              <Typography color="textSecondary" className={classes.subtitile}>
                {t('Created')}
              </Typography>
              <Typography className={classes.historyText}>
                {new Date(quota.createdDate as string).toLocaleDateString('ru-RU')}
              </Typography>
            </Grid>
            {quota.inputHistory.length > 0 && (
              <Grid item xs={12} className={classes.item}>
                <Typography color="textSecondary" className={classes.subtitile}>
                  {t('Modified')}
                </Typography>
                <Grid container>
                  {quota.inputHistory.map((e) => {
                    return (
                      <Grid
                        key={`${e.user.id} + ${e.date.toString()}`}
                        item
                        xs={12}
                        className={classes.historyText}
                      >
                        {new Date(e.date as string).toLocaleDateString('ru-RU')} | {e.user.name}{' '}
                        {e.cofFrom ? ` изменил КОФ с ${e.cofFrom} на ${e.cofTo}` : ''}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {quota.ownerHistory.length > 0 && (
              <Grid item xs={12} className={classes.item}>
                <Typography color="textSecondary" className={classes.subtitile}>
                  {t('ChangeOwnership')}
                </Typography>
                <Grid container>
                  {quota.ownerHistory.map((e) => {
                    return (
                      <Grid
                        key={`${e.oldOwner.id} + ${e.date.toString()}`}
                        item
                        xs={12}
                        className={classes.historyText}
                      >
                        <span className={classes.oldOwner}>
                          {e.oldOwner.name} <IconArrowLineRight />{' '}
                        </span>{' '}
                        {e.newOwner.name}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            <Grid item lg={12} md={12} xl={12} xs={12} className={classes.item}>
              <Select
                control={control}
                name="ownerId"
                label={t('Owner')}
                required
                disabled={disabled}
              >
                <MenuItem key={owner.id} value={owner.id}>
                  {owner.name}
                </MenuItem>
                {availableOwners.map((user) => {
                  return (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          {isError && <Alert severity="error">{t('Error message')}</Alert>}
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            disabled={disabled || !isDirty}
            size="medium"
            type="submit"
            variant="contained"
          >
            {t('Save')}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
