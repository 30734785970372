import { Grid } from 'components/Grid';
import {
  ShipmentContainer,
  NewsContainer,
  Critical,
  MissingContainer,
  MissingCommentOverdueFormPanel,
  SupportContainer,
  Role,
  PipelineToggle,
  OverdueContainer,
  InsideSalesContainer,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useErrorContext } from 'components/tasks';
import { ApplicationRole, IssueSource } from 'schema/serverTypes';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ApplicationRoles, useUserRole } from 'services';
import { Route, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SortableList } from '../../components/Home/Dnd/SortableList';
import React from 'react';
import { usePageTitle } from 'components/utils/usePageTitle';
import { SortableItemContainer } from 'components/Home/Dnd/SortableItemContainer';
import { ClosedItem } from '../../components/Home/Dnd/ClosedItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(16),
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: 20,
      textAlign: 'center',
    },
    description: {
      color: theme.palette.common.black,
      fontSize: 16,
      textAlign: 'center',
      maxWidth: 437,
    },
  }),
);

export const HomePage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const pageTitle = t('Section.home') + ' - Bumblebee';
  usePageTitle(pageTitle);

  useErrorContext({
    title: t('Home'),
    source: IssueSource.home,
  });

  const { hasRole } = useUserRole();

  type Item = {
    id: number;
    open: boolean;
  };

  const [items, setItems] = useState<Item[]>(
    localStorage.dashboardOrderVersion !== '3'
      ? [
          { id: 70, open: true },
          { id: 10, open: true },
          { id: 20, open: true },
          { id: 30, open: true },
          { id: 40, open: true },
          { id: 50, open: true },
          { id: 60, open: true },
        ]
      : JSON.parse(localStorage.dashboardOrder),
  );

  useEffect(() => {
    localStorage.setItem('dashboardOrderVersion', '3');
    localStorage.setItem('dashboardOrder', JSON.stringify(items));
  }, [items]);

  const toggleOpen = (id: number) => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, open: !item.open } : item)),
    );
  };

  return (
    <>
      <Critical />
      <Switch>
        <Route path="/">
          <Route path="/comments/overdue" component={MissingCommentOverdueFormPanel} exact />
        </Route>
      </Switch>
      <Grid container spacing={2.5}>
        {hasRole ? (
          <>
            <SortableList items={items} onChange={setItems}>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <Grid item xs={24} id={70}>
                  <SortableItemContainer
                    toggleOpen={() => toggleOpen(70)}
                    open={items.find((item) => item.id === 70)?.open ?? true}
                  >
                    {items.find((item) => item.id === 70)?.open ? (
                      <InsideSalesContainer />
                    ) : (
                      <ClosedItem title={'Inside Sales Progress'} />
                    )}
                  </SortableItemContainer>
                </Grid>
              </Role>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <Grid item xs={24} id={10}>
                  <SortableItemContainer
                    toggleOpen={() => toggleOpen(10)}
                    open={items.find((item) => item.id === 10)?.open ?? true}
                  >
                    {items.find((item) => item.id === 10)?.open ? (
                      <MissingContainer />
                    ) : (
                      <ClosedItem title={t('Missing progress')} />
                    )}
                  </SortableItemContainer>
                </Grid>
              </Role>
              <Role
                role={[
                  ApplicationRole.Admin,
                  ApplicationRole.SalesSupport,
                  ApplicationRole.InsideSales,
                ]}
              >
                <Grid item xs={24} id={20}>
                  <SortableItemContainer
                    toggleOpen={() => toggleOpen(20)}
                    open={items.find((item) => item.id === 20)?.open ?? true}
                  >
                    {items.find((item) => item.id === 20)?.open ? (
                      <SupportContainer />
                    ) : (
                      <ClosedItem title={t('Support Progress')} />
                    )}
                  </SortableItemContainer>
                </Grid>
              </Role>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <Grid item xs={24} id={30}>
                  <SortableItemContainer
                    toggleOpen={() => toggleOpen(30)}
                    open={items.find((item) => item.id === 30)?.open ?? true}
                  >
                    {items.find((item) => item.id === 30)?.open ? (
                      <OverdueContainer />
                    ) : (
                      <ClosedItem title={t('Overdue')} />
                    )}
                  </SortableItemContainer>
                </Grid>
              </Role>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <Grid item xs={24} id={40}>
                  <SortableItemContainer
                    toggleOpen={() => toggleOpen(40)}
                    open={items.find((item) => item.id === 40)?.open ?? true}
                  >
                    {items.find((item) => item.id === 40)?.open ? (
                      <PipelineToggle />
                    ) : (
                      <ClosedItem title={t('Pipeline Reports')} />
                    )}
                  </SortableItemContainer>
                </Grid>
              </Role>
              <Role>
                <Grid item xs={24} id={50}>
                  <SortableItemContainer
                    toggleOpen={() => toggleOpen(50)}
                    open={items.find((item) => item.id === 50)?.open ?? true}
                  >
                    {items.find((item) => item.id === 50)?.open ? (
                      <NewsContainer />
                    ) : (
                      <ClosedItem title={t('Newsfeed')} />
                    )}
                  </SortableItemContainer>
                </Grid>
              </Role>
              <Role role={ApplicationRoles.allExceptAssetManager}>
                <Grid item xs={24} id={60}>
                  <SortableItemContainer
                    toggleOpen={() => toggleOpen(60)}
                    open={items.find((item) => item.id === 60)?.open ?? true}
                  >
                    {items.find((item) => item.id === 60)?.open ? (
                      <ShipmentContainer />
                    ) : (
                      <ClosedItem title={t('Shipment_plural')} />
                    )}
                  </SortableItemContainer>
                </Grid>
              </Role>
            </SortableList>
          </>
        ) : (
          <Grid item xs={24}>
            <div className={classes.container}>
              <h2 className={classes.title}>{t('Welcome to Bumblebee!')}</h2>
              <div className={classes.description}>{t('24hours')}</div>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};
