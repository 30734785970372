import { useTranslation } from 'react-i18next';
import { useCalculationFormContext } from '../types';
import { Select } from 'components';
import { MenuItem } from '@material-ui/core';
import { Telematics } from 'schema/serverTypes';
import { useTelematicsData } from '../../../dictionaries/Telematics/useTelematicsData';
import { useTelematicsName } from 'services/api/useTelematicsDictionaryBackend';

export const TelematicsSelect = ({ telematics }: { telematics?: Telematics }) => {
  const { t } = useTranslation();
  const { control } = useCalculationFormContext();

  const { rows } = useTelematicsData();

  const telematicsNoneName = useTelematicsName(Telematics.None);
  const telematicsName = useTelematicsName(telematics);

  let options = [
    {
      id: 0,
      telematics: Telematics.None,
      name: telematicsNoneName,
      installationCost: 0,
      subscriptionFee: 0,
      enabled: true,
    },
    ...rows.filter((row) => row.enabled),
  ];

  const telematicsFromDictionary = [...options];

  const optionExists = options.some((option) => option.telematics === telematics);

  // If no object with the specified telematics is found, add a new option
  if (!optionExists && telematics) {
    options.push({
      id: -1,
      telematics: telematics,
      name: telematicsName,
      installationCost: 0,
      subscriptionFee: 0,
      enabled: true,
    });
  }

  return (
    <Select
      name="telematics"
      label={t('Telematics')}
      control={control}
      rules={{
        validate: {
          isClient: (value: Telematics | undefined) =>
            telematicsFromDictionary.some((option) => option.telematics === value) ||
            t('TelematicsTypeNotAvailable'),
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.telematics} value={option.telematics}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};
