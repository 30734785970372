import { Box, Grid } from '@material-ui/core';
import { WikiTable } from './WikiTable';
import { useWikiListData } from './useWikiListData';
import { Paging } from '../paging';
import { WikiFilterForm } from './WikiFilterForm';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { Empty } from '../Empty';
import { ApplicationRole } from '../../schema';
import { Role } from '../authentication';
import { usePageTitle } from 'components/utils/usePageTitle';

export const WikiDataGrid = () => {
  const { t } = useTranslation();
  const { rows, paging, filter, isLoading } = useWikiListData();
  const { control, onReset, setValue } = filter;

  const pageTitle = t('Wiki') + ' - Bumblebee';
  usePageTitle(pageTitle);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box flex={1}>
          <WikiFilterForm control={control} onReset={onReset} setValue={setValue} />
        </Box>
        <Role
          role={[
            ApplicationRole.Admin,
            ApplicationRole.SuperSalesManager,
            ApplicationRole.RiskManager,
          ]}
        >
          <Box>
            <Button variant="contained" to="wiki/create">
              {t('Create an article ')}
            </Button>
          </Box>
        </Role>
      </Box>
      {isLoading ? (
        <Empty>{t('Please wait while the data is loading')}</Empty>
      ) : (
        <Grid container item direction="column">
          <Grid item>
            <WikiTable rows={rows} />
          </Grid>
          <Grid item>
            <Paging {...paging} />
          </Grid>
        </Grid>
      )}
    </>
  );
};
