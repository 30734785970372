import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FutureShipmentControlColor, FutureShipmentControlViewModel } from 'schema';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.25, 0.25, 0.25, 1),
      lineHeight: '16px',
      borderRadius: 4,
      border: '1px solid',
      width: '100%',
      backgroundColor: (props: FutureShipmentControlViewModel) => {
        return props.color === FutureShipmentControlColor.red
          ? theme.palette.error.light
          : props.color === FutureShipmentControlColor.yellow
          ? '#FFEDAF'
          : props.color === FutureShipmentControlColor.green
          ? '#D0EDC1'
          : theme.palette.background.default;
      },
      borderColor: (props: FutureShipmentControlViewModel) => {
        return props.color === FutureShipmentControlColor.red
          ? theme.palette.error.main
          : props.color === FutureShipmentControlColor.yellow
          ? theme.palette.warning.light
          : props.color === FutureShipmentControlColor.green
          ? theme.palette.success.main
          : theme.palette.beige.main;
      },
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.palette.text.primary,
    },
    time: {
      minWidth: 16,
      minHeight: 16,
      fontSize: 9,
      lineHeight: '16px',
      position: 'relative',
      padding: (props: FutureShipmentControlViewModel) => {
        return props.color === FutureShipmentControlColor.gray ||
          props.color === FutureShipmentControlColor.green
          ? 0
          : theme.spacing(0, 1);
      },
      whiteSpace: 'nowrap',
      marginLeft: theme.spacing(0.5),
    },
  })
);

export const FutureShipmentControl = (props: FutureShipmentControlViewModel) => {
  const classes = useStyles(props);

  const { name, timeString } = props;

  return (
    <div className={classes.root}>
      <div>{name}</div>
      <div className={classes.time}>{timeString}</div>
    </div>
  );
};
