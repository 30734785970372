import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody, Typography } from '@material-ui/core';
import {
  DealQualificationGroup,
  DealQualificationRuleViewModel,
  DealQualificationType,
} from 'schema/serverTypes';
import { EditButton } from '../EditButton';
import { useTableStyles } from '../useTableStyles';
import { TableSortLabel } from 'components/data-grid/TableSortLabel';
import { QualificationsSorting } from './useQualificationsData';
import { DeleteButton } from '../DeleteButton';
import { useToast } from '../../Toast';
import { useQueryClient } from 'react-query';
import { ModalForm, useModalForm } from '../../Modal';
import { Button } from '../../Button';
import { useDeleteDealQualificationMutation } from '../../../services/api/useDealQualificationDictionaryBackend';

export type QualificationsTableProps = {
  rows: DealQualificationRuleViewModel[];
  loading: boolean;
  onOpen: (item: DealQualificationRuleViewModel) => void;
  sorting: QualificationsSorting;
};

type TableItemProps = {
  item: DealQualificationRuleViewModel;
  onOpen: (item: DealQualificationRuleViewModel) => void;
};

export const typeMap = {
  [DealQualificationType.Manual]: 'Ручное',
  [DealQualificationType.NoLimit]: 'Без ограничений',
  [DealQualificationType.Rule]: 'Авто',
};

export const groupMap = {
  [DealQualificationGroup.General]: 'Общие',
  [DealQualificationGroup.Counterparties]: 'Контрагенты',
  [DealQualificationGroup.ContractBase]: 'Договорная база',
  [DealQualificationGroup.LeaseCalculation]: 'Лизинговый расчет',
  [DealQualificationGroup.PaymentAndDeliveryOrder]: 'Порядок оплаты и поставки',
};

const TableItem = (props: TableItemProps) => {
  const classes = useTableStyles();
  const { item, onOpen } = props;
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { onOpen: onDeleteOpen, onClose, open } = useModalForm();

  const handleOpen = useCallback(() => {
    onOpen(item);
  }, [item, onOpen]);

  const handleDeleteOpen = useCallback(() => {
    onDeleteOpen();
  }, [onDeleteOpen]);

  const { mutateAsync: deleteAsync } = useDeleteDealQualificationMutation({
    onSuccess: () => {
      toast(t('QualificationDeleteSuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' &&
            (query.queryKey as string).indexOf('dealqualifications') >= 0
          );
        },
      });
      onClose();
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const handleDelete = useCallback(() => {
    deleteAsync({ id: item.id });
  }, [deleteAsync, item.id]);

  return (
    <>
      <TableRow>
        <TableCell size="medium">{groupMap[item.group]}</TableCell>
        <TableCell size="medium">{item.name}</TableCell>
        <TableCell size="medium">{typeMap[item.type]}</TableCell>
        <TableCell size="medium">{item.rule ? item.rule.displayName : 'Нет'}</TableCell>
        <TableCell size="medium" className={classes.actions}>
          <EditButton onClick={handleOpen} />
          <DeleteButton onClick={handleDeleteOpen} />
        </TableCell>
      </TableRow>
      <ModalForm open={open} onClose={onClose}>
        <div className={classes.modalRoot}>
          <Typography variant={'h2'} className={classes.modalTitle}>
            {t('Delete qualification')}
          </Typography>
          <Typography variant="subtitle2" className={classes.modalText}>
            {`${t('Are you sure you want to delete the qualification')} "${item.name}"`}
          </Typography>
          <div>
            <Button variant="contained" className={classes.modalButton} onClick={handleDelete}>
              {t('Yes')}
            </Button>
            <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
              {t('No')}
            </Button>
          </div>
        </div>
      </ModalForm>
    </>
  );
};

export const QualificationsTable = (props: QualificationsTableProps) => {
  const classes = useTableStyles();
  const { rows, onOpen, sorting } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium">
            <TableSortLabel columnName={'group'} sorting={sorting}>
              {t('Group of parameters')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName={'name'} sorting={sorting}>
              {t('Parameter')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName={'type'} sorting={sorting}>
              {t('Type of qualification')}
            </TableSortLabel>
          </TableCell>
          <TableCell size="medium">
            <TableSortLabel columnName={'rule'} sorting={sorting}>
              {t('Relation to the rule of the KR model')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={classes.change} size="medium"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((item: DealQualificationRuleViewModel, index: number) => (
          <TableItem key={index} item={item} onOpen={onOpen} />
        ))}
      </TableBody>
    </Table>
  );
};
