import { Typography } from '@material-ui/core';
import { Input } from '../../form';
import { SearchingNomenclature } from '../SearchingNomeclature';
import { Button } from '../../Button';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useCallback, useState } from 'react';
import { CloseNomenclatureIssueViewModel, IssueStatus } from 'schema';
import { useCloseNomenclatureIssueMutation, useUpdateIssueStatusMutation } from 'services';
import { useQueryClient } from 'react-query';
import { useToast } from '../../Toast';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalSelect: {
      textAlign: 'left',
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

type EditNomenclatureCodeFormProps = {
  issueId: number;
  onClose: (ev?: any) => void;
};

export const EditNomenclatureCodeForm = ({ issueId, onClose }: EditNomenclatureCodeFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();

  const {
    control: sendCodeControl,
    handleSubmit: handleSendCodeSubmit,
    watch,
  } = useForm<CloseNomenclatureIssueViewModel>({
    mode: 'onBlur',
  });

  const code = watch('code');

  const { mutateAsync: sendCodeAsync } = useCloseNomenclatureIssueMutation(issueId, {
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });
  const { mutateAsync: updateStatusAsync } = useUpdateIssueStatusMutation(issueId);

  const queryClient = useQueryClient();
  const handleSendCode = useCallback(() => {
    const onSubmit = async (data: CloseNomenclatureIssueViewModel) => {
      const issue = await sendCodeAsync(data);
      if (issue) {
        await updateStatusAsync({ status: IssueStatus.Closed });
        queryClient.invalidateQueries({
          predicate: (query) => {
            return true;
          },
        });
        onClose();
      }
    };
    handleSendCodeSubmit(onSubmit)();
  }, [sendCodeAsync, handleSendCodeSubmit, updateStatusAsync, queryClient, onClose]);

  const [notFound, setNotFound] = useState<boolean>(false);
  return (
    <div className={classes.modalRoot}>
      <Typography variant={'h2'} className={classes.modalTitle}>
        {t('Edit the nomenclature code')}
      </Typography>
      <form className={classes.modalSelect}>
        <Input
          label={t('Enter the code from 1-C')}
          name="code"
          control={sendCodeControl}
          rules={{
            required: {
              value: true,
              message: t('Required'),
            },
          }}
        />
        <SearchingNomenclature code={code} setNotFound={setNotFound} />
      </form>
      <div>
        <Button variant="contained" className={classes.modalButton} onClick={handleSendCode}>
          disabled={notFound}
          {t('Ok')}
        </Button>
        <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
          {t('Cancel')}
        </Button>
      </div>
    </div>
  );
};
