import { Route, Switch } from 'react-router-dom';
import {
  CounterpartiesDataGrid,
  CounterpartyCreateForm,
  Critical,
  MissingCommentFormPanel,
  MissingCommentOverdueFormPanel,
  RatingCommentFormPanel,
  Role,
} from 'components';
import { useErrorContext } from 'components/tasks';
import { useTranslation } from 'react-i18next';
import { ApplicationRole, IssueSource } from 'schema';
import { Counterparty } from '../../components/counterparties/Counterparty';
import { CounterpartyIndividual } from '../../components/counterparties/Counterparty/CounterpartyIndividual';
import { CounterpartyIndividualCreateForm } from '../../components/counterparties/CounterpartyCreateForm/CounterpartyIndividualCreateForm';

export const CounterpartiesPage = () => {
  const { t } = useTranslation();

  useErrorContext({
    title: t('Counterparty_plural'),
    source: IssueSource.counterparty,
  });

  return (
    <>
      <Critical />
      <Switch>
        <Route path="/counterparties/create/legal" exact component={CounterpartyCreateForm} />
        <Route
          path="/counterparties/create/individual"
          exact
          component={CounterpartyIndividualCreateForm}
        />
        <Route path="/counterparties/individual/:inn" exact component={CounterpartyIndividual} />
        <Route path="/counterparties/:inn">
          <Route path="/counterparties/:inn" component={Counterparty} />
          <Role
            role={[ApplicationRole.Admin, ApplicationRole.RiskManager, ApplicationRole.InsideSales]}
          >
            <Route
              path="/counterparties/:inn/rating/:id/comments"
              component={RatingCommentFormPanel}
              exact
            />
          </Role>
          <Route
            path="/counterparties/:inn/comments/:id"
            component={MissingCommentFormPanel}
            exact
          />
          <Route
            path="/counterparties/:inn/overdue/comments"
            component={MissingCommentOverdueFormPanel}
            exact
          />
        </Route>
        <Route path="/counterparties" exact component={CounterpartiesDataGrid} />
      </Switch>
    </>
  );
};
