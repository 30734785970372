import { Redirect, Route, Switch } from 'react-router-dom';
import { Critical } from 'components';
import { ConfiscatedParkingsDataGrid } from 'components/confiscated/parkings/ConfiscatedParkingsDataGrid';

export const ConfiscatedPage = () => {
  return (
    <>
      <Critical />
      <Switch>
        <Route path="/confiscated/parkings" component={ConfiscatedParkingsDataGrid} exact />
        <Redirect to="confiscated/parkings" />
      </Switch>
    </>
  );
};
