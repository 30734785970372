import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete } from 'components';
import { AddConfiscatedParkingRequest, ApplicationRole } from 'schema/serverTypes';
import { useUsersBackendQuery } from 'services/api';
import { UserListResult, UserListViewModel } from 'components/users/types';
import { Control, useController } from 'react-hook-form';

const getOptionLabel = (option: UserListViewModel | null) => {
  if (option === null) {
    return '';
  }
  return option.name;
};

const getOptionSelected = (option: UserListViewModel | null, value: UserListViewModel | null) => {
  if (option === null || value === null) {
    return false;
  }
  return option.id === value.id;
};

const useSearchParams = (search?: string) => {
  return useMemo(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('page', '1');
    searchParams.set('pageSize', '20');

    if (search && search !== '') {
      searchParams.set('search', search);
    }

    searchParams.append('role', ApplicationRole.Admin);
    searchParams.append('role', ApplicationRole.AssetManager);

    return searchParams;
  }, [search]);
};

export type ManagerAutocompleteProps = {
  control: Control<AddConfiscatedParkingRequest, object>;
};

export const ManagerAutocomplete = (props: ManagerAutocompleteProps) => {
  const { control } = props;
  const { t } = useTranslation();

  const {
    field: { value: managerUserId = null, onChange },
  } = useController({
    control,
    name: 'managerUserId',
  });

  const [userText, setUser] = useState('');
  const [input] = useDebounce(userText, 300);
  const searchParams = useSearchParams(input);

  const { data } = useUsersBackendQuery<UserListResult>({
    searchParams,
    queryKey: ['users', input],
  });

  const options = data?.data ?? [];

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setUser(value);
    },
    [setUser],
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: UserListViewModel | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<UserListViewModel | null>,
    ) => {
      if (value === null) {
        onChange(null);
        setUser('');
      } else {
        onChange(value.id);
        setUser(value.name);
      }
    },
    [onChange],
  );

  const selectedOption = options.find((t) => t.id === managerUserId) ?? null;

  return (
    <Autocomplete<UserListViewModel | null>
      label={`${t('Author')}`}
      options={options}
      inputValue={userText}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleOnChange}
      variant="standard"
      value={selectedOption}
    />
  );
};
