import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/useAutocomplete';
import { Autocomplete } from 'components';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { UtilizationArea } from 'schema/serverTypes';
import { ContractFormValues } from './types';
import { useIndustryLeasingProductPagedListQuery } from '../../../services/api/useIndustryLeasingProductBackend';

type RegionAutocompleteOption = {
  utilizationArea: string;
  displayUtilizationArea: string;
  region?: UtilizationArea;
};

type RegionAutocompleteProps = {
  industryId?: number;
};

export const RegionAutocomplete = (props: RegionAutocompleteProps) => {
  const { t } = useTranslation();

  const { industryId } = props;

  const { control, setValue } = useFormContext<ContractFormValues>();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: 'utilizationArea',
    rules: {
      required: {
        value: true,
        message: t('Required'),
      },
    },
  });

  const helperText = error !== undefined ? (error as FieldError).message : undefined;

  const { data } = useIndustryLeasingProductPagedListQuery({ page: 1, pageSize: 10000 });

  const selectedIndustryName = data?.data.find((item) => item.id === industryId)?.name;

  const russiaCisEurope = t('UtilizationAreas.RussiaCisEurope');
  const russiaExceptRepublics = t('UtilizationAreas.RussiaExceptRepublics');
  const russiaCisEuropeShort = t('Regions.RussiaCisEurope');
  const russiaExceptRepublicsShort = t('Regions.RussiaExceptRepublics');

  const options: RegionAutocompleteOption[] = useMemo(() => {
    return selectedIndustryName === 'HC' || selectedIndustryName === 'OT'
      ? []
      : [
          {
            region: UtilizationArea.RussiaExceptRepublics,
            utilizationArea: russiaExceptRepublics,
            displayUtilizationArea: russiaExceptRepublicsShort,
          },
          {
            region: UtilizationArea.RussiaCisEurope,
            utilizationArea: russiaCisEurope,
            displayUtilizationArea: russiaCisEuropeShort,
          },
        ];
  }, [
    selectedIndustryName,
    russiaCisEurope,
    russiaExceptRepublics,
    russiaCisEuropeShort,
    russiaExceptRepublicsShort,
  ]);

  const getOptionLabel = (option: RegionAutocompleteOption | null) => {
    if (option === null) {
      return '';
    }
    return option.displayUtilizationArea;
  };

  const getOptionSelected = (
    option: RegionAutocompleteOption | null,
    value: RegionAutocompleteOption | null,
  ) => {
    if (
      option === null ||
      value === null ||
      option.region === undefined ||
      value.region === undefined
    ) {
      return false;
    }
    return option.region === value.region;
  };

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (inputValue !== value && value !== '' && value !== undefined && value !== null) {
      const newInputValue =
        value === russiaCisEurope
          ? russiaCisEuropeShort
          : value === russiaExceptRepublics
            ? russiaExceptRepublicsShort
            : value;

      setInputValue(newInputValue);
    }
  }, [
    value,
    inputValue,
    russiaCisEurope,
    russiaCisEuropeShort,
    russiaExceptRepublics,
    russiaExceptRepublicsShort,
  ]);

  const handleOnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, value: string, _reason: AutocompleteInputChangeReason) => {
      setInputValue(value);
      if (value === russiaCisEuropeShort) {
        onChange(russiaCisEurope);
        setValue('region', UtilizationArea.RussiaCisEurope, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      } else if (value === russiaExceptRepublicsShort) {
        onChange(russiaExceptRepublics);
        setValue('region', UtilizationArea.RussiaExceptRepublics, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      } else {
        onChange(value);
        setValue('region', undefined, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      }
    },
    [
      onChange,
      setValue,
      russiaCisEurope,
      russiaCisEuropeShort,
      russiaExceptRepublics,
      russiaExceptRepublicsShort,
    ],
  );

  const handleOnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: RegionAutocompleteOption | string | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<RegionAutocompleteOption | string | null>,
    ) => {
      if (value === null) {
        onChange(null);
        setValue('region', undefined, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      } else if (typeof value === 'string') {
        onChange(value);
        setValue('region', undefined, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      } else {
        const option = value as RegionAutocompleteOption;
        onChange(option.utilizationArea);
        setValue('region', option.region, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      }
    },
    [onChange, setValue],
  );

  const selectedOption = options.find((t) => t.utilizationArea === value) ?? null;

  return (
    <Autocomplete<RegionAutocompleteOption, false, false, true>
      freeSolo
      label={`${t('Region')}`}
      options={options}
      onChange={handleOnChange}
      inputValue={inputValue}
      onInputChange={handleOnInputChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      variant="outlined"
      value={selectedOption}
      error={error !== undefined}
      helperText={helperText}
    />
  );
};
