import { CircularProgress, Grid } from '@material-ui/core';
import { Breadcrumbs, Button, IconSprite, Paging } from 'components';
import { useIndustrySpecializationData } from './useIndustrySpecializationData';
import { FilterForm } from './FilterForm';
import { IndustrySpecializationTable } from './IndustrySpecializationTable';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AddButton } from '../AddButton';
import { useHistory } from 'react-router';
import { themeOrange as theme } from '../../../theme';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDownloadDictionary } from '../../../services/api/useIndustryBackend';
import { usePageTitle } from 'components/utils/usePageTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      '& > *': {
        marginLeft: theme.spacing(1.5),
      },
    },
  }),
);

export const IndustrySpecializationDataGrid = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { filter, paging, ...dataProps } = useIndustrySpecializationData();
  const history = useHistory();

  const pageTitle = t('IndustriesSpecialization') + ' - Bumblebee';
  usePageTitle(pageTitle);

  const addHandler = () => {
    history.push('/dictionaries/specialization/add');
  };

  const { isLoading, downloadAsync } = useDownloadDictionary();
  const downloadHandler = useCallback(async () => {
    await downloadAsync();
  }, [downloadAsync]);

  return (
    <>
      <Breadcrumbs link={'/dictionaries'} text={t('Section.dictionaries')} />
      <Grid container spacing={2} direction="column">
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item>
            <FilterForm {...filter} />
          </Grid>
          <Grid item className={classes.actions}>
            <AddButton onClick={addHandler} />
            <Button variant="outlined" onClick={downloadHandler} disabled={isLoading}>
              <IconSprite
                icon={'download'}
                width={'12px'}
                height={'12px'}
                color={theme.palette.secondary.dark}
              />
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    color: theme.palette.secondary.dark,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
        <Grid container item direction="column">
          <Grid item>
            <IndustrySpecializationTable {...dataProps} />
          </Grid>
          <Grid item>
            <Paging {...paging} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
