import { useCurrencyExchanger } from 'components/calculator2/CalculationForm/useCurrencyExchanger';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { Currency, CurrencyDictionaryViewModel, CurrencyViewModel } from 'schema/serverTypes';

export type CurrencyExchangerProviderProps = {
  currencies: CurrencyDictionaryViewModel;
  children?: React.ReactNode;
};

export type CurrencyExchangerContextProps = ReturnType<typeof useCurrencyExchanger> & {
  getActualRate: (sale: Currency, lease: Currency, inputRate: number) => number;
  currencies?: CurrencyViewModel[];
  updated?: string;
};

export const CurrencyRatesContext = createContext<CurrencyExchangerContextProps>({
  findRate: (from: Currency, to: Currency) => 0,
  exchangeMoney: (amount: number, from: Currency, to: Currency, inputRate?: number | undefined) =>
    0,
  displayRate: (from: Currency, to: Currency, inputRate?: number | undefined) => 0,
  getActualRate: (sale: Currency, lease: Currency, inputRate: number) => 0,
});

export const CurrencyExchangerProvider = (props: CurrencyExchangerProviderProps) => {
  const {
    currencies: { baseCurrency, exchangeRates, currencies, updated },
    children,
  } = props;
  const { displayRate, findRate, exchangeMoney } = useCurrencyExchanger(
    baseCurrency,
    exchangeRates
  );

  const getActualRate = useCallback(
    (sale: Currency, lease: Currency, inputRate: number) => {
      const displayedRate = displayRate(sale, lease);
      if (displayedRate === inputRate) {
        return findRate(sale, lease);
      }

      if (lease === baseCurrency) {
        return inputRate;
      }

      const saleToBaseCurrencyRate = findRate(sale, baseCurrency);
      return saleToBaseCurrencyRate / inputRate;
    },
    [baseCurrency, displayRate, findRate]
  );

  const value: CurrencyExchangerContextProps = useMemo(() => {
    return {
      displayRate,
      findRate,
      exchangeMoney,
      getActualRate,
      currencies,
      updated,
    };
  }, [displayRate, findRate, exchangeMoney, getActualRate, currencies, updated]);

  return <CurrencyRatesContext.Provider value={value}>{children}</CurrencyRatesContext.Provider>;
};

export const useCurrencyExchangerContext = () => useContext(CurrencyRatesContext);
