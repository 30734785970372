import { Color, colors } from '@material-ui/core';
import createPalette, { ColorPartial } from '@material-ui/core/styles/createPalette';

export const white = '#FFFFFF';

enum KitColors {
  bgGray = '#F8F8FB',
  grey3 = '#4E4640',
  grey4 = '#BEC5D2',
  grey5 = '#383C61',
  grey6 = '#E3E6F3',
  greyBlue1 = '#ABC3EB',
  greyBlue2 = '#95C0E8',
  dllBlue = '#FF5400',
  blue1 = '#0081D7',
  blue2 = '#56BCFF',
  blue3 = '#1973AF',
  lightBlue = '#E9F1FE',
  lightBlue2 = 'blue',
  lightBlue3 = '#F8F9FB',
  blueGrey = '#D9E7FF',
  textGrey1 = '#405063',
  textGrey2 = '#6B7888',
  textGrey3 = '#7E8794',
  mobileGray = '#A9AFBD',
  attention = '#FFB822',
  darkAttention = '#F29100',
  green = '#1DC9B7',
  lightGreen = '#E5F1F0',
  chartBlue = '#95C0E8',
  chartPurple = '#FD397A',
  lightPurple = '#FFEBF1',
  pink = '#FD397A',
  beige = '#CFCCCA',
}

const KitColorsPalette = Object.fromEntries(
  Object.entries(KitColors).map(([key, value]) => [[key], { main: value }])
);

const style = createPalette({
  common: {
    white: '#FFFFFF',
    black: '#242424',
  },
  primary: {
    main: '#FF5400',
    light: '#FFEEE5',
  },
  primaryGradient: {
    100: '#FFFAF8',
    200: '#FFDDCC',
    400: '#FFBB99',
    500: '#FFA980',
    600: '#FF9866',
    700: '#FF874C',
    800: '#FF7633',
    900: '#FF651A',
  },

  secondary: {
    darker: '#1D1B20',
    dark: '#6E0E10',
    main: '#D5C7BC',
    light: '#F0E9E3',
  },
  info: {
    dark: colors.lightBlue[500],
    main: '#1F86E6',
    light: 'rgb(0 133 255 / 50%)',
    contrastText: '#aeaeae',
  },
  success: {
    dark: '#A7C817',
    main: '#52C41A',
    light: '#F6FCF3',
  },
  warning: {
    dark: KitColors.darkAttention,
    main: '#EE8D34',
    light: '#F6CB45',
  },
  error: {
    main: '#FC1C52',
    light: '#F1E2E2',
  },
  text: {
    primary: '#615750',
    secondary: '#A9A39F',
    disabled: '#D5C7BC',
  },
  background: {
    default: '#F5F5F5',
    paper: '#FFFFFF',
    dark: '#D5C7BC',
    light: '#FEF9F7',
  },
  divider: '#615750',
  grey: {
    100: KitColors.chartBlue,
  },
  ...KitColorsPalette,
});

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    dark: string;
    light?: string;
  }

  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }

  interface Palette {
    bgGray: Palette['primary'];
    grey3: Palette['primary'];
    grey4: Palette['primary'];
    grey5: Palette['primary'];
    grey6: Palette['primary'];
    greyBlue1: Palette['primary'];
    greyBlue2: Palette['primary'];
    dllBlue: Palette['primary'];
    blue1: Palette['primary'];
    blue2: Palette['primary'];
    blue3: Palette['primary'];
    lightBlue: Palette['primary'];
    lightBlue2: Palette['primary'];
    lightBlue3: Palette['primary'];
    blueGrey: Palette['primary'];
    textGrey1: Palette['primary'];
    textGrey2: Palette['primary'];
    textGrey3: Palette['primary'];
    mobileGray: Palette['primary'];
    attention: Palette['primary'];
    darkAttention: Palette['primary'];
    green: Palette['primary'];
    lightGreen: Palette['primary'];
    chartBlue: Palette['primary'];
    chartPurple: Palette['primary'];
    lightPurple: Palette['primary'];
    pink: Palette['primary'];

    primaryGradient: Color;
    beige: Palette['primary'];
  }

  interface PaletteOptions {
    bgGray: PaletteOptions['primary'];
    grey3: PaletteOptions['primary'];
    grey4: PaletteOptions['primary'];
    grey5: PaletteOptions['primary'];
    grey6: PaletteOptions['primary'];
    greyBlue1: PaletteOptions['primary'];
    greyBlue2: PaletteOptions['primary'];
    dllBlue: PaletteOptions['primary'];
    blue1: PaletteOptions['primary'];
    blue2: PaletteOptions['primary'];
    blue3: PaletteOptions['primary'];
    lightBlue: PaletteOptions['primary'];
    lightBlue2: PaletteOptions['primary'];
    lightBlue3: PaletteOptions['primary'];
    blueGrey: PaletteOptions['primary'];
    textGrey1: PaletteOptions['primary'];
    textGrey2: PaletteOptions['primary'];
    textGrey3: PaletteOptions['primary'];
    mobileGray: PaletteOptions['primary'];
    attention: PaletteOptions['primary'];
    darkAttention: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    lightGreen: PaletteOptions['primary'];
    chartBlue: PaletteOptions['primary'];
    chartPurple: PaletteOptions['primary'];
    lightPurple: PaletteOptions['primary'];
    pink: PaletteOptions['primary'];

    primaryGradient: ColorPartial;
    beige: PaletteOptions['primary'];
  }
}

export default style;
