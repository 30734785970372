import { useTranslation } from 'react-i18next';
import { Button, Grid, Role } from 'components';
import { Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTemplatesData } from './useTemplatesData';
import { TemplatesFilterForm } from './TemplatesFilterForm';
import { TemplateCard } from './TemplateCard';
import { ApplicationRole } from 'schema/serverTypes';
import { usePageTitle } from 'components/utils/usePageTitle';

export const TemplatesDataGrid = () => {
  const { t } = useTranslation();
  const { filter, templates } = useTemplatesData();

  const pageTitle = t('Section.templates') + ' - Bumblebee';
  usePageTitle(pageTitle);

  const { control } = useForm({
    mode: 'onBlur',
  });

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box flex={1}>
          <TemplatesFilterForm {...filter} />
        </Box>
        <Box>
          <Role role={['admin', 'super_sales_manager', ApplicationRole.RiskManager]}>
            <Button variant="contained" to="templates/create">
              {t('New template')}
            </Button>
          </Role>
        </Box>
      </Box>
      <form>
        <Grid container columnSpacing={2.5} rowSpacing={2.5}>
          {templates.map((template) => {
            return <TemplateCard template={template} control={control} key={template.id} />;
          })}
        </Grid>
      </form>
    </>
  );
};
