import { useTranslation } from 'react-i18next';
import { Currency, QuotaNomenclatureViewModel, ScoringModelType } from 'schema';
import { formatMoney, Grid } from 'components/index';
import clsx from 'clsx';
import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nomenclaturesRow: {
      '&&': {
        borderBottom: '1px solid ' + theme.palette.secondary.light,
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },
      },
      borderLeft: '4px solid transparent',
    },
    nomenclaturesCell: {
      '&&&&': {
        padding: theme.spacing(1),
      },
    },
    nomenclaturesHead: {
      backgroundColor: alpha(theme.palette.secondary.main, 0.6),
      padding: theme.spacing(0.75, 2),
      color: theme.palette.text.primary,
    },
    nomenclaturesItem: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 2),
      '&:nth-child(6n+1), &:nth-child(6n+2), &:nth-child(6n+3)': {
        backgroundColor: theme.palette.background.light,
      },
    },
    nomenclaturesTable: {
      border: '1px solid' + theme.palette.secondary.main,
      borderRadius: 4,
    },
    right: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
  })
);

type NomenclaturesProps = {
  nomenclatures: QuotaNomenclatureViewModel[];
  scoringModelType: ScoringModelType;
};

export const Nomenclatures = (props: NomenclaturesProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const { nomenclatures } = props;

  return (
    <TableRow className={classes.nomenclaturesRow}>
      <TableCell colSpan={10} className={classes.nomenclaturesCell}>
        <Grid container className={classes.nomenclaturesTable}>
          <Grid item xs={16} className={classes.nomenclaturesHead}>
            {t('LeaseSubject')}
          </Grid>
          <Grid item xs={6} className={clsx(classes.nomenclaturesHead, classes.right)}>
            {t('ItemPrice')}
          </Grid>
          <Grid item xs={2} className={clsx(classes.nomenclaturesHead, classes.right)}>
            {t('NumberOfItems')}
          </Grid>
          {nomenclatures.map((nomenclature: QuotaNomenclatureViewModel) => (
            <Nomenclature key={nomenclature.id} {...nomenclature} />
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const Nomenclature = (props: QuotaNomenclatureViewModel) => {
  const classes = useStyles(props);
  const { name, price, count } = props;
  return (
    <>
      <Grid item xs={16} className={classes.nomenclaturesItem}>
        {name}
      </Grid>
      <Grid item xs={6} className={clsx(classes.nomenclaturesItem, classes.right)}>
        {formatMoney({
          amount: price ?? '',
          currency: Currency.Ruble,
        })}
      </Grid>
      <Grid item xs={2} className={clsx(classes.nomenclaturesItem, classes.right)}>
        {count}
      </Grid>
    </>
  );
};
