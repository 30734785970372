import { useCallback, useEffect, useMemo } from 'react';
import { NomenclatureFilter, NomenclatureSortBy, SortOrder } from 'schema/serverTypes';
import { useForm, useWatch } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { usePaging } from 'components/paging/usePaging';
import { useSorting } from 'components/sorting/useSorting';
import { useNomenclatureListQuery } from 'services/api/useNomenclaturesBackend';
import { useLocation } from 'react-router-dom';

export const useNomenclaturesData = () => {
  const { page, pageSize, onPageChanged, onPageSizeChanged, onReset } = usePaging();

  const { sortBy, order, setOrder, setSortBy } = useSorting<NomenclatureSortBy>({
    sortBy: NomenclatureSortBy.code,
    order: SortOrder.asc,
  });

  const handleSortBy = useCallback(
    (sortBy: NomenclatureSortBy) => {
      setSortBy(sortBy);
    },
    [setSortBy]
  );

  const handleSortOrder = useCallback(
    (order: SortOrder) => {
      setOrder(order);
    },
    [setOrder]
  );

  const sorting = useMemo(() => {
    return {
      sortBy,
      order,
      setSortBy: handleSortBy,
      setOrder: handleSortOrder,
    };
  }, [sortBy, order, handleSortBy, handleSortOrder]);

  const { search: search1 } = useLocation();
  const query = new URLSearchParams(search1);
  const nomenclatureCode = query.get('nomenclatureCode');

  const { control, reset } = useForm<NomenclatureFilter>({
    mode: 'onBlur',
    defaultValues: {
      search: undefined,
      onlyConfirmed: false,
      code: nomenclatureCode ? [nomenclatureCode] : undefined,
    },
  });

  const handleOnReset = useCallback(() => {
    reset({ search: undefined });
  }, [reset]);

  const code = useWatch({ control, name: 'code' });
  const searchValue = useWatch({ control, name: 'search' });
  const categoryValue = useWatch({ control, name: 'category' });
  const categoryCodeValue = useWatch({ control, name: 'categoryCode' });
  const assetTypeValue = useWatch({ control, name: 'assetType' });

  const [search] = useDebounce(searchValue, 500);
  const [category] = useDebounce(categoryValue, 500);
  const [categoryCode] = useDebounce(categoryCodeValue, 500);
  const [assetType] = useDebounce(assetTypeValue, 500);

  const { data, isLoading } = useNomenclatureListQuery({
    code: typeof code === 'string' ? [code] : code,
    category,
    categoryCode,
    search,
    assetType,
    page,
    pageSize: pageSize,
    sortBy,
    order,
  });

  const filter = useMemo(() => {
    return {
      control,
      handleOnReset,
    };
  }, [control, handleOnReset]);

  const rows = data?.data ?? [];
  const pageCount = data?.pageCount ?? 0;
  const totalCount = data?.totalCount ?? 0;
  const length = rows.length;

  const paging = useMemo(() => {
    return {
      pageCount,
      totalCount,
      page,
      pageSize,
      dataCount: length,
      onPageChanged,
      onPageSizeChanged,
      onReset,
    };
  }, [pageCount, totalCount, page, pageSize, length, onPageChanged, onPageSizeChanged, onReset]);

  useEffect(() => {
    onPageChanged(1);
  }, [search, code, category, categoryCode, onPageChanged]);

  return {
    paging,
    filter,
    sorting,
    isLoading,
    data: data?.data ?? [],
  };
};
