import { useQuotaDealQualificationQuery } from 'services';
import { DealQualificationGroup } from 'schema';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { groupMap } from '../../../dictionaries/Qualifications/QualificationsTable';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
      breakInside: 'avoid',
    },
    container: {
      columnCount: 2,
      columnGap: theme.spacing(4),
    },
    title: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    status: {
      fontSize: 12,
    },
    valid: {
      display: 'inline-block',
      width: 24,
      fontWeight: 700,
      color: theme.palette.success.main,
    },
    inValid: {
      display: 'inline-block',
      width: 24,
      fontWeight: 700,
      color: theme.palette.error.main,
    },
    noValid: {
      color: theme.palette.error.main,
    },
    name: {
      display: 'inline-block',
      marginLeft: theme.spacing(2),
    },
  })
);

type QualificationStatusesProps = {
  quotaId: number;
};

export const QualificationStatuses = ({ quotaId }: QualificationStatusesProps) => {
  const classes = useStyles();

  const { data } = useQuotaDealQualificationQuery(quotaId);
  if (!data || data?.length === 0) return null;

  type DealQualificationRuleGroupBy<T> = T extends Record<string, unknown> ? keyof T : never;
  const groupBy = <T extends Record<string, any>>(
    data: T[],
    key: DealQualificationRuleGroupBy<T>
  ) => {
    return data.reduce((acc, curr) => {
      const groupKey = curr[key];
      if (groupKey !== undefined) {
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(curr);
      }
      return acc;
    }, {} as Record<string, T[]>);
  };

  const groupedData = groupBy(data, 'group');

  return (
    <div className={classes.container}>
      {Object.keys(groupedData).map((key: any) => {
        const groupKey = key as DealQualificationGroup;
        return (
          <div key={groupKey} className={classes.root}>
            <div className={classes.title}>{groupMap[groupKey]}</div>
            {groupedData[groupKey].map((item) => {
              return (
                <div className={classes.status} key={item.id}>
                  {item.isValid ? (
                    <span className={classes.valid}>OK</span>
                  ) : (
                    <span className={classes.inValid}>—</span>
                  )}
                  <span className={clsx(classes.name, !item.isValid ? classes.noValid : '')}>
                    {item.name}
                  </span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
