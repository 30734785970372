import {
  Button,
  Grid,
  IconBackTo,
  IssueNomenclature,
  IssueShipmentReports,
  IssueVerification,
  IssueScoring,
  IssueApproval,
  ModalForm,
  useModalForm,
  IconSprite,
} from 'components';
import { useTranslation } from 'react-i18next';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import { Issue, IssueContract, IssueParam, IssueShipment, IssueTelematics } from '.';
import { useIssueData } from './useIssueData';
import { useLocation } from 'react-router-dom';
import { IssuePayment } from './IssuePayment';
import { useRef } from 'react';
import { IssueType } from '../../schema';
import { IssueControl } from './IssueControl';
import { EditQuotaPage } from '../../pages/quotas-page/QuotasPage';
import { usePageTitle } from 'components/utils/usePageTitle';
import { useIssueTypeString } from 'components/tasks/useStatusString';
import { IssueContractIncidentForm } from './IssueContractIncidentForm';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    wrapper: {
      width: '100%',
    },
    span: {
      marginLeft: theme.spacing(1.6),
      color: theme.palette.common.black,
    },
    commentsLink: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2),
      '& button': {
        marginLeft: theme.spacing(1),
      },
    },
    chatIcon: {
      marginLeft: theme.spacing(1.2),
    },
    quotaAccordion: {
      marginBottom: theme.spacing(1),
    },
  }),
);

export const Task = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { issue } = useIssueData();
  const { onOpen, onClose, open } = useModalForm();
  const issueType = useIssueTypeString(issue?.issueType ?? IssueType.Contract);
  let issueTypeText = '';

  if (issue !== undefined && issue.issueType !== undefined) {
    issueTypeText = issueType;
  }

  const pageTitle =
    t('Task') + ' ' + issueTypeText + ' №' + issue?.id + ' - ' + issue?.lessee?.name;
  usePageTitle(pageTitle);

  let { pathname } = useLocation();
  pathname = '/' + pathname.split('/')[1];

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const handlerScroll = () => {
    const el = scrollRef.current as HTMLElement;
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Box mb={2} mt={2.5}>
          <Button variant="iconButton" endIcon={<IconBackTo />} to={pathname} />
          <span className={classes.span}>
            {t('Task')} №{issue?.id}
          </span>
        </Box>
        <Grid container columnSpacing={2.5}>
          <Grid item xs={16}>
            {issue?.issueType === IssueType.Contract ? (
              <IssueContract issue={issue} />
            ) : issue?.issueType === IssueType.Telematics ? (
              <IssueTelematics issue={issue} />
            ) : issue?.issueType === IssueType.Shipment ? (
              <IssueShipment issue={issue} />
            ) : issue?.issueType === IssueType.Payment ? (
              <IssuePayment issue={issue} />
            ) : issue?.issueType === IssueType.Verification ? (
              <IssueVerification issue={issue} />
            ) : issue?.issueType === IssueType.ShipmentReport ? (
              <IssueShipmentReports issue={issue} />
            ) : issue?.issueType === IssueType.Nomenclature ? (
              <IssueNomenclature issue={issue} />
            ) : issue?.issueType === IssueType.Scoring ? (
              <IssueScoring issue={issue} />
            ) : issue?.issueType === IssueType.Approval ? (
              <IssueApproval issue={issue} />
            ) : (
              issue && <Issue issue={issue} />
            )}
          </Grid>
          <Grid item xs={8}>
            {issue && <IssueParam issue={issue} />}
          </Grid>
          {issue?.issueType &&
            issue?.issueType !== IssueType.Error &&
            issue?.issueType !== IssueType.Rework && (
              <>
                <Grid item xs={24}>
                  <IssueControl
                    issueType={issue?.issueType}
                    issueId={issue?.id}
                    controls={issue?.controls}
                  />
                </Grid>
                <Grid item xs={24} className={classes.commentsLink}>
                  {issue?.issueType === IssueType.Contract && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        onOpen();
                      }}
                    >
                      {'Сообщить об инциденте '}
                    </Button>
                  )}
                  <Button variant="outlined" onClick={handlerScroll}>
                    {t('Comments')}{' '}
                    <IconSprite
                      icon={'chat'}
                      width={'16px'}
                      height={'16px'}
                      className={classes.chatIcon}
                    />
                  </Button>
                </Grid>
              </>
            )}

          {issue?.issueType !== IssueType.Error &&
            issue?.issueType !== IssueType.Rework &&
            issue?.quotaId && (
              <Grid item xs={24} className={classes.quotaAccordion}>
                <Accordion defaultExpanded={false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      {t('Detailed information about the calculation')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <EditQuotaPage
                      quotaId={issue?.quotaId}
                      shipmentItemId={issue?.shipmentItemId}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          <Grid item xs={24}>
            <div id="comments" ref={scrollRef} />
          </Grid>
        </Grid>
      </div>
      <ModalForm open={open} onClose={onClose} width={500}>
        <IssueContractIncidentForm issueId={issue?.id ?? -1} onClose={onClose} />
      </ModalForm>
    </>
  );
};
